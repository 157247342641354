import React from "react";
import { Link } from "react-router-dom";
import validators from "../../validators";
import "./Signin.css";
import logoImg from "../../assets/img/miner-w.png?v3";
import ReCAPTCHA from "react-google-recaptcha";
import Api from "../../services/api";
// import { toast } from "react-toastify";
import { RECAPTCHASITEKEY } from "../../services/api-config";
import TwoFaValidateModal from "./TwoFaValidateModal";
import _ from "lodash";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import { storeUserInfo, storeToken } from "../../store/actions/UserAction";
import Toast from '../CustomToast/CustomToast';

let api;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      email: "",
      password: "",
      authenticationToken: "",
      userInfo: "",
      userResponse: "",
      buttonLoading: false,
      twoFaModalOpen: false,
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.signin = this.signin.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.twoFaAuthenticationCheck = this.twoFaAuthenticationCheck.bind(this);
    this.handleCaptchaResponseChange =
      this.handleCaptchaResponseChange.bind(this);
    this.loginSuccess = this.loginSuccess.bind(this);
  }

  componentWillMount() {
    pageProgress("display");
    document.title =
      messageConstants.SIGNIN_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  componentDidMount() {
    pageProgress("remove");
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onCloseModal() {
    this.setState({
      twoFaModalOpen: false,
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["email", "password"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  loginSuccess(response) {
    if (!_.isUndefined(response)) {
      const { storeUserInfo, storeToken } = this.props;
      let userInfo = {};
      let authenticationToken = response.data.token;
      userInfo.fullName = response.data.user.fullName;
      userInfo.userDisplayName = response.data.user.fullName;
      userInfo.email = response.data.user.email;
      userInfo.isKycVerified = response.data.user.isKycVerified;
      userInfo.role = response.data.user.role;
      userInfo.identificationDataFilled =
        response.data.user.identificationDataFilled;
      userInfo.fatcaCrsDataFilled = response.data.user.fatcaCrsDataFilled;
      userInfo.binanceAccountNumber = response.data.user.binanceAccountNumber;
      storeUserInfo(userInfo);
      storeToken(authenticationToken);
      this.props.checkLogin();
      if (response.message !== "" && response.message !== null) {
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
      }
      if (response.data.user.isKycVerified === false) {
        /* let redirectUrl =
          response.data.user.kycStatus === "u"
            ? "kyc_status/pending"
            : response.data.user.kycStatus === "r"
            ? "kyc_status/rejected"
            : response.data.user.basicDetailFilled === false
            ? "/kyc"
            : response.data.user.documentsUploaded === false
            ? "/kyc_upload"
            : "/kyc_submit"; */
        let redirectUrl = "/dashboard";
        this.props.history.push(redirectUrl);
      } else {
        let redirectUrl = "/dashboard";
        this.props.history.push(redirectUrl);
      }
    }
  }

  async signin(event) {
    event.preventDefault();
    const { email, password, recaptchaResponse } = this.state;
    const isFormValid = this.isFormValid();
    if (
      isFormValid &&
      this.email !== "" &&
      password !== "" &&
      password.length >= 6
    ) {
      this.setState({
        buttonLoading: true,
      });
      const response = await api.create("user/signin", {
        email: email,
        password: password,
        role: "m",
        recaptchaResponse: recaptchaResponse,
      });

      if (response) {
        this.setState({
          buttonLoading: false,
        });

        if (response.code === 200 && response.data.token !== "") {
          if (response.data.user.twoFAStatus === true) {
            this.setState({
              twoFaModalOpen: true,
              userResponse: response,
            });
          } else {
            this.loginSuccess(response);
          }
        } else {
          Toast({
            type:'error',
            title: 'Error',
            message:response.message,
          });
          // toast.error(response.message);
          this.recaptcha.reset();
        }
      }
    } else {
      if (!isFormValid) {
        Toast({
          type:'error',
          title: 'Error',
          message:messageConstants.REQUIRED_FIELDS_VALID_MSG,
        });
        // toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      } else {
        let msg =
          password.length < 6
            ? messageConstants.VALID_PASSWORD_MSG
            : messageConstants.REQUIRED_FIELD_ERROR_MSG;
            Toast({
              type:'error',
              title: 'Error',
              message:msg
            });
        // toast.error(msg);
      }
      this.recaptcha.reset();
    }
  }

  async twoFaAuthenticationCheck() {
    let { twoFAcode, email, userResponse } = this.state;
    if (!_.isUndefined(twoFAcode) && email !== "" && twoFAcode !== "") {
      const response = await api.create("user/verify2FA", {
        email: email,
        code: twoFAcode,
      });
      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
        this.loginSuccess(userResponse);
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
    } else {
      Toast({
        type:'error',
        title: 'Error',
        message:messageConstants.VALID_TWO_FACTOR_CODE_MSG,
      });
      // toast.error(messageConstants.VALID_TWO_FACTOR_CODE_MSG);
    }
  }

  render() {
    const { email, password, buttonLoading } = this.state;
    const isFormValid = this.isFormValid();
    return (
      <div className="login-container min-width-50">
        <div className="auth-box-w wider login-box">
          <div className="logo-w">
            <Link to="/landing">
              <img alt="Logo" src={logoImg} width="250" />
            </Link>
          </div>
          <form action="" className="custom-form">
            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                <input
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Email ID*"
                  type="text"
                  onChange={this.onchange}
                  value={this.state.email}
                />
              </div>
              {email && this.displayValidationErrors("email")}
            </div>
            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-key" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Password*"
                  type="password"
                  name="password"
                  onChange={this.onchange}
                  value={this.state.password}
                  id="password"
                />
              </div>
              {password && this.displayValidationErrors("password")}
            </div>
            <div className="form-check terms-block">
              <ReCAPTCHA
                ref={(el) => {
                  this.recaptcha = el;
                }}
                sitekey={RECAPTCHASITEKEY}
                onChange={this.handleCaptchaResponseChange}
              />
            </div>
            <div className="clearfix"></div>
            <div>
              <div className="buttons-w pull-left width-35 pl-2 pl-none width-100">
                <button
                  className={`btn custom-submit-button ${
                    isFormValid ? "" : "disabled"
                  }`}
                  onClick={this.signin}
                  disabled={!isFormValid}
                >
                  Sign In
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
              <div className="pull-right signuppage-info-bottom-link signinpt">
                Don't have account?&nbsp;
                <Link to="/signup" className="color-white">
                  <span className="text-underline">Sign Up</span>
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="text-center mt-3 mb-2 signin-info-bottom-link">
              <Link to="/forgot" className="color-white">
                Forgot Password?
              </Link>
            </div>
          </form>
        </div>
        <TwoFaValidateModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onchange={this.onchange}
          twoFaAuthenticationCheck={this.twoFaAuthenticationCheck}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeUserInfo: (userInfo) => dispatch(storeUserInfo(userInfo)),
    storeToken: (token) => dispatch(storeToken(token)),
  };
};

export default connect(null, mapDispatchToProps)(Signin);
