import React, { Component } from "react";
import _ from "lodash";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import CardElement from "./CardElement";
import { pageProgress } from "../../utils/Util";
import DashHeader from '../DashHeader/DashHeader';
import './resources.scss';

let api;

class Resources extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      resourcesList: [],
      requestProcessing: true,
      totalResources: 0,
      categoryList: [],
      selectedCategory: "",
      title: "",
      selectedCategoryLabel: "",
      clearLoading: false,
      filterLoading: false,
    };
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.getFileExtension = this.getFileExtension.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearRecords = this.clearRecords.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
  }

  changeTitle(e) {
    this.setState({ title: e.target.value });
  }

  filterRecords() {
    this.setState({ filterLoading: true });
    this.getRecords();
  }

  clearRecords() {
    this.setState(
      {
        clearLoading: true,
        title: "",
        selectedCategoryLabel: "",
        selectedCategory: "",
      },
      () => {
        this.getRecords();
      }
    );
  }

  async componentWillMount() {
    pageProgress("display");
    document.title =
      messageConstants.RESOURCES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getRecords();
    this.getCategory();
  }

  async getRecords() {
    const { selectedCategory, title } = this.state;
    try {
      const response = await api.get("user/resources/list", {
        filterByTitle: title,
        category: selectedCategory,
        panel: "m",
      });
      if (response.code === 200) {
        this.setState({
          resourcesList: response.data.resources,
          requestProcessing: false,
          totalResources: response.data.totalResources,
          clearLoading: false,
          filterLoading: false,
        });
      }
      pageProgress("remove");
    } catch (e) {
      this.setState({
        requestProcessing: false,
        totalResources: 0,
      });
      pageProgress("remove");
    }
  }

  handleChangeCategory(e) {
    this.setState({
      selectedCategory: e.value,
      selectedCategoryLabel: e.label,
    });
  }

  getFileExtension(filename) {
    return filename.split(".").pop();
  }

  async getCategory() {
    const response = await api.get("user/viewCategories");
    if (response.code === 200) {
      if (response.data) {
        if (response.data.resourceCategories) {
          let categoryArr = [];
          response.data.resourceCategories.forEach((data) => {
            let category = {};
            category.value = data.title;
            category.label = data.title;
            categoryArr.push(category);
          });
          this.setState({ categoryList: categoryArr });
        }
      }
    }
  }

  render() {
    const {
      resourcesList,
      totalResources,
      requestProcessing,
      selectedCategoryLabel,
      selectedCategory,
      categoryList,
      title,
      clearLoading,
      filterLoading,
    } = this.state;
    let displayResourceList = "";
    if (
      !_.isUndefined(resourcesList) &&
      !_.isEmpty(resourcesList) &&
      totalResources > 0
    ) {
      displayResourceList = resourcesList.map((resource, mainIndex) => {
        const extension = this.getFileExtension(resource.resourceFile);
        if (
          extension === "pdf" ||
          extension === "doc" ||
          extension === "docx"
        ) {
          return (
            <CardElement
              title={resource.resourceTitle}
              description={resource.resourceDescription}
              link={resource.resourceFile}
              category={resource.category}
              document={true}
              key={mainIndex}
              filetype={resource.type}
            />
          );
        } else if (
          extension === "JPEG" ||
          extension === "JPG" ||
          extension === "PNG"
        ) {
          return (
            <CardElement
              title={resource.resourceTitle}
              description={resource.resourceDescription}
              link={resource.resourceFile}
              category={resource.category}
              document={false}
              key={mainIndex}
              filetype={resource.type}
            />
          );
        }
        return (
          <CardElement
            title={resource.resourceTitle}
            description={resource.resourceDescription}
            link={resource.resourceFile}
            category={resource.category}
            document={false}
            key={mainIndex}
            filetype={resource.type}
          />
        );
      });
    }
    console.log('categoryList',categoryList);
    return (
      <div className='resources-container'>
        <DashHeader heading={'Resources'} description={'Training resources for this platform'} />
      <div>
      <form className='filter-section-resources'>
        <div className='input-field-container'>
          <select
            className="input-field"
            value={{
              value: selectedCategory,
              label: selectedCategoryLabel || "Select Category",
            }}
            onChange={this.handleChangeCategory}
          >
            {
              categoryList.map((list,index)=>(
                <option key={index} value={list.value}>{list.label}</option>
              ))
            }
          </select>
        </div>

        <div className='input-field-container'>
          <input
            className="input-field"
            value={title}
            placeholder="Keyword"
            type="text"
            onChange={this.changeTitle}
          />
        </div>

        <div className='btn-container'>
          <button
            className="btn-primary"
            type="button"
            onClick={this.filterRecords}
          >
            {" "}
            Filter
            {filterLoading && (
              <i className="fa-spin fa fa-spinner text-white ml-2" />
            )}
          </button>
          <button
            className="btn-secondary"
            type="button"
            onClick={this.clearRecords}
          >
            {" "}
            Clear
            {clearLoading && (
              <i className="fa-spin fa fa-spinner text-white ml-2" />
            )}
          </button>
        </div>
      </form>

      </div>
      {requestProcessing === false && totalResources > 0 && (
        <div className="mt-3">
          <div className='resources-card-container'>{displayResourceList}</div>
        </div>
      )}

      {requestProcessing === false && totalResources === 0 && (
        <div className="filter-box text-black mt-3">
          There are no resources to display.
        </div>
      )}
    </div>
    );
  }
}

export default Resources;
