import React from "react";
import './miner-power.scss';

const TableHeaderTitle = ({ title, unit }) => {
  return (
    <div className="table-header-title">
      <div>{title}</div>
      {unit &&<div style={{fontSize:'8px'}}>{unit}</div>}
    </div>
  );
};

export default TableHeaderTitle;
