import React, { useState } from 'react';
import './tabs.scss';

const Tabs = ({ tabs, onTabChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabClick = (index) => {
    setActiveIndex(index);
    if (onTabChange) {
      onTabChange(tabs[index], index);
    }
  };

  return (
    <div className="tabs-container">
      <div className="tab-list">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </button>
        ))}
      </div>     
    </div>
  );
};

export default Tabs;
