import React from "react";
import Api from "../../services/api";
// import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
import Toast from '../CustomToast/CustomToast';
let api;

class EmailVerify extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {};
  }

  async componentDidMount() {
    const verificationCode = this.props.match.params.id;
    pageProgress("display");
    try {
      const response = await api
        .setToken(verificationCode)
        .get("user/emailVerify");
      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message: response.message,
        });
        // toast.success(response.message);
      }
    } catch (e) {
      Toast({
        type:'error',
        title: 'Error',
        message: messageConstants.SOMETHING_WENT_WRONG,
      });
      // toast.error(messageConstants.SOMETHING_WENT_WRONG);
    }
    this.props.history.push("/signin");
  }

  render() {
    return <div className="register-container"></div>;
  }
}

export default EmailVerify;
