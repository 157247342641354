import React, { Component } from "react";
import Api from "../../services/api";
import validators from "../../validators";
import { ComponentToPrint } from "./ComponentToPrint";
// import { toast } from "react-toastify";
import "./modals.css";
import { ExportPdf } from "./ExportPdf";
import Toast from "../CustomToast/CustomToast";
import Modal from "../Modal/Modal";
import "./ConfirmationModal.scss";

let api;

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      show: false,
      otp: "",
      timeHorizon: "",
      submitButtonDisabled: false,
      paymentRequestButtonLoading: false,
      earningData: [],
      fileName: "MinerData",
      decSecretKey: "",
      showEmail: false,
      downloaded: false,
      buttonLoading: false,
      showOtp: true,
      showSecretKey: false,
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.confirmedSubmit = this.confirmedSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendEmailNotification = this.sendEmailNotification.bind(this);
    this.confirmDownloaded = this.confirmDownloaded.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.validators = validators;
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];

    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="emailError" key={index}>
            <br />
          </span>
        );
      });

      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  isFormValid() {
    let status = true;
    const validationFields = ["email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      } else {
      }
    });
    return status;
  }

  handleChange(e) {
    this.setState({
      otp: e.target.value,
    });
  }

  async clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  }
  sendEmailNotification() {
    const isFormValid = this.isFormValid();

    if (isFormValid && this.state.email !== "") {
      this.setState({ showEmail: false, showOtp: true, buttonLoading: true });
    } else if (!isFormValid && this.state.email !== "") {
      this.displayValidationErrors("email");
    }
  }
  confirmDownloaded() {
    this.setState({ downloaded: true });
  }
  confirmedSubmit() {
    if (this.state.downloaded === false) {
      Toast({
        type: "error",
        title: "Error",
        message: "Please download the private key",
      });
      // toast.error("Please download the private key");
    } else if (this.state.downloaded === true) {
      this.setState({ showOtp: true, showSecretKey: false });
      this.props.confirmationOnCloseModal();
    }
  }

  async submitOtp() {
    const { otp } = this.state;
    if (!otp) {
      Toast({
        type: "error",
        title: "Error",
        message: "Please Enter OTP",
      });
      // toast.error("please Enter OTP");
    } else {
      const response = await api.create("user/wallet/otp/verify", {
        otpCode: otp,
      });

      if (response.code === 200) {
        this.setState({
          whilelistedWalletAddressSecretKey: response.data,
          otp: "",
        });
        Toast({
          type: "success",
          title: "Success",
          message: "OTP verified successfully",
        });
        // toast.success("OTP verified successfully");
        const responsePrivateKey = await api.get("miner/getPrivateKey");
        this.setState({ decSecretKey: responsePrivateKey.data.key });
        this.setState({
          showEmail: false,
          showOtp: false,
          showSecretKey: true,
        });
      } else {
        Toast({
          type: "error",
          title: "Error",
          message: response.message,
        });
        // toast.error(response.message);
        this.setState({ showOtp: true, showSecretKey: false });
      }
    }
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeAddress(e) {
    this.setState({
      address: e.target.value,
    });
  }
  async onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  async onSubmit() {}

  render() {
    const {
      confirmationModalOpen,
      confirmationOnCloseModal,
      decWalletAddress,
    } = this.props;
    const { showOtp, submitButtonDisabled, showSecretKey, decSecretKey } =
      this.state;
    return (
      <Modal
        isOpen={confirmationModalOpen}
        onClose={confirmationOnCloseModal}
        modalWidth={784}
        modalTitle="Please Enter OTP Sent On Email"
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="confirmation-modal">
          <div role="document">
            <div>
              <div>
                <div>
                  <div className="" style={{ minWidth: "550px" }}>
                    {showOtp ? (
                      <div className="form-group">
                        <label htmlFor="title">
                          Enter OTP<span className="required-field">*</span>
                        </label>
                        <input
                          className="input-field"
                          placeholder="Enter OTP"
                          name="otp"
                          id="otp"
                          type="text"
                          value={this.state.otp}
                          onChange={this.handleChange}
                        />
                        <div className="d-flex justify-content-end mt-4">
                          <button
                            className="btn-secondary"
                            type="button"
                            onClick={confirmationOnCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-primary"
                            type="button"
                            disabled={submitButtonDisabled}
                            onClick={() => {
                              this.submitOtp();
                            }}
                          >
                            {" "}
                            Submit{" "}
                            {this.state.paymentRequestButtonLoading && (
                              <i className="fa-spin fa fa-spinner text-white m-1" />
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {showSecretKey ? (
                      <div className="form-group">
                        <div>
                          <h4 className="text-black">
                            Please Download the Secret Key
                          </h4>
                          <ComponentToPrint
                            ref={(el) => (this.componentRef = el)}
                            decSecretKey={decSecretKey}
                          />
                          <div className="confirmationBox">
                            <div className="confirmationColumn1">
                              <input
                                id="downloaded"
                                value={this.state.downloaded}
                                className="mr-2 mb-1"
                                type="checkbox"
                                onClick={() => {
                                  this.confirmDownloaded();
                                }}
                              />
                              <label
                                className="text-black"
                                htmlFor="downloaded"
                              >
                                {" "}
                                I confirm that I have downloaded the private
                                key.
                              </label>
                            </div>
                            <div>
                              <ExportPdf
                                decSecretKey={this.state.decSecretKey}
                                decWalletAddress={decWalletAddress}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mt-2 mr-2">
                          <button
                            className="btn modal-button cancel-button mb-2 mr-3"
                            type="button"
                            onClick={confirmationOnCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn modal-button update-button"
                            type="button"
                            disabled={submitButtonDisabled}
                            onClick={() => {
                              this.confirmedSubmit();
                            }}
                          >
                            {" "}
                            Submit{" "}
                            {this.state.paymentRequestButtonLoading && (
                              <i className="fa-spin fa fa-spinner text-white " />
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ConfirmationModal;
