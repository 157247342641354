import React, { useEffect } from "react";
import "./modal.scss";
import CloseIcon from "../../assets/img/CloseIcon.svg";
const Modal = ({
  isOpen,
  onClose,
  children,
  modalWidth,
  className,
  modalTitle,
}) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && event.target.classList.contains("custom-modal")) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <div
      className={`${isOpen ? "modal-visible" : "modal-hidden"} custom-modal`}
    >
      <div
        style={{ width: modalWidth || 400 }}
        className={`modal-container ${className || ''}`}
      >
        <div className="modal-head">
          <div className="title-modal">
            <h1>{modalTitle}</h1>
          </div>

          <div className="close-icon">
            <img onClick={onClose} alt="Close" src={CloseIcon} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
