import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";

import Api from "../../services/api";
import Table from '../Table/Table';
const api = new Api();

const StopLossTable = () => {
  const [workerData, setWorkerData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [stopLossNumber, setStopLossNumber] = useState(0);
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log('activeTab',activeTab);
  // }, [activeTab]);

  // const handleTableChange = async (type, params) => {
  //   if (type === "pagination") {
  //     const { page: pPage, sizePerPage: pSizePerPage } = params;
  //     if (sizePerPage !== pSizePerPage || page !== pPage) {
  //       setPage(pPage);
  //       setSizePerPage(pSizePerPage);
  //     }
  //   }
  // };

  // const fetchStopLoss = async () => {
  //   setLoading(true);
  //   const response = await api.get("miner/getStopLoss", {
  //     page,
  //     sizePerPage,
  //   });
  //   if (response.code === 200) {
  //     const data = response.data.stopLoss.map((d) => {
  //       return {
  //         ...d,
  //         binanceAccountNumber: d.userId.binanceAccountNumber || "",
  //         email: d.userId.email || "",
  //         date: moment(d.updatedAt).format("YYYY-MM-DD"),
  //         status: d.status === "s" ? "Proceeded" : "Pending",
  //       };
  //     });
  //     setWorkerData(data);
  //     setTotalSize(response.data.total);
  //   }
  //   setLoading(false);
  // };

  const onSubmitStopLoss = async () => {
    if (stopLossNumber <= 0) {
      alert("Please type the number of miners you want to stop loss");
    }

    const response = await api.create("miner/addStopLoss", {
      number: stopLossNumber,
    });
    if (response.code === 200) {
      const data = [
        {
          ...response.data,
          binanceAccountNumber: response.data.userId.binanceAccountNumber || "",
          email: response.data.userId.email || "",
          date: moment(response.data.updatedAt).format("YYYY-MM-DD"),
          status: response.data.status === "s" ? "Proceeded" : "Pending",
        },
        ...workerData,
      ];
      setWorkerData(data);
    }
  };

  // useEffect(() => {
  //   fetchStopLoss();
  // }, [page, sizePerPage]);

  // const columns = [
  //   {
  //     heading:'Sub Account ID',
  //     accessor:'binanceAccountNumber'
  //   },
  //   {
  //     heading:'Email',
  //     accessor:'email'
  //   },
  //   {
  //     heading:'Workers',
  //     accessor:'number'
  //   },
  //   {
  //     heading:'Date',
  //     accessor:'date'
  //   },
  //   {
  //     heading:'Status',
  //     accessor:'status'
  //   },
  // ]

  // const workerColumns = [
  //   {
  //     headerClasses: "custom-table-th",
  //     style: { whiteSpace: "nowrap" },
  //     dataField: "binanceAccountNumber",
  //     text: "Sub Account ID",
  //   },
  //   {
  //     headerClasses: "custom-table-th",
  //     style: { whiteSpace: "nowrap" },
  //     dataField: "email",
  //     text: "Email",
  //   },
  //   {
  //     headerClasses: "custom-table-th",
  //     style: { whiteSpace: "nowrap" },
  //     dataField: "number",
  //     text: "Workers",
  //   },
  //   {
  //     headerClasses: "custom-table-th",
  //     dataField: "date",
  //     text: "Date",
  //   },
  //   {
  //     headerClasses: "custom-table-th",
  //     dataField: "status",
  //     text: "Status",
  //   },
  // ];
  // console.log('activeTab',activeTab);
  // const WorkerRemoteAll = ({ data, onTableChange }) => (
  //   <div className="table-responsive">
  //     <Table  
  //       showPagination={true}
  //       onSortedList={() => console.log('data')}
  //       column={columns}
  //       data={data}
  //     />
  //     {/* <BootstrapTable
  //       remote
  //       keyField="_id"
  //       bordered={false}
  //       loading={isLoading}
  //       data={data}
  //       columns={workerColumns}
  //       pagination={paginationFactory({ page, sizePerPage, totalSize })}
  //       onTableChange={onTableChange}
  //       noDataIndication="No results!"
  //       overlay={overlayFactory({
  //         spinner: true,
  //         background: "rgba(192,192,192,0.3)",
  //       })}
  //       classes="table table-striped table-lightfont dataTable"
  //     /> */}
  //   </div>
  // );

  // WorkerRemoteAll.propTypes = {
  //   data: PropTypes.array.isRequired,
  // };
  return (
    <>
      <div className="stop-loss-container">
        <div className="row">
          <div className="col-md-6">
            <h5 className="card-title-font form-table-header">Stop Loss</h5>
          </div>
        </div>
        <div className="row mb-3">
          <p className="text-semi-bold" style={{ marginLeft: "10px" }}>
            If your Workers are generating a net loss, usually due to a drop in bitcoin price
          </p>
        </div>
        <form className="stop-loss-form">
          <div>
            <p className=''>Set Stop Loss Limit</p>
            <input
              className="input-field"
              placeholder="Type the number of miners you want to stop loss"
              type="number"
              onChange={(e) => {
                setStopLossNumber(e.target.value);
              }}
              value={stopLossNumber}
            />
          </div>
          
          <div>
            <button
              className="btn-primary"
              type="button"
              onClick={onSubmitStopLoss}
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* {activeTab === 2 &&
        <div>
        <div className="table-responsive">
          <WorkerRemoteAll
            data={workerData}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            onTableChange={handleTableChange}
          />
        </div>
      </div>
      } */}
    </>
  );
}

export default StopLossTable;