import React, { Component } from "react";
import * as messageConstants from "../../utils/Messages";
import {
  niceNumberDecimalDisplay,
  parseTextInputToNumeric,
} from "../../utils/Util";
import _ from "lodash";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import BudgetModal from "../Layout/BudgetFoler/BudgetModal";
import { Box, List, ListItem, Grid, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import {
  hashrateConverter,
  powerConsumptionConverter,
  initHashrateConverter,
} from "./helper";
import './mining-calculator.scss';
import Table from "../Table/Table";
import { withRouter } from "react-router-dom";

class CalculatorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hashRate: 74,
      hashRateType: "terahash",
      powerConsumption: 3400,
      powerConsumptionType: "watt",
      powerCost: 0,
      poolFees: 0,
      BudgetModalOpen: false,
      blockReward: 0,
      difficultyRate: 0,
      exchangeRate: 0,
      buttonLoading: false,
      calculationData: this.props.calculationData || [],
      costPerTeraHash: 20,
      budget: 10000,
      totalAsics: 1,
      readOnly: false,
      transactionFeeRate: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      blockReward: nextProps.blockReward,
      difficultyRate: nextProps.difficultyRate,
      exchangeRate: nextProps.exchangeRate,
      powerCost: nextProps.powerCost,
      poolFees: nextProps.poolFees,
      costPerTeraHash: nextProps.costPerTeraHash || 20,
      transactionFeeRate: nextProps.transactionFeeRate || 0,
      budget: nextProps.budget || 10000,
      totalAsics: nextProps.totalAsics || 1,
      powerConsumption: nextProps.powerConsumption || 3400,
      hashRate: nextProps.hashRate || 74,
      readOnly: nextProps.readOnly || false,
      calculationData: nextProps.calculationData || [],
    });
  }

  onCloseModalBudget = () => {
    this.setState({ BudgetModalOpen: false });
  };

  BudgetShowModal = () => {
    this.setState({
      BudgetModalOpen: true,
    });
  };

  onchange = (event) => {
    let eventValue = event.target.value;
    if (event.target.name === "exchangeRate") {
      eventValue = parseTextInputToNumeric(eventValue);
    }

    let stateObj = {
      [event.target.name]: eventValue,
    };

    if (event.target.name === "budget") {
      const { costPerTeraHash, hashRate, hashRateType } = this.state;
      eventValue = parseTextInputToNumeric(eventValue);
      let teraHashConverter = initHashrateConverter(hashRateType, hashRate);
      let totalAsics = eventValue / (costPerTeraHash * teraHashConverter);
      totalAsics = Math.round(totalAsics);
      stateObj["totalAsics"] = totalAsics;
    }

    if (event.target.name === "totalAsics") {
      const { costPerTeraHash, hashRate, hashRateType } = this.state;
      eventValue = parseTextInputToNumeric(eventValue);
      let teraHashConverter = initHashrateConverter(hashRateType, hashRate);
      let budget = eventValue * (costPerTeraHash * teraHashConverter);
      stateObj["budget"] = budget;
    }

    if (event.target.name === "costPerTeraHash") {
      const { budget, hashRate, hashRateType } = this.state;
      eventValue = parseTextInputToNumeric(eventValue);
      let teraHashConverter = initHashrateConverter(hashRateType, hashRate);
      let totalAsics = budget / (eventValue * teraHashConverter);
      totalAsics = Math.round(totalAsics);
      stateObj["totalAsics"] = totalAsics;
    }

    if (event.target.name === "hashRate") {
      const { costPerTeraHash, budget, hashRateType } = this.state;
      eventValue = parseTextInputToNumeric(eventValue);
      let teraHashConverter = initHashrateConverter(hashRateType, eventValue);
      let totalAsics = budget / (costPerTeraHash * teraHashConverter);
      totalAsics = Math.round(totalAsics);
      stateObj["totalAsics"] = totalAsics;
    }
    this.setState(stateObj);
  };

  onHashRateChange = (event) => {
    const { hashRate, hashRateType } = this.state;
    let { name, value } = event.target;
    const hashrateDisplay = hashrateConverter(hashRateType, value, hashRate);
    this.setState({
      [name]: value,
      hashRate: hashrateDisplay,
    });
  };

  onPowerConsumptionChange = (event) => {
    const { powerConsumption, powerConsumptionType } = this.state;
    let { name, value } = event.target;
    const wattDisplay = powerConsumptionConverter(
      powerConsumptionType,
      value,
      powerConsumption
    );
    this.setState({
      [name]: value,
      powerConsumption: wattDisplay,
    });
  };

  render() {
    let {
      buttonLoading,
      calculationData,
      hashRateType,
      powerConsumption,
      powerConsumptionType,
      budget,
      costPerTeraHash,
      hashRate,
      difficultyRate,
      totalAsics,
      BudgetModalOpen,
      exchangeRate,
      blockReward,
      poolFees,
      powerCost,
      readOnly,
      transactionFeeRate,
    } = this.state;

    if (!readOnly) {
      budget =
        parseFloat(costPerTeraHash) *
        parseFloat(totalAsics) *
        parseFloat(hashRate);
    }

    const buttonDisabled = !!buttonLoading ? true : false;
    let calculationRequestsHtml = "";
    if (calculationData && !_.isEmpty(calculationData)) {
      calculationRequestsHtml = calculationData.map((details) => (
        <tr>
          <td>{details.label}</td>
          <td>{details.btcMined}</td>
          <td>&#36;{niceNumberDecimalDisplay(details.btcMinedUsd, 2)}</td>
          <td>{details.poolFeesBtc}</td>
          <td>&#36;{niceNumberDecimalDisplay(details.poolFeesUsd, 2)}</td>
          <td>
            &#36;{niceNumberDecimalDisplay(details.electricityCostUsd, 0)}
          </td>
          <td>&#36;{niceNumberDecimalDisplay(details.profit, 2)}</td>
        </tr>
      ));
    } else if (!buttonLoading && _.isEmpty(calculationData)) {
      calculationRequestsHtml = (
        <tr className="text-center">
          <td colSpan="7">
            <h3 className="text-default-color text-xl">
              {messageConstants.NO_RECORDS_FOUND}
            </h3>
          </td>
        </tr>
      );
    }

    let totalhashrateforuser = budget / costPerTeraHash;
    totalhashrateforuser = niceNumberDecimalDisplay(totalhashrateforuser, 2);
    console.log('calculationData',calculationData)

    const tableColumn = [
      {
        heading:'Period',
        accessor:'label'
      },
      {
        heading:'BTC Mined',
        accessor:'btcMined'
      },
      {
        HeadCell:() => <div className="price-heading-tag">
          <p>BTC Mined</p>
          <span>(USD)</span>
        </div>,
        accessor:'',
        Cell:({row}) => <p>&#36; {niceNumberDecimalDisplay(row.btcMinedUsd, 2)}</p>
      },
      {
        HeadCell:() => <div className="price-heading-tag">
        <p>Pool Fees</p>
        <span>(BTC)</span>
      </div>,
        accessor:'poolFeesBtc',
      },
      {
        HeadCell:() => <div className="price-heading-tag">
        <p>Pool Fees</p>
        <span>(USD)</span>
      </div>,
        accessor:'',
        Cell:({row}) => <p>&#36; {niceNumberDecimalDisplay(row.poolFeesUsd, 2)}</p>
      },
      {
        HeadCell:() => <div className="price-heading-tag">
        <p>Electricity Costs</p>
        <span>(USD)</span>
      </div>,
        accessor:'',
        Cell:({row}) => <p>&#36; {niceNumberDecimalDisplay(row.electricityCostUsd, 2)}</p>
      },
      {
        heading:'Profit',
        accessor:'',
        Cell:({row}) => <p>&#36; {niceNumberDecimalDisplay(row.profit, 2)}</p>
      },
    ]
    return (
      <div className='mining-calcultaor-container'>
        <div>
          <div>
            <div className="calculator-container">
              <h2>Input Data</h2>
              <div className="row mining-margin">
                <div className="col-lg-4">
                  <h5 className="text-mining mb-3">
                    Budget
                    <p className='tooltip-icon' id='budgetTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="budgetTooltip"
                      title="Budget"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="budgetTooltip"
                    >
                      <PopoverBody>
                        The budget field is used if you wish to calculate
                        profits based on total capital instead of total
                        hashrate. The budget is the amount used to purchase a
                        number of application specific integrated circuits
                        (ASICs) for Bitcoin mining.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      // className="input-field"
                      type="text"
                      id="budget"
                      name="budget"
                      onChange={this.onchange}
                      value={budget}
                      readOnly={readOnly}
                    />
                    <div className="sub-field">
                      USD
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mgtp-xs-3 mgtp-sm-3 mgtp-md-3">
                  <h5 className="text-mining mb-3">
                    Cost per Terahash
                    <p className='tooltip-icon' id='costPerTeraHashTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="costPerTeraHashTooltip"
                      title="Cost per Terahash"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="costPerTeraHashTooltip"
                    >
                      <PopoverBody>
                        The cost per Terahash is the dollar amount paid for each
                        Hashrate. 1 terahash = 1,000,000,000,000 hashes.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      id="costPerTeraHash"
                      name="costPerTeraHash"
                      onChange={this.onchange}
                      value={costPerTeraHash}
                      readOnly={readOnly}
                    />
                    <div className="sub-field">
                      $/TH
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mgtp-xs-3 mgtp-sm-3 mgtp-md-3">
                  <h5 className="text-mining mb-3">
                    Total ASICs
                    <p className='tooltip-icon' id='totalAsicsTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="totalAsicsTooltip"
                      title="Total ASICs"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="totalAsicsTooltip"
                    >
                      <PopoverBody>
                        The total number of bitcoin miners you can purchase with
                        your Budget based on the Cost per Terahash and the
                        Hashrate per ASIC.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      id="totalAsics"
                      name="totalAsics"
                      onChange={this.onchange}
                      value={totalAsics}
                      style={{ borderRadius: "8px" }}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
              <hr
                style={{
                  marginTop: "1.5rem",
                  marginLeft: "10px",
                  marginRight: "10px",
                  border:'1px solid #222329'
                }}
              />
              <div className="row mining-margin">
                <div className="col-md-6 col-sm-6 col-lg-4">
                  <h5 className=" mt-3 text-mining mb-3">
                    Hashrate per ASIC
                    <p className='tooltip-icon' id='hashRateTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="hashRateTooltip"
                      title="Hashrate"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="hashRateTooltip"
                    >
                      <PopoverBody>
                        Hashrate is the unit used to measure speed of the ASICs.
                        If you have inserted a Budget then only insert the
                        hashrate of one ASIC not all of the ASICs that you will
                        purchase with your Budget.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      id="hashRate"
                      name="hashRate"
                      onChange={this.onchange}
                      value={hashRate}
                      readOnly={readOnly}
                    />
                    <select
                      onChange={this.onHashRateChange}
                      defaultValue={hashRateType}
                      id="hashRateType"
                      name="hashRateType"
                    >
                      <option value="hash">H/s</option>
                      <option value="kilohash">kH/s</option>
                      <option value="megahash">MH/s</option>
                      <option value="gigahash">GH/s</option>
                      <option value="terahash">TH/s</option>
                      <option value="petahash">PH/s</option>
                      <option value="exahash">EH/s</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-4">
                  <h5 className="mt-3 text-mining mb-3">
                    Power Consumption
                    <p className='tooltip-icon' id='powerConsumptionTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="powerConsumptionTooltip"
                      title="Hashrate"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="powerConsumptionTooltip"
                    >
                      <PopoverBody>
                        This is the total power consumption that is consumed by
                        the ASICs. If you already inserted a Budget then only
                        insert the power consumption of one ASIC not all of the
                        ASICs that you will purchase with your Budget.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="powerConsumption"
                      onChange={this.onchange}
                      value={powerConsumption}
                      readOnly={readOnly}
                    />

                    <select
                      onChange={this.onPowerConsumptionChange}
                      defaultValue={powerConsumptionType}
                      id="powerConsumptionType"
                      name="powerConsumptionType"
                    >
                      <option value="watt">W</option>
                      <option value="kilowatt">kW</option>
                      <option value="megawatt">MW</option>
                      <option value="gigawatt">GW</option>
                      <option value="terawatt">TW</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-4 mining-issue-margin">
                  <h5 className="mt-3 text-mining mb-3">
                    Power Cost
                    <p className='tooltip-icon' id='powerCostTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="powerCostTooltip"
                      title="Power Cost"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="powerCostTooltip"
                    >
                      <PopoverBody>
                        The power cost is the cost for powering the ASICs
                        represented as a dollar amount per kilowatt-hour.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="powerCost"
                      onChange={this.onchange}
                      value={powerCost}
                      readOnly={readOnly}
                    />
                    <div className="sub-field">
                      $/kWh
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mining-margin">
                <div className="col-md-6 col-sm-6 col-lg-4">
                  <h5 className="mt-3 text-mining mb-3">
                    Pool Fees
                    <p className='tooltip-icon' id='poolFeesTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="poolFeesTooltip"
                      title="Pool Fees"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="poolFeesTooltip"
                    >
                      <PopoverBody>
                        The pool fees are the costs of using the mining pool
                        where the cryptocurrency is being mined.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="poolFees"
                      onChange={this.onchange}
                      value={poolFees}
                      readOnly={readOnly}
                    />

                    <div className="sub-field">
                      &#37;
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-4">
                  <h5 className="mt-3 text-mining mb-3">
                    Block Reward
                    <p className='tooltip-icon' id='blockRewardTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="blockRewardTooltip"
                      title="Block Reward"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="blockRewardTooltip"
                    >
                      <PopoverBody>
                        A block reward refers to the number of bitcoins you get
                        if you successfully mine a block of the currency. The
                        amount of the reward halves every 210,000 blocks, or
                        roughly every four years. The amount is expected to hit
                        zero around the year 2140.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="blockReward"
                      onChange={this.onchange}
                      value={blockReward}
                      readOnly
                    />
                    <div className="sub-field">
                      BTC
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-4 mining-issue-margin">
                  <h5 className="mt-3 text-mining mb-3">
                    Difficulty Rate
                    <p className='tooltip-icon' id='difficultyRateTooltip'>?</p>
                    {/* <i
                      className="fa fa-info-circle ml-1"
                      id="difficultyRateTooltip"
                      title="Difficulty Rate"
                    ></i> */}
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="difficultyRateTooltip"
                    >
                      <PopoverBody>
                        Difficulty rate is a parameter that bitcoin and other
                        cryptocurrencies use to keep the average time between
                        blocks steady as the network's hash power changes.
                      </PopoverBody>
                    </UncontrolledPopover>
                  </h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="difficultyRate"
                      value={difficultyRate}
                      readOnly
                    />

                    <div className="sub-field">
                      G
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-4 mining-issue-margin">
                  <h5 className="mt-3 text-mining mb-3">Exchange Rate</h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="exchangeRate"
                      onChange={this.onchange}
                      value={exchangeRate}
                      readOnly={readOnly}
                    />
                    <div className="sub-field">
                      BTC/USD
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-4 mining-issue-margin">
                  <h5 className="mt-3 text-mining mb-3">Transaction Fee</h5>
                  <div className="input-with-select">
                    <input
                      type="text"
                      name="transactionFeeRate"
                      value={transactionFeeRate}
                      readOnly={readOnly}
                    />

                    <div className="sub-field">
                      %
                    </div>
                  </div>
                </div>

                <div className="calculateContainer">
                <button
                  className="btn-primary"
                  onClick={() => {
                    this.props.onCalculate({
                      hashRateType,
                      hashRate,
                      powerConsumptionType,
                      powerConsumption,
                      powerCost,
                      blockReward,
                      difficultyRate,
                      exchangeRate,
                      poolFees,
                      budget,
                      costPerTeraHash,
                      totalAsics,
                    });
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                  disabled={buttonDisabled}
                >
                  {buttonLoading && <i className="fa-spin fa fa-spinner m-2" />}
                  Calculate
                </button>
              </div>
              </div>
            </div>

            <section className="calculator-table">
                <div className="table-responsive">
                  <Table
                    data={calculationData}
                    column={tableColumn}
                    showPagination={false}
                    onSortedList={() => console.log('')}
                   />

                {
                  this?.props?.location?.pathname?.includes('mining_calculator') &&
                  <div
                    className="disclosure-container"
                  >
                    Disclosure: The Profit Details are the result of a chosen
                    budget of $
                    <span className="highlight-text" id="budgetlabel">
                      {niceNumberDecimalDisplay(budget, 2)}
                    </span>{" "}
                    to purchase Bitcoin mining servers that are hashing{" "}
                    <span id="hashratelabel">{hashRate} TH/s</span> at a price
                    of $<span id="costperterahashlabel">{costPerTeraHash}</span>{" "}
                    per terahash. This assumes a total hashrate of{" "}
                    <span id="totalhashrateforuserlabel">
                      {totalhashrateforuser}
                    </span>
                    . The Profit Details also take in consideration the current
                    network hash rate of {difficultyRate} and current BTC/USD
                    exchange rate of 1 BTC = $
                    <span id="exchangeRateLabel">
                      {niceNumberDecimalDisplay(parseFloat(exchangeRate), 2)}
                    </span>
                    . These figures vary based on the total network hash rate
                    and on the BTC to USD conversion rate. Block reward is fixed
                    at 6.25 BTC. The Profit Details do not take into account any
                    future changes in block rewards, hashrate and difficulty
                    rate. All inputs in this calculator vary over time. The
                    profit details is based on current values only and should be
                    used as a guide only.
                  </div>
                }
                  {/* <table className="table table-td-no-border mining-table-minimum mining-result-table">
                    <thead className="table-head">
                      <tr>
                        <th className="custom-table-th">Period</th>
                        <th className="custom-table-th">BTC Mined</th>
                        <th className="custom-table-th">BTC Mined (USD)</th>
                        <th className="custom-table-th">Pool Fees (BTC)</th>
                        <th className="custom-table-th">Pool Fees (USD)</th>
                        <th className="custom-table-th">
                          Electricity Costs (USD)
                        </th>
                        <th className="custom-table-th">Profit</th>
                      </tr>
                    </thead>
                    <tbody>{calculationRequestsHtml}</tbody>
                  </table> */}
                  {/* <div
                    className="mt-4"
                    style={{ fontSize: "12px", lineHeight: "18px" }}
                  >
                    Disclosure: The Profit Details are the result of a chosen
                    budget of $
                    <span className="highlight-text" id="budgetlabel">
                      {niceNumberDecimalDisplay(budget, 2)}
                    </span>{" "}
                    to purchase Bitcoin mining servers that are hashing{" "}
                    <span id="hashratelabel">{hashRate} TH/s</span> at a price
                    of $<span id="costperterahashlabel">{costPerTeraHash}</span>{" "}
                    per terahash. This assumes a total hashrate of{" "}
                    <span id="totalhashrateforuserlabel">
                      {totalhashrateforuser}
                    </span>
                    . The Profit Details also take in consideration the current
                    network hash rate of {difficultyRate} and current BTC/USD
                    exchange rate of 1 BTC = $
                    <span id="exchangeRateLabel">
                      {niceNumberDecimalDisplay(parseFloat(exchangeRate), 2)}
                    </span>
                    . These figures vary based on the total network hash rate
                    and on the BTC to USD conversion rate. Block reward is fixed
                    at 6.25 BTC. The Profit Details do not take into account any
                    future changes in block rewards, hashrate and difficulty
                    rate. All inputs in this calculator vary over time. The
                    profit details is based on current values only and should be
                    used as a guide only.
                  </div> */}
                </div>
            </section>

            {/* <section>
              <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-md-4 mgtp-sm-4 mgtp-xs-4">
                <div className="card-header">
                  <h4 className="card-title-font form-table-header">
                    Profit Details
                  </h4>
                </div>
                <div className="table-responsive">
                  {!!calculationData &&
                    calculationData.length > 0 &&
                    calculationData.map((details) => (
                      <List>
                        <ListItem>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              {" "}
                              {details.label}
                            </Grid>
                            <Grid item xs={12}>
                              BTC Mined (USD): {details.btcMined}
                            </Grid>
                            <Grid item xs={12}>
                              BTC Mined (USD): &#36;
                              {niceNumberDecimalDisplay(details.btcMinedUsd, 2)}
                            </Grid>
                            <Grid item xs={12}>
                              Pool Fees (BTC): {details.poolFeesBtc}
                            </Grid>
                            <Grid item xs={12}>
                              Pool Fees (USD): &#36;
                              {niceNumberDecimalDisplay(details.poolFeesUsd, 2)}
                            </Grid>
                            <Grid item xs={12}>
                              Electricity Costs (USD): &#36;
                              {niceNumberDecimalDisplay(
                                details.electricityCostUsd,
                                0
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              Profit: &#36;
                              {niceNumberDecimalDisplay(details.profit, 2)}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                      </List>
                    ))}
                </div>
              </div>
            </section> */}
          </div>
        </div>

        <BudgetModal
          authToken={this.props.authToken}
          onCloseModalBudget={this.onCloseModalBudget}
          BudgetModalOpen={BudgetModalOpen}
          paymentSource="budget"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(withRouter(CalculatorComponent));
