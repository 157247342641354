import React, { useState, useEffect } from 'react';
import './table.scss';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner/Spinner';
import sortingicon from '../../assets/img/sorting.svg';
import { useLocation } from 'react-router-dom';

const Table = ({
  data,
  column,
  redirectTo,
  onSortedList,
  showPagination,
  currentPage,
  totalSize,
  itemsPerPage = 8,
  onPageChange,
  loading,
  className,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [sortedData, setSortedData] = useState(data || []);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const location = useLocation();
  const totalPages = Math.ceil(totalSize / itemsPerPage);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (sortConfig.key) {
      const sorted = [...data].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });

      setSortedData(sorted);
      onSortedList && onSortedList(sorted);
    } else {
      setSortedData(data);
    }
  }, [data, sortConfig, onSortedList]);

  const handleSort = (accessor) => {
    setSortConfig((prevConfig) => ({
      key: accessor,
      direction: prevConfig.key === accessor && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderDesktopTable = () => (
    <div className="list-body">
      <table className={`table custom-table ${className}`}>
        <thead>
          <tr className="header-row">
            {column?.map((tableData, colIndex) => (
              <>
                {(tableData.heading || tableData.HeadCell) && (
                  <th
                    key={colIndex}
                    className={colIndex === 0 ? 'sorting-icon' : ''}
                  >
                    {colIndex === 0 && (
                      <img
                        src={sortingicon}
                        alt="sortingicon"
                        onClick={() => handleSort(tableData.accessor)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {tableData.HeadCell
                      ? tableData.HeadCell()
                      : tableData.heading}
                  </th>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData?.length > 0 ? (
            sortedData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={redirectTo ? () => redirectTo(row) : null}
              >
                {column?.map((tableData, colIndex) => (
                  <td key={colIndex}>
                    {tableData.Cell
                      ? tableData.Cell({
                          value: row[tableData.accessor],
                          row,
                        })
                      : row[tableData.accessor]}
                  </td>
                ))}
              </tr>
            ))
          ) : loading ? (
            <tr>
              <td colSpan={12}>
                <Spinner style={{ position: 'absolute' }} />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={12}>
                <div className="no-data-found">
                  <p>No Data Found</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const renderMobileTable = () => (
    <div className="mobile-table">
      {sortedData?.map((row, rowIndex) => (
        <div key={rowIndex} className="mobile-table-row">
          {column?.map((tableData, colIndex) => (
            <div key={colIndex} className="mobile-table-cell">
              <strong>
                {tableData.HeadCell ? tableData.HeadCell() : tableData.heading}{location.pathname !== '/wallets' ? ':':''}
              </strong>{' '}
              <p>
              {tableData.Cell
                ? tableData.Cell({ value: row[tableData.accessor], row })
                : row[tableData.accessor]}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className="table-container">
      <>
        {isMobile ? renderMobileTable() : renderDesktopTable()}

        {showPagination && (
          <section className="table-footer">
            <div>
              <p>{totalSize || 0} items</p>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </section>
        )}
      </>
    </div>
  );
};

export default Table;
