import React, { useState } from 'react'
import DashHeader from '../DashHeader/DashHeader'
import PlanCard from './PlanCard';
import './subscription.scss';
import subscriptionBg from '../../assets/img/subscription-bg.png';
import Modal from '../Modal/Modal';
import SelectPaymentModal from './SelectPaymentModal';

const SubscriptionsPlan = () => {
    const [paymentMethodSelection,setPaymentMethodSelection] = useState(false);
    const [planSelection,setPlanSelction] = useState();
    const [paymentMethod,setPaymentMethod] = useState(0);

    const handleSubscriptionPlan = (selected) => {
        setPaymentMethodSelection(true);
        setPlanSelction(selected)
    }
    const subscriptionPlans = [
        {
            heading: 'Basic Plan',
            price: '29$/month',
            features: [
                'Access to core features',
                'Basic analytics and reporting',
                '1 user account',
                'Email support'
            ],
            buttonText: 'Choose Basic Plan',
            subHeading: "Perfect for small businesses or freelancers looking to get started with our services.",
        },
        {
            heading: 'Professional Plan',
            price: '79$/month',
            features: [
                'Advanced analytics and insights',
                'Up to 5 user accounts',
                'Priority email support',
                'Customizable settings and branding'
            ],
            buttonText: 'Choose Professional Plan',
            subHeading: 'Perfect for growing teams who need deeper insights and collaboration tools.',
        },
        {
            heading: 'Enterprise Plan',
            price: '149$/month',
            features: [
                'Dedicated account manager',
                'Unlimited user accounts',
                '24/7 premium support',
                'API access and integration support'
            ],
            buttonText: 'Choose Enterprise Plan',
            subHeading: 'Perfect for large organizations needing full access to advanced features and dedicated support.',
        }
    ];    

    return (
        <div style={{backgroundImage: `url(${subscriptionBg})`}} className='subscription-plan-container'>
            <DashHeader heading={'Subscriptions'} description={'Unlock advanced features and manage workers'} />
            <div className='card-main-container'>
                <div className='plan-card-container'>
                    {
                        subscriptionPlans.map((plan, index) => (
                            <PlanCard plan={plan} key={index} setPlanSelction={handleSubscriptionPlan} />
                        ))
                    }
                </div>
            </div>

            <Modal 
              isOpen={paymentMethodSelection} 
              onClose={() => {
                setPaymentMethodSelection(false)
                setPaymentMethod(0);
              }
              } 
              modalWidth={750}
            >
              <SelectPaymentModal 
                planSelection={planSelection} 
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
            />
            </Modal>
        </div>
    )
}

export default SubscriptionsPlan;
