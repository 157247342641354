import React from 'react';
import './compute-market.scss';

const ComputeCard = ({ compute,index }) => {
  return (
    <div key={index} className='compute-market-card'>
      <div className='card-heading'>
        <h5>{compute.heading}</h5>
        <span>{compute.hour}</span>
      </div>

      <div className='card-specs'>
        <div className='specs-box'>
          <p>GPUs:</p>
          <span>{compute.gpu}</span>
        </div>
        <div className='specs-box'>
        <p>VCPUs:</p>
        <span>{compute.vcps}</span>
      </div> 
      <div className='specs-box'>
        <p>RAM:</p>
        <span>{compute.ram}</span>
      </div> 
      <div className='specs-box'>
        <p>Availibity:</p>
        <span>{compute.availibity}</span>
      </div>
      <div className='specs-box'>
        <p>GPU Memory:</p>
        <span>{compute.gpuMemory}</span>
      </div>
      <div className='specs-box'>
        <p>SSD:</p>
        <span>{compute.ssd}</span>
      </div>
      </div>

     <button className='btn-primary'>Reserve Now</button>
    </div>
  )
}

export default ComputeCard