import React from 'react'
import paymentSucessIcon from '../../assets/img/payment-success.svg';
import bgLayer from '../../assets/img/success-bg.png';
import './payment-success.scss';
const PaymentSuccess = () => {
  return (
    <div className='payment-success-container' style={{backgroundImage: `url(${bgLayer}),linear-gradient(#181921, #181921)`,}}>
      <div className='payment-succes-content'>
        <img src={paymentSucessIcon} alt='paymentSucessIcon' />
        <h2>You have successfully activated the "Enterprise Plan"</h2>
        <p>Congratulations on your purchase. Let's open a new horizon of functionality together!</p>
        <div>
          <button className='btn-secondary'>Back to homepage</button>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess