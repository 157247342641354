import React, { Component } from "react";
import Modal from "../Modal/Modal";
import infoIcon from "../../assets/img/red-info.svg";
import "./ChangePasswordModal.scss";

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      changePasswordModalOpen,
      onCloseModal,
      onInputValueChange,
      changePasswordButtonLoading,
      updatePassword,
      errorMessage,
    } = this.props;
    let buttonDisabled = changePasswordButtonLoading === true ? true : false;

    return (
      <Modal
        isOpen={changePasswordModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        modalWidth={784}
        modalTitle="Change Password"
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="password-change-modal">
          <div role="document">
            <div>
              <div>
                <div>
                  <div>
                    <div className="feature-list mt-3">
                      <div className="border-gap">
                        <p>
                          Pick a strong password. Use a combination of numbers,
                          letters & special characters.
                        </p>
                        <p className="mt-3  mb-0">
                          It should be different from other passwords you use
                          elsewhere on the internet. Change your password
                          regularly.
                        </p>
                      </div>
                    </div>
                    <form className="mt-5">
                      <div className="form-group">
                        <label for="transactionAmount">
                          Current Password
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="input-field"
                          placeholder="Enter current password"
                          type="password"
                          name="oldPassword"
                          id="oldPassword"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label for="transactionAmount">
                          New Password<span className="required-field">*</span>
                        </label>
                        <input
                          className={`input-field ${
                            errorMessage ? "border-red-color" : ""
                          }`}
                          placeholder="Enter current password"
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label for="transactionAmount">
                          Confirm New Password
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className={`input-field ${
                            errorMessage ? "border-red-color" : ""
                          }`}
                          placeholder="Enter current password"
                          type="password"
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          onChange={onInputValueChange}
                        />

                        {errorMessage && (
                          <div className="error-msg-div">
                            <img src={infoIcon} alt="infoIcon" />
                            <p>{errorMessage}</p>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          className="btn-secondary"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn-primary"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={updatePassword}
                        >
                          Update{" "}
                          {changePasswordButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ChangePasswordModal;
