import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";
// import { toast } from "react-toastify";
import "./modals.css";
import { connect } from "react-redux";
import Toast from '../CustomToast/CustomToast';

let api;

class Stripe extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      authToken: this.props.authToken,
      product: {
        invoiceId: this.props.invoiceId,
        price: this.props.price,
        name: "permian",
        currency: this.props.currency,
      },
    };
    this.makePayment = this.makePayment.bind(this);
  }

  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "stripe",
    });

    if (response.code === 200) {
      this.setState({ stripeKey: response.data[0].key });
    }
  }

  async makePayment(token) {
    this.props.depositOnCloseModal();

    const body = {
      token,
      product: this.state.product,
    };
    let authenticationToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authenticationToken,
    };

    return fetch(`${API_ROOT}/miner/StripePayment`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          Toast({
            type:'success',
            title: 'Success',
            message: "Payment Requested Successfully!",
          });
          // toast.success("Payment Requested Successfully!");
          this.props.getRecords(this.props.reference);
        } else {
          Toast({
            type:'error',
            title: 'Payment Error',
            message:"Payment Not Requested Successfully!",
          });
          // toast.error("Payment Not Requested Successfully!");
        }
      })
      .catch((error) => console.log(error));
  }
  render() {
    const {
      price,
      currency,
      stripeKey,
      setHideInsideModal,
      setShowInsideModal,
    } = this.props;
    return (
      <div>
        <StripeCheckout
          stripeKey={stripeKey}
          token={this.makePayment}
          name="Pay Bill"
          amount={price * 100}
          currency={currency}
          billingAddress
          opened={setHideInsideModal}
          closed={setShowInsideModal}
        >
          <span className="d-block">{currency}</span>
        </StripeCheckout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(Stripe);
