import React, { Component } from "react";
// import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import Api from "../../../services/api";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { API_ROOT } from "../../../services/api-config";
import { connect } from "react-redux";
import Toast from '../../CustomToast/CustomToast';
import Modal from '../../Modal/Modal';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let api;

class PaymentRequestModal extends Component {
  constructor(props, state) {
    super(props);
    api = new Api();
    this.state = {
      transactionAmount: "",
      quantity: "",
      userNote: "",
      unitAmount: "",
      wireTransferDetails: "",
    };
    this.changeAmount = this.changeAmount.bind(this);
    this.changeUserNote = this.changeUserNote.bind(this);
    this.saveNrtBuyPaymentRequest = this.saveNrtBuyPaymentRequest.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.uploadImageArray = {};
  }

  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "wireTransfer",
    });

    if (response.code === 200) {
      let details = response.data[0].wireTransferPaymentCredentials;
      const detailsArr = details.split(",");

      this.setState({
        wireTransferDetails: detailsArr,
      });
    }
    this.getPriceDetails();
  }

  async getPriceDetails() {
    const { paymentSource } = this.props;
    const priceResponse = await api.get("miner/StripePrice", {
      type: paymentSource,
    });
    if (
      priceResponse.code === 200 &&
      priceResponse.data &&
      !_.isEmpty(priceResponse.data)
    ) {
      this.setState({
        unitAmount: Number(priceResponse.data.stripePrice.unitPrice),
      });
    }
  }

  changeQuantity(e) {
    const { unitAmount } = this.state;
    this.setState(
      {
        quantity: e.target.value,
      },
      () => {
        this.setState({
          transactionAmount: this.state.quantity * unitAmount,
        });
      }
    );
  }
  onChangeCurrencyType(value) {
    this.setState({
      currency: value,
    });
  }
  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }
  async saveNrtBuyPaymentRequest() {
    if (this.state.quantity > 0 && this.state.transactionAmount > 0) {
      this.props.onCloseModal();
      this.props.onCloseModalBudget();
      const data = new FormData();
      data.append("proofOfPayment", this.uploadImageArray["file"]);
      data.append("amount", this.state.transactionAmount);
      data.append("userNote", this.state.userNote);
      data.append("qty", this.state.quantity);
      data.append("unitPrice", this.state.unitAmount);

      if (
        this.uploadImageArray["file"] &&
        this.state.transactionAmount &&
        this.state.quantity &&
        this.state.unitAmount
      ) {
        let authenticationToken = this.props.authToken;
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authenticationToken,
          },
        };

        axios
          .post(API_ROOT + "/miner/WireTransferBudgetPayment", data, config)
          .then((response) => {
            if (response.data.code === 200) {
              Toast({
                type:'success',
                title: 'Success',
                message:response.data.message,
              });
              // toast.success(response.data.message);
            } else {
              this.props.onCloseModal();
              Toast({
                type:'error',
                title: 'Error',
                message:response.data.message,
              });
              // toast.error(response.data.message);
            }
          })
          .catch((error) => {
            this.props.onCloseModal();
            Toast({
              type:'error',
              title: 'Error',
              message:"Please upload valid payment proof file.",
            });
            // toast.error("Please upload valid payment proof file.");
            console.log(error);
          });
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:"Please fill  all the required detials!",
        });
        // toast.error("Please fill  all the required detials!");
      }
    } else {
      Toast({
        type:'error',
        title: 'Error',
        message:"Please make sure the quantity is greater than 0!",
      });
      // toast.error("Please make sure the quantity is greater than 0!");
    }
  }
  async changeAmount(event) {
    const { unitAmount } = this.state;
    this.setState(
      {
        transactionAmount: event.target.value,
      },
      () => {
        this.setState({
          quantity: this.state.transactionAmount / unitAmount,
        });
      }
    );
  }
  async changeUserNote(event) {
    this.setState({
      userNote: event.target.value,
    });
  }
  render() {
    const {
      paymentRequestModalOpen,
      onCloseModal,
      paymentRequestButtonLoading,
    } = this.props;
    const { wireTransferDetails, transactionAmount, quantity } = this.state;
    let wireFrameData = wireTransferDetails;
    let buttonDisabled = paymentRequestButtonLoading === true ? true : false;

    return (
      <Modal
        isOpen={paymentRequestModalOpen}
        onClose={onCloseModal}
        modalWidth={750}
        modalTitle="Make FIAT Payment Request"
      >
        <div className='wire-tranfer-modal-container'>
          <div className="desc">
            Submit the below form to deposit your budget. Make sure to
            specify the same Purpose of Transaction on your wire
            transfer as instructed below.
          </div>
          <form className="mt-3">
            <div className="form-group text-black">
              <label
                htmlFor="Quantity"
                className="text-default-color"
              >
                Quantity
                <span className="required-field">*</span>
              </label>
              <input
                className="input-field"
                id="Quantity"
                placeholder="Enter Quantity"
                type="number"
                value={quantity}
                name="Quantity"
                onChange={this.changeQuantity}
              />
            </div>

            <div className="form-group text-black">
              <label
                htmlFor="transactionAmount"
                className="text-default-color"
              >
                Budget Amount
                <span className="required-field">*</span>
              </label>
              <div className="d-flex">
                <input
                  className="input-field"
                  id="transactionAmount"
                  placeholder="Enter transaction amount"
                  type="number"
                  value={transactionAmount}
                  name="transactionAmount"
                  onChange={this.changeAmount}
                  disabled={true}
                />
              </div>
            </div>
            <div class="form-group text-black">
              <label
                htmlFor="userNote"
                className="text-default-color"
              >
                Wire transfer details to make payment
              </label>
              <div className='wire-transfter-nfo'
              >
                {wireFrameData
                  ? wireFrameData.map((data, index) => {
                      const dataArr = data.split(":");
                      return (
                        <div
                          style={{ padding: "13px 14px 13px 33px" }}
                          className="row justify-content-start text-black"
                        >
                          <div>
                            <span className="text-white info">
                              {dataArr[0]} :{" "}
                            </span>
                            <span className="info-value">
                              {dataArr[1]}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div className="form-group text-white">
              <span className="transaction-purpose mr-3 text-bold">
                Purpose of transaction :
              </span>
              <span className="text-bold text-white">Computer Equipment</span>
            </div>
            <div className="form-group text-black">
              <label
                htmlFor="userNote"
                className="text-default-color"
              >
                Note
              </label>
              <textarea
                className="input-field"
                placeholder="Enter note"
                name="userNote"
                id="userNote"
                value={this.userNote}
                onChange={this.changeUserNote}
              ></textarea>
            </div>

            <label className="text-default-color text-black">
              {" "}
              Proof of Payment (pdf, jpg, jpeg)
              <span className="required-field">*</span>
            </label>
            <div
              className="resource-continaer payment-proof-container dropzoneIdContainer"
              // style={{ backgroundColor: "#dddddd" }}
            >
              <div className="row">
                <div className="col-md-12">
                  <FilePond
                    allowMultiple={false}
                    allowFileTypeValidation={true}
                    className="uploadProof"
                    acceptedFileTypes={[
                      "image/*",
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ]}
                    instantUpload={false}
                    onupdatefiles={(fileItems) => {
                      if (
                        !_.isEmpty(fileItems) &&
                        fileItems[0].fileSize < 5000000
                      ) {
                        if (
                          (fileItems[0].fileExtension === "pdf" ||
                            fileItems[0].fileExtension === "docx") &&
                          fileItems[0].fileSize < 5000000
                        ) {
                          this.fileUpdated(fileItems);
                        } else if (
                          (fileItems[0].fileExtension === "jpeg" ||
                            fileItems[0].fileExtension === "jpg" ||
                            fileItems[0].fileExtension === "png") &&
                          fileItems[0].fileSize < 500000
                        ) {
                          this.fileUpdated(fileItems);
                        } else if (
                          (fileItems[0].fileExtension === "jpeg" ||
                            fileItems[0].fileExtension === "jpg" ||
                            fileItems[0].fileExtension === "png") &&
                          fileItems[0].fileSize > 500000
                        ) {
                          fileItems[0].abortLoad();
                          Toast({
                            type:'error',
                            title: 'Error',
                            message:"Please make sure image size is under 500kb",
                          });
                          // toast.error(
                          //   "Please make sure image size is under 500kb"
                          // );
                        }
                      } else if (
                        !_.isEmpty(fileItems) &&
                        fileItems[0].fileSize > 5000000
                      ) {
                        fileItems[0].abortLoad();
                        Toast({
                          type:'error',
                          title: 'Error',
                          message:"Please make sure file size is under 5mb",
                        });
                        // toast.error(
                        //   "Please make sure file size is under 5mb"
                        // );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer-btn">
              <button
                className="btn-secondary"
                type="button"
                onClick={onCloseModal}
              >
                Cancel
              </button>
              <button
                 className="btn-primary"
                type="button"
                disabled={buttonDisabled}
                onClick={this.saveNrtBuyPaymentRequest}
              >
                {" "}
                Deposit{" "}
                {paymentRequestButtonLoading && (
                  <i className="fa-spin fa fa-spinner text-white m-1" />
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(PaymentRequestModal);
