import React, { Component } from "react";
import { ExportCSV } from "./ExportCSV";
import "./Earnings.scss";
import Modal from "../Modal/Modal";
class EarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      show: false,
      timeHorizon: "",
      earningData: [],
      fileName: "MinerData",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.setShow = this.setShow.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
    this.clearTime = this.clearTime.bind(this);
  }

  async setShow(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  async clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeStartDate(e) {
    this.setState(
      {
        startDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async onChangeEndDate(e) {
    this.setState(
      {
        endDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async onSubmit() {}

  render() {
    const { modalOpen, onCloseModal, earningsDatas } = this.props;

    const { fileName, timeHorizon } = this.state;
    return (
      <Modal
        isOpen={modalOpen}
        onClose={onCloseModal}
        modalWidth={750}
        modalTitle="Export Data"
      >
        <div className="earning-modal-container">
          <div>
            <p>Export list of my workers</p>
            <div className="form-group text-black">
              <label htmlFor="title">
                Start Date<span className="required-field">*</span>
              </label>
              <input
                className="input-field "
                id="startDate"
                type="date"
                value={this.state.startDate}
                onChange={this.onChangeStartDate}
              />
            </div>

            <div className="form-group text-black">
              <label htmlFor="title">
                End Date<span className="required-field">*</span>
              </label>
              <input
                className="input-field"
                id="endDate"
                type="date"
                value={this.state.endDate}
                onChange={this.onChangeEndDate}
              />
            </div>

            <div className="seperator-text">
              <span>OR</span>
            </div>
            <div className="time-horizon-field">
              <label>Select Time Horizon</label>
              <div>
                <div>
                  <select
                    className="input-field mb-2"
                    value={timeHorizon || ""}
                    onChange={(e) => this.onChangeTimeHorizon(e.target.value)}
                  >
                    <option value="" disabled>
                      Time Horizon
                    </option>
                    <option value="24H">24H</option>
                    <option value="7D">7D</option>
                    <option value="30D">30D</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <ExportCSV
                csvData={earningsDatas}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                timeHorizon={this.state.timeHorizon}
                onCloseModal={onCloseModal}
                fileName={fileName}
                clearTime={this.clearTime}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EarningModal;
