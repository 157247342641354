import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import './resources.scss';
class CardElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullDesc: false,
    };
    this.showDescription = this.showDescription.bind(this);
    this.expandDescription = this.expandDescription.bind(this);
  }

  expandDescription() {
    if (this.state.fullDesc === true) {
      this.setState({ fullDesc: false });
    } else if (this.state.fullDesc === false) {
      this.setState({ fullDesc: true });
    }
  }

  showDescription(description) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h3 className="text-bold text-center">Description</h3>
              <p className="alert__body text-center mt-2">{description}</p>
              <div className="text-center mt-3">
                <button
                  className="c-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    const { title, description, link, filetype, category, document } =
      this.props;
    const { fullDesc } = this.state;
    let newDes = description;

    if (newDes) {
      if (newDes.length > 70) {
        newDes = `${newDes.slice(0, 70)}...`;
      }
    }
    return (
      <div className='resource-card-container'>
        <h4>{title}</h4>
        <div className='category-icon-box'>
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6665 3.16667C1.6665 2.79848 1.96498 2.5 2.33317 2.5H6.33317L7.99984 4.5H13.6665C14.0347 4.5 14.3332 4.79847 14.3332 5.16667V13.8333C14.3332 14.2015 14.0347 14.5 13.6665 14.5H2.33317C1.96498 14.5 1.6665 14.2015 1.6665 13.8333V3.16667Z" fill="#25262B" stroke="#25262B" stroke-width="2" stroke-linejoin="round"/>
        </svg>
          <div>
            <span>{category}</span>
          </div>
        </div>
        <p>{fullDesc ? description : newDes}</p>
        <di className='resources-btn'>
          <button className='btn-primary'>View Material</button>
        </di>
      </div>
    );
  }
}
export default CardElement;
