import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import stripeLogo from "../../assets/img/stripe-seeklogo.com.svg";
import Api from "../../services/api";
import wireTransferLogo from "../../assets/img/bank.png";
import _ from "lodash";
import WireTransferModal from "./WireTransferModal";
import StripeTooltip from "../Layout/BudgetFoler/StripeTooltip";
import WireTooltip from "../Layout/BudgetFoler/WireTooltip";
import { Card, CardContent, Grid, CircularProgress } from "@material-ui/core";
import StripeIcon from '../../assets/img/stripe-icon.svg';
import WireTransfer from '../../assets/img/wire-tranfer.svg';

const useStyles = (theme) => ({
  card: {
    width: "100%",
    boxShadow: "0px 0px 16px rgb(0 0 0 / 22%)",
    marginTop: "40px",
    marginBottom: "20px",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  circular: {
    marginTop: "60px",
  },
});

let api;

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      email: "",
      onOpened: false,
      paymentRequestButtonLoading: false,
      paymentRequestModalOpen: false,
      earningData: [],
      fileName: "MinerData",
      orderId: "",
      isLoading: false,
      orderId: this.props.orderId,
    };
    this.handleStripePayment = this.handleStripePayment.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onPaymentRequestCloseModal =
      this.onPaymentRequestCloseModal.bind(this);
    this.showPaymentRequestModal = this.showPaymentRequestModal.bind(this);
  }

  async createOrder() {
    if (this.props.orderId) return this.props.orderId;
    if (this.state.orderId) return this.state.orderId;
    const response = await api.create("eshop/order/create", {});
    if (response.code === 200) {
      this.setState({ orderId: response.data });
    }
    return response.data;
  }

  async handleStripePayment() {
    this.setState({ isLoading: true });

    const orderId = await this.createOrder();
    const { selectedProduct, quantity, monthlyElectricityCost } = this.props;
    const url = `${window.location.origin}/journey?selectedProduct=${selectedProduct}&quantity=${quantity}&monthlyElectricityCost=${monthlyElectricityCost}&orderId=${orderId}`;

    const res = await api.create("eshop/createCheckout", {
      orderId,
      lineItems: [
        {
          name: `Miner Order ${orderId}`,
          amount: this.props.transactionAmount * 100,
          quantity: 1,
        },
      ],
      successUrl: url,
      cancelUrl: url,
    });
    if (res.code === 200) {
      this.setState({ isLoading: false });
      window.open(res.message, "_self");
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      orderId: nextProps.orderId,
    });
  }

  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "stripe",
    });

    if (response.code === 200) {
      this.setState({ stripeKey: response.data[0].key });
    }

    const addreesResponse = await api.create("user/getUserDetails");

    if (addreesResponse.code === 200) {
      this.setState({
        senderWalletAddress: addreesResponse.data.btcWalletAddress,
        email: addreesResponse.data.email,
      });
    }

    await this.createOrder();
  }

  showPaymentRequestModal() {
    this.setState({ paymentRequestModalOpen: true });
  }
  onPaymentRequestCloseModal() {
    this.setState({ paymentRequestModalOpen: false, isLoading: false });
  }

  async onChangeAddress(e) {
    this.setState({
      address: e.target.value,
    });
  }

  onUpload = () => {
    this.props.onUpload();
    this.setState({ isLoading: false });
  };

  onLoading = () => {
    this.setState({ isLoading: true });
  };

  render() {
    const { classes } = this.props;
    const { paymentRequestModalOpen, email, orderId } = this.state;
    return (
      <section>
        <div className="payment-select-container">
        <div className="payment-select-box">
          <div className="payment-header">
            <div>
              <img className="payment-icon" src={StripeIcon} alt='StripeIcon' />
              <h3>Stripe</h3>
            </div>
            <span>Credit Card</span>
          </div>
          <button onClick={this.handleStripePayment} className="btn-primary">Go To Checkout</button>
        </div>
        <div className="payment-select-box">
          <div className="payment-header">
            <div>
              <img className="payment-icon" src={WireTransfer} alt='StripeIcon' />
              <h3>FIAT Payment Transfer</h3>
            </div>
            <span>Wire Transfer</span>
          </div>
          <button 
            className="btn-primary" 
            onClick={() => {
                this.showPaymentRequestModal();
            }}
            >
              Go To Checkout
            </button>
        </div>
        </div>
       
        <WireTransferModal
            {...this.props}
            {...this.state}
            onCloseModal={this.onPaymentRequestCloseModal}
            paymentRequestModalOpen={paymentRequestModalOpen}
            onUpload={this.onUpload}
            onLoading={this.onLoading}
            email={email}
            transactionAmount={this.props.transactionAmount}
            orderId={orderId}
          />
        {/* <Card className={classes.card}>
          {this.state.isLoading ? (
            <CardContent>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <CircularProgress className={classes.circular} />
              </Grid>
            </CardContent>
          ) : (
            <CardContent>
              <div className=" d-flex  mt-4 justify-content-center align-items-center">
                <span
                  className="custom-tooltip-coin2 row justify-content-center s-icon-margin"
                  tooltip-title={`import Wallet`}
                >
                  <img
                    className="paymentIcons hovershadow"
                    src={stripeLogo}
                    style={{
                      borderRadius: "10px",
                      background: "#e9e9e9",
                    }}
                    alt=""
                    onClick={this.handleStripePayment}
                  />

                  <StripeTooltip />
                </span>

                <span
                  className="iconMargin custom-tooltip-coin2 row justify-content-center b-icon-margin"
                  tooltip-title={`import Wallet`}
                >
                  <div
                    className="paymentIcons hovershadow text-center"
                    style={{
                      background: "black",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      className="paymentIcons hovershadow"
                      style={{
                        position: "relative",
                        left: "-5px",
                        top: "-5px",
                        filter: "invert(100%)",
                        borderRadius: "10px",
                      }}
                      src={wireTransferLogo}
                      alt=""
                      onClick={() => {
                        this.showPaymentRequestModal();
                      }}
                    />
                  </div>

                  <WireTooltip />
                </span>
               
              </div>
            </CardContent>
          )}
        </Card> */}
      </section>
    );
  }
}
export default withStyles(useStyles)(PaymentMethod);
