import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Table from "../Table/Table";
import Api from "../../services/api";
import { pageProgress } from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import Tabs from "../Tabs/Tabs"; // Assuming a Tabs component exists
import "./Inventory.scss";
import StopLossTable from "./StopLossTable";
import DashHeader from "../DashHeader/DashHeader";

const WorkerInventory = () => {
  const api = new Api();

  const [tableLoading, setTableLoading] = useState(false);
  const [workerLoading, setWorkerLoading] = useState(false);
  const [inventoryPage, setInventoryPage] = useState(1);
  const [lossPage, setLossPage] = useState(1);
  const [inventorySizePerPage, setInventorySizePerPage] = useState(10);
  const [stopLossSizePerPage, setStopLossSizePerPage] = useState(10);
  const [inventoryTotalSize, setInventoryTotalSize] = useState(0);
  const [stopLossSize, setStopLossSize] = useState(0);
  const [workerData, setWorkerData] = useState([]);
  const [lossData, setLossData] = useState([]);
  const [filter, setFilter] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const statusColors = {
    delivered: "purple",
    installed: "success",
    repairing: "warning",
    "in transit": "warning",
    configuring: "primary",
    "stop loss": "red",
  };

  useEffect(() => {
    pageProgress("force_remove");
    document.title =
      messageConstants.WORKER_INVENTORY_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    fetchWorkerInventory();
    fetchStopLoss();
  }, []);

  const handleTabChange = (info,index) => {
    setActiveTab(index);
  };

  const onChange = (event) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [event.target.name]: event.target.value,
    }));
  };

  const onStatusChange = (obj) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      status: obj ? obj.value : "",
    }));
  };

  const doFilterRecords = () => {
    setInventoryPage(1);
    fetchWorkerInventory();
  };

  const clearFilterRecords = () => {
    setFilter({ workerIdViaBTC: "", serial: "", status: "" });
    fetchWorkerInventory();
  };

  const fetchStopLoss = useCallback(async () => {
    const response = await api.get("miner/getStopLoss", {
      page: lossPage,
      sizePerPage: stopLossSizePerPage,
    });
    if (response.code === 200) {
      const data = response.data.stopLoss.map((d) => ({
        ...d,
        binanceAccountNumber: d.userId.binanceAccountNumber || "",
        email: d.userId.email || "",
        date: moment(d.updatedAt).format("YYYY-MM-DD"),
        status: d.status === "s" ? "Proceeded" : "Pending",
      }));
      setLossData(data);
      setStopLossSize(response.data.total);
    }
  }, [lossPage, stopLossSizePerPage]);

  const fetchWorkerInventory = useCallback(async () => {
    try {
      setWorkerLoading(true);
      setTableLoading(true);
      const response = await api.get("miner/workerInvetory", {
        ...filter,
        page: inventoryPage,
        sizePerPage: inventorySizePerPage,
      });
      if (response.code === 200 && response.data?.workerInventory) {
        setWorkerData(response.data.workerInventory);
        setInventoryTotalSize(response.data.total);
      } else {
        throw new Error("Failed to fetch worker inventory.");
      }
    } catch (error) {
      alert(`${error}`);
    } finally {
      setWorkerLoading(false);
      setTableLoading(false);
    }
  }, [inventoryPage, inventorySizePerPage, filter]);

  useEffect(() => {
    fetchStopLoss();
  }, [fetchStopLoss]);

  useEffect(() => {
    fetchWorkerInventory();
  }, [fetchWorkerInventory]);

  const handleTableChange = (tableType, type, params) => {
    const { page, sizePerPage } = params;
    if (type === "pagination") {
      if (tableType === "stopLoss") {
        if (stopLossSizePerPage !== sizePerPage || lossPage !== page) {
          setStopLossSizePerPage(sizePerPage);
          setLossPage(page);
        }
      } else {
        if (inventorySizePerPage !== sizePerPage || inventoryPage !== page) {
          setInventorySizePerPage(sizePerPage);
          setInventoryPage(page);
        }
      }
    }
  };

  const tabs = ['Miner', 'Stop Loss', 'Sub Account'];

  const inventoryColumns = [
    {
      heading:'Miner',
      accessor:'model'
    },
    {
      heading:'Serial Number',
      accessor:'serial'
    },
    {
      heading:'HR',
      accessor:'hashrate'
    },
    {
      heading:'W',
      accessor:'powerConsumption'
    },
    {
      heading:'W/THs',
      accessor:'powerWth'
    },
    {
      heading:'Location',
      accessor:'location'
    },
    {
      heading:'Worker ID',
      accessor:'workerIdViaBTC'
    },
    {
      heading:'Sub Account ID',
      accessor:'subAccountId'
    },
    {
      heading:'STATUS',
      accessor:'status',
      Cell:(({value,row}) => {
        const badgeColor = statusColors[value.toLowerCase()] || "secondary";
        return <span className={"badge badge-" + badgeColor}>{value}</span>;
      })
    },
    {
      heading:'Notes',
      accessor:'notes'
    },
  ]

  const stopLossColumns = [
    {
      heading:'Sub Account ID',
      accessor:'binanceAccountNumber'
    },
    {
      heading:'Email',
      accessor:'email'
    },
    {
      heading:'Workers',
      accessor:'number'
    },
    {
      heading:'Date',
      accessor:'date'
    },
    {
      heading:'Status',
      accessor:'status',
      Cell:({ row }) => <div className={row.status.toLowerCase() === 'pending' ? 'badge-warning':'badge-success'}>{row.status}</div>
    },
  ]

  return (
    <div className="worker-inventory-container">
       <DashHeader heading={'Mining Equipment Inventory'} description={'Monitor the status and performance of your miners'} />
       <Tabs tabs={tabs} onTabChange={handleTabChange} />
        {
          activeTab === 0 ? (
          <Table
            data={workerData}
            column={inventoryColumns} 
            currentPage={inventoryPage}
            itemsPerPage={inventorySizePerPage}
            totalSize={inventoryTotalSize}
            showPagination={true}
            onPageChange={(page) =>
              handleTableChange("workerInventory", "pagination", { page,sizePerPage:10 })
            }
          />
        ) : activeTab === 1 ?(
          <StopLossTable activeTab={activeTab} />
        ):
        <Table
            data={lossData}
            column={stopLossColumns} 
            currentPage={lossPage}
            showPagination={true}
            itemsPerPage={stopLossSizePerPage}
            totalSize={stopLossSize}
            onPageChange={(page) =>
              handleTableChange("stopLoss", "pagination", { page,sizePerPage:10 })
            }
          />
        }
    </div>
  );
};

export default WorkerInventory;
