import React, { Component } from "react";
import _ from "lodash";
import Api from "../../../services/api";
import * as messageConstants from "../../../utils/Messages";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import HomePage from "./HomePage";
import { Elements } from "@stripe/react-stripe-js";
import "./Budget.css";
import { pageProgress } from "../../../utils/Util";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../../store/actions/UserAction";
// import { toast } from "react-toastify";
import Toast from '../../CustomToast/CustomToast';

let api;

class Budget extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      userName: "",
      email: "",
      line1: "",
      page: 1,
      confirmationEmail: "",
      countryName: "Select country",
      updateCardCountry: "Select Country",
      countryIdValue: "",
      isHavingBudget: "",
      stripeCustomerId: "",
      countryId: "",
      existingexpiry: "",
      userPostalCode: "",
      countryCode: "",
      renderFlag: false,
      countryList: [],
      quantity: "",
      paymentSource: "",
      unitAmount: "",
      priceId: "",
      state: "",
      city: "",
      stripeKey: "",
      country: "Select country",
      stripePromise: "",
    };
    this.optionRef = React.createRef();
    this.onCloseModal = this.onCloseModal.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.getPriceDetails = this.getPriceDetails.bind(this);
    this.updateModalOnCloseModal = this.updateModalOnCloseModal.bind(this);
    this.showuUpdateModal = this.showuUpdateModal.bind(this);
    this.checkCardDetails = this.checkCardDetails.bind(this);
    this.getStripeKey = this.getStripeKey.bind(this);
  }

  async getStripeKey() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "stripe",
    });

    if (response.code === 200) {
      this.setState({
        stripeKey: response.data[0].key,
        stripePromise: loadStripe(response.data[0].key),
      });
    }
  }

  onMenuOpen() {
    const { countryList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countryList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  }

  showuUpdateModal() {
    this.setState({
      updateModal: true,
    });
  }
  updateModalOnCloseModal() {
    this.setState({
      updateModal: false,
    });
  }

  async viewCustomerDetails() {
    const { countryList, paymentSource } = this.state;
    const response = await api.get("miner/viewCustomerDetails", {
      type: paymentSource,
    });
    if (response.code === 200) {
      if (response.data) {
        if (response.data.billing_details) {
          this.setState(
            {
              countryIdValue: response.data.billing_details.address
                ? response.data.billing_details.address.country
                : "",
              email: response.data.billing_details.email,
              line1: response.data.billing_details.address
                ? response.data.billing_details.address.line1
                : "",
              state: response.data.billing_details.address
                ? response.data.billing_details.address.state
                : "",
              city: response.data.billing_details.address
                ? response.data.billing_details.address.city
                : "",
              countryCode: response.data.billing_details.address
                ? response.data.billing_details.address.country
                : "",
              countryId: response.data.billing_details.address
                ? response.data.billing_details.address.country
                : "",
              userPostalCode: response.data.billing_details.address
                ? response.data.billing_details.address.postal_code
                : "",
              existingexpiry: response.data.card
                ? `${response.data.card.exp_month}/${String(
                    response.data.card.exp_year
                  ).slice(-2)}`
                : "",
            },
            () => {
              if (countryList) {
                const getCountry = countryList.filter((data) => {
                  return data.value === this.state.countryCode;
                });
                if (!_.isEmpty(getCountry)) {
                  this.setState({ country: getCountry[0].label }, () => {});
                }
              }
            }
          );
        } else if (response.data.address) {
          this.setState(
            {
              countryIdValue: response.data.address
                ? response.data.address.country
                : "",
              userName: response.data.name,
              email: response.data.email,
              line1: response.data.address ? response.data.address.line1 : "",
              state: response.data.address ? response.data.address.state : "",
              city: response.data.address ? response.data.address.city : "",
              countryCode: response.data.address
                ? response.data.address.country
                : "",
              countryId: response.data.address
                ? response.data.address.country
                : "",
              userPostalCode: response.data.address
                ? response.data.address.postal_code
                : "",
            },
            () => {
              if (countryList) {
                const getCountry = countryList.filter((data) => {
                  return data.value === this.state.countryCode;
                });
                if (!_.isEmpty(getCountry)) {
                  this.setState({ country: getCountry[0].label }, () => {});
                }
              }
            }
          );
        }
      }
    }
  }
  async checkCardDetails() {
    const response = await api.get("miner/viewBudget", {
      type: this.props.history.location.state,
    });
    if (response.code === 200) {
      if (response.data.budgetedMiner) {
        if (response.data.budgetedMiner["stripeCustomerId"]) {
          this.setState({ stripeCustomerId: true }, () => {
            if (this.state.stripeCustomerId === true) {
              this.viewCustomerDetails();
            }
          });
        }
      }
    }
  }
  handleCountryChange(selectedOption) {
    this.setState({
      countryIdValue: selectedOption.value,
      country: selectedOption.label,
      updateCardCountry: selectedOption.label,
    });
  }

  async getCountryList() {
    let { countries } = this.props;
    this.setState(
      {
        countryList: countries.map((country) => ({
          value: country.sortname,
          label: country.name,
        })),
      },
      () => {
        this.checkCardDetails();
      }
    );
    this.setState({
      renderFlag: true,
    });
    pageProgress("force_remove");
  }

  async getPriceDetails() {
    try {
      const type = this.props.history.location.state.type;
      const priceResponse = await api.get("miner/StripePrice", {
        type: type,
      });
      if (
        priceResponse.code === 200 &&
        priceResponse.data &&
        priceResponse.data.stripePrice
      ) {
        this.setState({
          unitAmount: Number(priceResponse.data.stripePrice.unitPrice),
          priceId: priceResponse.data.stripePrice.stripePriceId,
        });
      }
    } catch (err) {
      console.log(err.message);
      Toast({
        type:'error',
        title: 'Error',
        message:err.message,
      });
      // toast.error(err.message);
      return;
    }
  }

  async onChangeEmail(e) {
    this.setState({
      confirmationEmail: e.target.value,
    });
  }
  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      "Budget" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    const paymentSource = this.props.history.location.state;
    this.setState({ paymentSource });
    this.getStripeKey();
    this.setState({
      lastDate: moment().endOf("month").format("DD, MMM YYYY"),
    });
    this.getRecords();
    this.getPriceDetails();
    this.checkCardDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.countries !== prevProps.countries) {
      this.getCountryList();
    }
  }

  async getRecords() {
    const { fetchUserDetails } = this.props;
    fetchUserDetails((userDetails) => {
      this.setState(
        {
          userName: userDetails.fullName,
          isHavingBudget: userDetails.isHavingBudget,
          email: userDetails.email,
          isSubscribed: userDetails.isSubscribed,
          line1: userDetails.userKycDetail
            ? userDetails.userKycDetail.addressLine1
            : "",
          state: userDetails.userKycDetail
            ? userDetails.userKycDetail.state
            : "",
          city: userDetails.userKycDetail ? userDetails.userKycDetail.city : "",
          country: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId.name
            : "Select Country",
          countryCode: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId.sortname
            : "",
          countryId: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId._id
            : "",
          userPostalCode: userDetails.userKycDetail
            ? userDetails.userKycDetail.postalCode
            : "",
          countryIdValue: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId.sortname
            : "",
        },
        () => {
          this.getCountryList();
        }
      );
    });
  }

  render() {
    const {
      stripePromise,
      countryList,
      stripeCustomerId,
      countryIdValue,
      renderFlag,
      updateCardCountry,
      unitAmount,
      userPostalCode,
      countryCode,
      stripeKey,
    } = this.state;
    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box budgetContainer">
            <div className="row flex-column width500 budget-background">
              <div style={{ opacity: renderFlag ? "1" : "0.4" }}>
                {stripeKey && (
                  <Elements stripe={stripePromise}>
                    <HomePage
                      authToken={this.props.authToken}
                      userName1={this.state.userName}
                      userEmail={this.state.email}
                      userline1={this.state.line1}
                      renderFlag={renderFlag}
                      countriesList={countryList}
                      unitAmount={unitAmount}
                      priceId={this.state.priceId}
                      isHavingBudget={this.state.isHavingBudget}
                      getRecords={this.getRecords}
                      stripeCustomerId={stripeCustomerId}
                      optionRef={this.optionRef}
                      onMenuOpen={this.onMenuOpen}
                      userPostalCode={userPostalCode}
                      history={this.props.history}
                      userCountryIdValue={countryIdValue}
                      updateCardCountry={updateCardCountry}
                      showuUpdateModal={this.showuUpdateModal}
                      userCountryId={this.state.countryId}
                      userCity={this.state.city}
                      userstate={this.state.state}
                      handleCountryChange={this.handleCountryChange}
                      existingexpiry={this.state.existingexpiry}
                      userCountryCode={countryCode}
                      userQuantity={this.state.userQuantity}
                      usercountry={this.state.country}
                    />
                  </Elements>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
  countries: state.country.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
