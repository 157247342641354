import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import EarningModal from "./EarningModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Api from "../../services/api";
import { niceNumberDecimalDisplay, hashrateConverter } from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
import DashHeader from '../DashHeader/DashHeader';
import allWorker from '../../assets/img/all-workers.svg';
import onlineWorker from '../../assets/img/online-worker.svg';
import offlineWorker from '../../assets/img/offline-worker.svg';
import Table from '../Table/Table';

let api;

class Workers extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      workerLoading: true,
      workerData: [],
      workerPage: 1,
      counter: 0,
      workerTableData: [],
      workerSizePerPage: 20,
      workerRenderFlag: false,
      modalOpen: false,
      excelData: [],
      workerTotalSize: 0,
      offlineWorkers: 0,
    };
    pageProgress("force_remove");
    this.handleTableChange = this.handleTableChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.getAllData = this.getAllData.bind(this);
    this.setStatistics = this.setStatistics.bind(this);
  }

  getAllData() {
    try {
      const { workerTotalSize } = this.state;
      api
        .get("user/workers", {
          page: 1,
          sizePerPage: workerTotalSize,
        })
        .then(async (response) => {
          if (response.code === 200) {
            const workerArr = [];
            let workerData =
              response.data && response.data.workers
                ? response.data.workers
                : [];
            workerData.map(async (data) => {
              let worker = {};
              worker.name = data.worker_name;
              worker.realHashRate = await hashrateConverter(
                data.hashrate_10min
              );
              worker["24H"] = await hashrateConverter(data.hashrate_24hour);
              worker.reject = `${data.reject_rate * 100}%`;
              worker.lastActive = data.last_active
                ? moment(data.last_active * 1000).format("YYYY/MM/DD HH:MM:SS")
                : "";
              worker.status = data.worker_status;
              workerArr.push(worker);
            });

            this.setState({
              excelData: workerArr,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  showModal() {
    this.setState({
      title: "",
      modalOpen: true,
    });
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      messageConstants.WORKERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getRecords();
    this.setStatistics();
  }

  async setStatistics() {
    try {
      const response = await api.get("user/miner/minerRealTimeStatistics");

      if (response.code === 200) {
        if (response.data) {
          this.setState({
            validNum: response.data.active_workers,
            invalidNum: response.data.unactive_workers,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getRecords() {
    try {
      const { workerPage, workerSizePerPage } = this.state;
      const response = await api.get("user/workers", {
        page: workerPage,
        sizePerPage: workerSizePerPage,
      });

      if (response.code === 200) {
        let workerData =
          response.data && response.data.workers ? response.data.workers : [];
        const totalWorkers =
          response.data && response.data.totalWorkers
            ? response.data.totalWorkers
            : 0;

        workerData = await Promise.all(
          workerData.map(async (workerDetails) => {
            workerDetails.hashrate_10min = await hashrateConverter(
              workerDetails.hashrate_10min
            );
            workerDetails.hashrate_24hour = await hashrateConverter(
              workerDetails.hashrate_24hour
            );
            workerDetails.reject_rate = workerDetails.reject_rate * 100;
            return workerDetails;
          })
        );

        this.setState(
          {
            workerData,
            workerTotalSize: totalWorkers,
            counter: this.state.counter + 1,
            workerLoading: false,
            workerRenderFlag: true,
          },
          () => {
            if (this.state.counter === 1) {
              this.getAllData();
            }
          }
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.sizePerPage !== sizePerPage ||
      this.state.workerPage !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, workerPage: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const {
      workerData,
      workerSizePerPage,
      workerRenderFlag,
      workerPage,
      workerLoading,
      excelData,
      validNum,
      invalidNum,
    } = this.state;

    const columns = [
      {
        heading:'Name',
        accessor:'worker_name'
      },
      {
        heading:'Real Hashrate',
        accessor:'hashrate_10min',
        Cell:(value)=><span>{value}</span>
      },
      {
        heading:'24H',
        accessor:'hashrate_24hour',
        Cell:(value)=><span>{value || 0}</span>
      },
      {
        heading:'Reject',
        accessor:'reject_rate',
        Cell:(value)=>{
          let reject = niceNumberDecimalDisplay(value, 2);
          return <span>{reject ? reject : 0}%</span>;
        }
      },
      {
        heading:'Last Active',
        accessor:'last_active',
        Cell:(value,row)=>{
          const timestamp = value * 1000;
          return row.last_active
            ? moment(timestamp).format("YYYY/MM/DD HH:MM:SS")
            : "";
        }
      },
      {
        heading:'Status',
        accessor:'status',
        Cell:(value,row)=> <div className={value === 'online'?'green-btn':'red-btn'}><span></span>{value}</div>
      },
    ]
    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "worker_name",
        text: "Name",
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "hashrate_10min",
        text: "Real Hashrate",
        formatter: function (cell) {
          return <span>{cell}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate_24hour",
        text: "24H",
        formatter: function (cell) {
          return <span>{cell ? cell : 0}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "reject_rate",
        text: "Reject",
        formatter: function (cell) {
          let reject = niceNumberDecimalDisplay(cell, 2);
          return <span>{reject ? reject : 0}%</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "last_active",
        text: (
          <>
            Last Active <span className="font-weight-normal">(YYYY/MM/DD)</span>
          </>
        ),
        formatter: function (cell, row) {
          const timestamp = cell * 1000;
          return row.last_active
            ? moment(timestamp).format("YYYY/MM/DD HH:MM:SS")
            : "";
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "worker_status",
        text: "Status",
        formatter: function (cell, row) {
          return <span>{_.toUpper(cell)}</span>;
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <Table 
          showPagination={true}
          column={columns}
          data={data}
          onSortedList={() => console.log(data)}
        />
        {/* <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable "
        /> */}
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="worker-continer buy-xpr-container">
        <div className="content-i">
          <DashHeader heading={'My Workers'} description={'Monitor and manage your mining workers efficiently'} />
          
          <div className='worker-box-container'>
            <div className='worker-main-boxes'>
              <div className='worker-box'>
                <img src={allWorker} alt='all worker' width={16} height={16} />
                <div>
                  <h2>{(validNum || 0) + (invalidNum || 0)}</h2>
                  <p>All workers</p>
                </div>
              </div>
              <div className='worker-box'>
              <img src={onlineWorker} alt='all worker' width={16} height={16} />
                <div>
                  <h2>{validNum || 0}</h2>
                  <p>Online workers</p>
                </div>
              </div>
              <div className='worker-box'>
              <img src={offlineWorker} alt='all worker' width={16} height={16} />
                <div>
                  <h2>{invalidNum || 0}</h2>
                  <p>Offline workers</p>
                </div>
              </div>
            </div>

            <div
                className="mt-3 exportData"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => this.showModal()}
                >
                  Export Data
                </button>
                <EarningModal
                  {...this.props}
                  {...this.state}
                  earningsDatas={excelData}
                  onCloseModal={this.onCloseModal}
                  onInputValueChange={this.onchange}
                />
              </div>
          </div>

          <div className="earnings-table table-responsive">
            {/* {workerRenderFlag === true && ( */}
              <WorkerRemoteAll
                data={workerData}
                page={workerPage}
                sizePerPage={workerSizePerPage}
                totalSize={this.state.workerTotalSize}
                onTableChange={this.handleTableChange}
              />
            {/* )} */}
          </div>
          
        </div>
      </div>
    );
  }
}

export default Workers;
