import React, { Component } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import * as messageConstants from "../../utils/Messages";
import _ from "lodash";
import Api from "../../services/api";
import { pageProgress } from "../../utils/Util";
import DashHeader from "../DashHeader/DashHeader";
import Tabs from "../Tabs/Tabs";
import Table from "../Table/Table";
import './orders.scss';

let api;

class Orders extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      machinePage: 1,
      macineSizePerPage: 10,
      machineLoading: false,
      machineData: null,
      machineTotalSize: 0,
      eshopMachinePage: 1,
      eshopMacineSizePerPage: 10,
      eshopMachineLoading: false,
      eshopMachineData: null,
      eshopMachineTotalSize: 0,
      activeTab:0
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  async componentDidMount() {
    pageProgress("force_remove");
    document.title =
      "My Orders" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getMachineRecords();
    this.getMachineRecordsFromEShop();
  }

  async getMachineRecords() {
    const { macineSizePerPage, machinePage } = this.state;
    this.setState({ machineLoading: true });
    const response = await api.get("user/machines", {
      sizePerPage: macineSizePerPage,
      page: machinePage,
    });

    this.setState({
      machineLoading: false,
    });
    if (response.code !== 200) {
      return alert("Fail to fetch machines");
    }
    this.setState({
      machineData: response.data.machines,
      machineTotalSize: response.data.totalMachines,
    });
  }

  async getMachineRecordsFromEShop() {
    const { eshopMacineSizePerPage, eshopMachinePage } = this.state;
    this.setState({ machineLoading: true });
    const response = await api.get("eshop/machinesForMiner", {
      sizePerPage: eshopMacineSizePerPage,
      page: eshopMachinePage,
    });
    if (response.code === 200) {
      return this.setState({
        eshopMachineData: response.data.machines,
        eshopMachineTotalSize: response.data.totalMachines,
      });
    }
    this.setState({
      eshopMachineLoading: false,
    });
    alert("Fail to fetch machines from eShop");
  }

  handleTabChange = (name,tabIndex) => this.setState({activeTab:tabIndex});
  
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.sizePerPage !== sizePerPage ||
      this.state.machineData !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, machineData: page }, () => {
        this.getMachineRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getMachineRecords();
        });
        return true;
      }
    }
  };

  render() {
    const {
      machineLoading,
      machineData,
      machinePage,
      macineSizePerPage,
      machineTotalSize,
      eshopMachineLoading,
      eshopMachineData,
      eshopMachinePage,
      eshopMacineSizePerPage,
      eshopMachineTotalSize,
    } = this.state;

    const columns = [
      {
        heading:'Invoice #',
        accessor:'invoiceNumber'
      },
      {
        heading:'Manufacturer',
        accessor:'manufacturer'
      },
      {
        heading:'Model',
        accessor:'model'
      },
      {
        heading:'Algorithm',
        accessor:'algorithm'
      },
      {
        heading:'Hashrate (TH)',
        accessor:'hashrate'
      },
      {
        heading:'Power Consumption (W)',
        accessor:'powerConsumption'
      },
      {
        heading:'Price in USD',
        accessor:'priceInUsd'
      },
      {
        heading:'Quantity',
        accessor:'quantity'
      },
      {
        heading:'Status',
        accessor:'status',
        Cell:({row,value}) => {
          return(
            <div className={row.status === 'installed' || row.status.toLowerCase() === 'paid' ? 'badge-success':'badge-warning'}>{row.status}</div>
          )
        }
      },
      {
        heading:'Operations',
        accessor:'status',
        Cell:({row,trackLink}) =>{
          return (
            <div style={{textAlign:'center'}}
            >
              {!_.isUndefined(row) && (
                <div>
                  <a
                    rel="noopener noreferrer"
                    href={row.trackLink}
                    target="_blank"
                  >
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.49984 4.5H4.49984C4.14622 4.5 3.80708 4.64048 3.55703 4.89052C3.30698 5.14057 3.1665 5.47971 3.1665 5.83333V12.5C3.1665 12.8536 3.30698 13.1928 3.55703 13.4428C3.80708 13.6929 4.14622 13.8333 4.49984 13.8333H11.1665C11.5201 13.8333 11.8593 13.6929 12.1093 13.4428C12.3594 13.1928 12.4998 12.8536 12.4998 12.5V8.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.8335 9.16602L13.8335 3.16602" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5 3.16602H13.8333V6.49935" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  </a>
                </div>
              )}
              {(_.isUndefined(row) || row === "") && <div>N/A</div>}
            </div>
          );
        }
      }
    ]
    // const machineColumns = [
    //   {
    //     headerClasses: "custom-table-th",
    //     style: { whiteSpace: "nowrap" },
    //     dataField: "invoiceNumber",
    //     text: "Invoice #",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     style: { whitSpace: "nowrap" },
    //     dataField: "manufacturer",
    //     text: "Manufacturer",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "model",
    //     text: "Model",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "algorithm",
    //     text: "Algorithm",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "hashrate",
    //     text: "Hashrate (TH)",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "powerConsumption",
    //     text: "power Consumption (W)",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "priceInUsd",
    //     text: "price in USD",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "quantity",
    //     text: "Quantity",
    //     sort: true,
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "status",
    //     text: "Status",
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "trackLink",
    //     text: "Operations",
    //     formatter: function (cell, row) {
    //       return (
    //         <div
    //           className="text-left"
    //           style={{ width: "150px", overflow: "hidden" }}
    //         >
    //           {!_.isUndefined(cell) && (
    //             <div>
    //               <a
    //                 className="btn btn-primary btn-xs"
    //                 rel="noopener noreferrer"
    //                 href={row.trackLink}
    //                 target="_blank"
    //               >
    //                 Track
    //               </a>

    //               <a
    //                 className="btn btn-info btn-xs ml-1"
    //                 rel="noopener noreferrer"
    //                 href="mailto:sales@permianchain.com"
    //                 target="_blank"
    //               >
    //                 <i className="fa fa-mail"></i> Email
    //               </a>
    //             </div>
    //           )}
    //           {(_.isUndefined(cell) || cell === "") && <div>N/A</div>}
    //         </div>
    //       );
    //     },
    //   },
    // ];

    const MachineRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
      isLoading,
    }) => (
      <>
        <Table column={columns} data={data} showPagination={true} onSortedList={() => console.log('')} />
        {/* <BootstrapTable
        remote
        keyField="_id"
        bordered={false}
        loading={isLoading}
        data={data}
        columns={machineColumns}
        pagination={
          machinePage > 1
            ? paginationFactory({ page, sizePerPage, totalSize })
            : undefined
        }
        onTableChange={onTableChange}
        noDataIndication="No results!"
        overlay={overlayFactory({
          spinner: true,
          background: "rgba(192,192,192,0.3)",
        })}
        classes="table table-striped table-lightfont dataTable"
      /> */}
      </>
     
    );

    MachineRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
      isLoading: PropTypes.bool.isRequired,
    };

    return (
      <div className="orders-containers">
        <div>
          <div>
            <DashHeader heading={'My orders'} description={'View status of online and offline mining orders'} />

            <Tabs tabs={['Offline', 'Online']} onTabChange={this.handleTabChange}  />

           { 
            this.state.activeTab === 0 &&
            <div>
              <div className="table-responsive">
                {!!machineData && (
                  <MachineRemoteAll
                    data={machineData}
                    page={machinePage}
                    sizePerPage={macineSizePerPage}
                    totalSize={machineTotalSize}
                    onTableChange={this.handleTableChange}
                    isLoading={machineLoading}
                  />
                )}
              </div>
            </div>
            }

            {
              this.state.activeTab === 1 &&
              <div>
              <div className="table-responsive">
                {!!eshopMachineData && (
                  <MachineRemoteAll
                    data={eshopMachineData}
                    page={eshopMachinePage}
                    sizePerPage={eshopMacineSizePerPage}
                    totalSize={eshopMachineTotalSize}
                    onTableChange={this.handleTableChange}
                    isLoading={eshopMachineLoading}
                  />
                )}
              </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Orders;
