import React from "react";
// import { toast } from "react-toastify";
import { connect } from "react-redux";
import Toast from '../CustomToast/CustomToast';

class Logout extends React.Component {
  async componentDidMount() {
    const { authToken } = this.props;
    if (authToken) {
      Toast({
        type:'error',
        title: 'Error',
        message:"Logged out successfully.",
      });
      // toast.success("Logged out successfully.");
      this.props.logout();
    }
  }

  render() {
    return <div className="register-container" />;
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.user.token,
  };
};

export default connect(mapStateToProps)(Logout);
