import React, { Component } from "react";
import Modal from "../Modal/Modal";
import "./ChangePasswordModal.scss";
import infoIcon from "../../assets/img/red-info.svg";

class EditProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      editProfileModalOpen,
      onCloseModal,
      onInputValueChange,
      updateProfile,
      editProfileButtonLoading,
      fullName,
      errorMessage,
      phoneNumber,
      email,
      accountId,
    } = this.props;
    let buttonDisabled = editProfileButtonLoading === true ? true : false;
    return (
      <Modal
        isOpen={editProfileModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        modalWidth={784}
        center
        modalTitle="Edit Profile"
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="password-change-modal">
          <div role="document">
            <div className="">
              <div className="">
                <div className="">
                  <div className="">
                    <form>
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">Account ID</label>
                        <input
                          className="input-field"
                          placeholder="Enter Account"
                          type="text"
                          name="account"
                          id="fullName"
                          value={accountId ? accountId : "N/A"}
                          disabled
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">Full Name</label>
                        <input
                          className="input-field"
                          placeholder="Enter name"
                          type="text"
                          name="fullName"
                          id="fullName"
                          value={fullName}
                          onChange={onInputValueChange}
                          disabled
                        />
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">
                          Email<span className="required-field">*</span>
                        </label>
                        <input
                          className="input-field"
                          disabled={true}
                          placeholder="Enter email"
                          type="text"
                          name="email"
                          id="email"
                          value={email}
                          onChange={onInputValueChange}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">
                          Contact Number
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="input-field"
                          placeholder="Enter contact number"
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={phoneNumber}
                          onChange={onInputValueChange}
                        />
                        {errorMessage && (
                          <div className="error-msg-div">
                            <img src={infoIcon} alt="infoIcon" />
                            <p>{errorMessage}</p>
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="btn-secondary"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn-primary"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={updateProfile}
                        >
                          {" "}
                          Update{" "}
                          {editProfileButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EditProfileModal;
