import React, { Component } from "react";
// import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";

import { FilePond, registerPlugin } from "react-filepond";
import Api from "../../services/api";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { API_ROOT } from "../../services/api-config";
import { connect } from "react-redux";
import Modal from "../Modal/Modal";
import "./get-started.scss";
import Toast from "../CustomToast/CustomToast";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let api;

class WireTransferModal extends Component {
  constructor(props, state) {
    super(props);
    api = new Api();
    this.state = {
      userNote: "",
      wireTransferDetails: "",
    };
    this.changeUserNote = this.changeUserNote.bind(this);
    this.saveNrtBuyPaymentRequest = this.saveNrtBuyPaymentRequest.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.uploadImageArray = {};
  }
  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "wireTransfer",
    });

    if (response.code === 200) {
      let details = response.data[0].wireTransferPaymentCredentials;
      const detailsArr = details.split(",");

      this.setState({
        wireTransferDetails: detailsArr,
      });
    }
  }

  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }
  async saveNrtBuyPaymentRequest() {
    this.props.onCloseModal();
    const data = new FormData();
    data.append("image", this.uploadImageArray["file"]);
    data.append("budgetAmount", this.props.transactionAmount);
    data.append("note", this.state.userNote);
    data.append("orderId", this.props.orderId);
    data.append("quantity", 1);

    const authenticationToken = this.props.authToken;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: authenticationToken,
      },
    };
    this.props.onLoading();

    axios
      .post(API_ROOT + "/eshop/createPaymentRequest", data, config)
      .then((response) => {
        if (response.data.code === 200) {
          Toast({
            type: "success",
            title: "Success",
            message: response.data.message,
          });
          // toast.success(response.data.message);
          this.props.onUpload();
        } else {
          this.props.onCloseModal();
          Toast({
            type: "error",
            title: "Error",
            message: response.data.message,
          });
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        Toast({
          type: "error",
          title: "Error",
          message: "Please upload valid payment proof file.",
        });
        // toast.error("Please upload valid payment proof file.");
        console.log(error);
      });
  }

  async changeUserNote(event) {
    this.setState({
      userNote: event.target.value,
    });
  }
  render() {
    const {
      paymentRequestModalOpen,
      onCloseModal,
      paymentRequestButtonLoading,
      transactionAmount,
    } = this.props;
    const { wireTransferDetails } = this.state;
    let wireFrameData = wireTransferDetails;
    let buttonDisabled = paymentRequestButtonLoading === true ? true : false;

    console.log("wireFrameData", wireFrameData);
    return (
      <Modal
        isOpen={paymentRequestModalOpen}
        onClose={onCloseModal}
        modalWidth={750}
        modalTitle="Make FIAT Payment Request"
      >
        <div className="wire-tranfer-modal-container">
          <div className="desc">
            Submit the below form to request to deposit your payment request in
            fiat currency.
          </div>
          <form className="mt-3">
            <div className="form-group text-black">
              <label htmlFor="transactionAmount">Transaction Amount</label>
              <div className="d-flex">
                <input
                  className="input-field"
                  id="transactionAmount"
                  type="text"
                  value={transactionAmount}
                  name="transactionAmount"
                  disabled={true}
                />
              </div>
              <div className="text-center mt-4 receive-text "></div>
            </div>

            <div class="form-group text-black">
              <label htmlFor="userNote">
                Wire transfer details to make payment
              </label>
              <div className="wire-transfter-nfo">
                {wireFrameData
                  ? wireFrameData.map((data, index) => {
                      const dataArr = data.split(":");
                      return (
                        <div
                          key={index}
                          style={{ padding: "13px 14px 13px 33px" }}
                          class="row justify-content-start text-black"
                        >
                          <div>
                            <span className="text-white info">
                              {dataArr[0]} :{" "}
                            </span>
                            <span className="info-value">{dataArr[1]}</span>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div className="form-group text-black">
              <label htmlFor="userNote">Note</label>
              <textarea
                className="input-field"
                placeholder="Enter note"
                name="userNote"
                id="userNote"
                value={this.userNote}
                onChange={this.changeUserNote}
              ></textarea>
            </div>

            <label className="text-black">
              {" "}
              Proof of Payment (pdf, jpg, jpeg)
              <span className="required-field">*</span>
            </label>
            <div className="resource-continaer payment-proof-container dropzoneIdContainer">
              <div className="row">
                <div className="col-md-12">
                  <FilePond
                    allowMultiple={false}
                    allowFileTypeValidation={true}
                    className="uploadProof"
                    acceptedFileTypes={[
                      "image/*",
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ]}
                    instantUpload={false}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    onupdatefiles={(fileItems) => {
                      if (
                        !_.isEmpty(fileItems) &&
                        fileItems[0].fileSize < 5000000
                      ) {
                        if (
                          (fileItems[0].fileExtension === "pdf" ||
                            fileItems[0].fileExtension === "docx") &&
                          fileItems[0].fileSize < 5000000
                        ) {
                          this.fileUpdated(fileItems);
                        } else if (
                          (fileItems[0].fileExtension === "jpeg" ||
                            fileItems[0].fileExtension === "jpg" ||
                            fileItems[0].fileExtension === "png") &&
                          fileItems[0].fileSize < 500000
                        ) {
                          this.fileUpdated(fileItems);
                        } else if (
                          (fileItems[0].fileExtension === "jpeg" ||
                            fileItems[0].fileExtension === "jpg" ||
                            fileItems[0].fileExtension === "png") &&
                          fileItems[0].fileSize > 500000
                        ) {
                          fileItems[0].abortLoad();
                          Toast({
                            type: "error",
                            title: "Error",
                            message:
                              "Please make sure image size is under 500kb",
                          });
                          // toast.error(
                          //   "Please make sure image size is under 500kb"
                          // );
                        }
                      } else if (
                        !_.isEmpty(fileItems) &&
                        fileItems[0].fileSize > 5000000
                      ) {
                        fileItems[0].abortLoad();
                        Toast({
                          type: "error",
                          title: "Error",
                          message: "Please make sure file size is under 5mb",
                        });
                        // toast.error(
                        //   "Please make sure file size is under 5mb"
                        // );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer-btn">
              <button
                className="btn-secondary"
                type="button"
                onClick={onCloseModal}
              >
                Cancel
              </button>
              <button
                className="btn-primary"
                type="button"
                disabled={buttonDisabled}
                onClick={this.saveNrtBuyPaymentRequest}
              >
                {" "}
                Submit{" "}
                {paymentRequestButtonLoading && (
                  <i className="fa-spin fa fa-spinner text-white m-1" />
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(WireTransferModal);
