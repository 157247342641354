import React from "react";
// import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import Toast from '../CustomToast/CustomToast';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let api;

class KycUpload extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      documentTypeId: "",
      kycFile: "",
      selfieWithKycFile: "",
      buttonLoading: false,
      apiError: "",
      recaptchaResponse: "",
      kycDocumentNumber: "",
      displayNote: false,
      kycFiles: "",
      objectIdRef: "",
      filesRequiredAr: [],
      allowKycSubmit: false,
    };
    this.saveKycDocuments = this.saveKycDocuments.bind(this);
    this.fileRemoved = this.fileRemoved.bind(this);
    /*this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleMultipleUploadFile = this.handleMultipleUploadFile.bind(this);*/
    this.fileUpdated = this.fileUpdated.bind(this);
    this.uploadInput = [];
    this.uploadImageArray = {};
    this.uploadMultipleImageArray = {};
    this.proxyUrl = "https://cors-anywhere.herokuapp.com/";
    this.userRequiredUploadFileCounter = 0;
    this.userUploadedFileCounter = 0;
    this.bigFiles = ["pdf", "docx"];
    this.smallFiles = [
      "jpeg",
      "jpg",
      "png",
      "gif",
      "tiff",
      "psd",
      "eps",
      "ai",
      "indd",
      "raw",
    ];
  }

  async componentWillMount() {
    document.title =
      messageConstants.KYC_UPLOAD_FILES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    try {
      let _this = this;
      const userResponse = await api.create("user/getUserDetails", {
        getKycPhoto: true,
        getKycDocumentTypes: true,
      });

      if (userResponse.code === 200) {
        if (
          userResponse.data.kycStatus === "u" ||
          userResponse.data.kycStatus === "a"
        ) {
          let redirectUrl =
            userResponse.data.kycStatus === "u"
              ? "kyc_status/pending"
              : "kyc_status/approved";
          this.props.history.push(redirectUrl);
        } else {
          let stateObj = {};
          let userKycImages = userResponse.data.userKycDetailPhotos;
          if (!_.isUndefined(userKycImages) && !_.isEmpty(userKycImages)) {
            _.forEach(userKycImages, function (value, key) {
              let keyName = "display" + key + "Image";
              let textKey = "kyc" + key + "TextDisplay";
              stateObj[keyName] = value;
              stateObj[textKey] = false;
            });
          }

          if (
            !_.isUndefined(userResponse.data.displayNote) &&
            userResponse.data.displayNote === true
          ) {
            stateObj.displayNote = true;
          }

          if (
            !_.isUndefined(userResponse.data.kycDocumentTypeList) &&
            !_.isUndefined(userResponse.data.kycDocumentNumber)
          ) {
            stateObj.kycDocumentTypeList =
              userResponse.data.kycDocumentTypeList;
            stateObj.kycDocumentNumber = userResponse.data.kycDocumentNumber;
            stateObj.kycFiles = userResponse.data.kycFiles;
            stateObj.objectIdRef = userResponse.data.objectIdRef;
          }

          if (!_.isEmpty(userResponse.data.kycDocumentTypeList)) {
            _.forEach(
              userResponse.data.kycDocumentTypeList,
              function (docValue, docKey) {
                if (!_.isEmpty(docValue.children)) {
                  _.forEach(docValue.children, function (childValue, childKey) {
                    if (childValue.required === true) {
                      _this.userRequiredUploadFileCounter += 1;
                    }
                  });
                }
              }
            );
          }

          if (!_.isEmpty(userResponse.data.objectIdRef)) {
            let keyFiles = userResponse.data.kycFiles;
            _.forEach(userResponse.data.objectIdRef, function (value, key) {
              stateObj[key + "StateFiles"] = [];
              if (!_.isEmpty(value)) {
                _.forEach(value, function (value1, key1) {
                  let temp = {};
                  temp.source = _this.proxyUrl + keyFiles[value1];
                  temp.options = { type: "local", load: false };
                  stateObj[key + "StateFiles"].push(temp);
                });
              }
            });
            stateObj["allowKycSubmit"] = true;
            this.userUploadedFileCounter = _.size(
              userResponse.data.objectIdRef
            );
          }
          if (!_.isUndefined(stateObj)) {
            this.setState(stateObj);
          }
        }
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:userResponse.message,
        });
        // toast.error(userResponse.message);
      }
      pageProgress("remove");
    } catch (error) {
      pageProgress("remove");
    }
  }

  async saveKycDocuments(event) {
    const { allowKycSubmit } = this.state;
    let _this = this;
    let uploadedFileSize =
      _.size(this.uploadImageArray) + _.size(this.uploadMultipleImageArray);
    if (
      uploadedFileSize > 0 &&
      (uploadedFileSize >= _this.userRequiredUploadFileCounter ||
        _this.userRequiredUploadFileCounter <= _this.userUploadedFileCounter)
    ) {
      this.setState({
        buttonLoading: true,
      });
      const data = new FormData();
      if (!_.isUndefined(this.uploadImageArray)) {
        _.forEach(this.uploadImageArray, function (imageValue) {
          data.append(imageValue.name, imageValue.file);
          data.append(
            imageValue.name + "_kycDocumentId",
            imageValue.documentTypeId
          );
        });
      }

      if (!_.isUndefined(this.uploadMultipleImageArray)) {
        _.forEach(this.uploadMultipleImageArray, function (imageValue) {
          if (!_.isEmpty(imageValue.files)) {
            _.forEach(imageValue.files, function (image) {
              let size = image.file.size;
              let type = image.file.type;
              let lastModified = image.file.lastModified;
              if (
                size > 0 &&
                !_.isUndefined(type) &&
                !_.isUndefined(lastModified) &&
                lastModified !== "" &&
                lastModified !== null
              ) {
                data.append(imageValue.name + "[]", image.file);
              } else {
                data.append(
                  imageValue.name + "_existed_files[]",
                  image.file.name
                );
              }
            });
            data.append(
              imageValue.name + "_kycDocumentId",
              imageValue.documentTypeId
            );
          }
        });
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: this.props.authToken,
        },
      };
      axios
        .post(API_ROOT + "/user/saveKycDocuments", data, config)
        .then(function (response) {
          if (!_.isUndefined(response)) {
            _this.setState({
              buttonLoading: false,
            });
            if (response.data.code === 200) {
              Toast({
                type:'success',
                title: 'Success',
                message:response.data.message,
              });
              // toast.success(response.data.message);
              _this.props.history.push("/kyc_submit");
            } else {
              Toast({
                type:'error',
                title: 'Error',
                message:response.data.message,
              });
              // toast.error(response.data.message);
            }
          }
        })
        .catch(function (error) {
          _this.setState({
            buttonLoading: false,
          });
          Toast({
            type:'error',
            title: 'Error',
            message:messageConstants.ERROR_MSG_VALID_FILES,
          });
          // toast.error(messageConstants.ERROR_MSG_VALID_FILES);
        });
    } else {
      if (
        allowKycSubmit &&
        _this.userUploadedFileCounter >= _this.userRequiredUploadFileCounter
      ) {
        this.props.history.push("/kyc_submit");
      } else {
        let message = messageConstants.ERROR_MSG_REQUIRED_FILES;
        _this.setState({
          buttonLoading: false,
        });
        Toast({
          type:'error',
          title: 'Error',
          message:message,
        });
        // toast.error(message);
      }
    }
  }

  async fileUpdated(
    isMultiple = false,
    documentTypeName,
    documentTypeId,
    fileItems,
    documentRequired
  ) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      let { allowKycSubmit } = this.state;
      if (isMultiple === true) {
        var temp = {};
        temp.documentTypeId = documentTypeId;
        temp.files = fileItems;
        temp.name = documentTypeName;
        this.uploadMultipleImageArray[documentTypeName] = temp;
        if (allowKycSubmit) {
          this.userUploadedFileCounter += 1;
        }
      } else {
        let size = fileItems[0].file.size;
        let type = fileItems[0].file.type;
        let lastModified = fileItems[0].file.lastModified;
        if (
          size > 0 &&
          !_.isUndefined(type) &&
          !_.isUndefined(lastModified) &&
          lastModified !== "" &&
          lastModified !== null
        ) {
          var uploadTemp = {};
          uploadTemp.documentTypeId = documentTypeId;
          uploadTemp.file = fileItems[0].file;
          uploadTemp.name = documentTypeName;
          this.uploadImageArray[documentTypeName] = uploadTemp;
          if (allowKycSubmit) {
            this.userUploadedFileCounter += 1;
          }
        }
      }
    }
  }

  async fileRemoved() {
    let { allowKycSubmit } = this.state;
    if (allowKycSubmit) {
      this.userUploadedFileCounter -= 1;
    }
  }

  render() {
    const { kycDocumentTypeList, displayNote } = this.state;
    let { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    let displayDocumentTypes = "";
    let _this = this;
    const getDisplayDocumentTypes = () => {
      if (
        !_.isUndefined(kycDocumentTypeList) &&
        !_.isEmpty(kycDocumentTypeList)
      ) {
        displayDocumentTypes = kycDocumentTypeList.map(
          (parentDocumentType, mainIndex) => (
            <div className="col-md-12">
              <div className="mt-4">
                {/* <h5 className={"color-white " + (parentDocumentType.children.length > 1 ? '' : 'd-none hide')}>{parentDocumentType.label}</h5> */}
                <div
                  className={parentDocumentType.children.length > 1 ? "" : ""}
                >
                  {parentDocumentType.children.map((documentType, index) => (
                    <div className="mt-4 pr-5 pl-5">
                      {/* <div className="element-wrapper custom-element-box"> */}
                      <div className="element-box p-4">
                        <h5 className="form-header">
                          {documentType.label}{" "}
                          <span
                            className={
                              "required-field " +
                              (documentType.required === true
                                ? ""
                                : "d-none hide")
                            }
                          >
                            *
                          </span>
                        </h5>
                        <div className="dropzoneIdContainer">
                          <div className="row">
                            <div className="col-md-7">
                              <FilePond
                                ref={(ref) =>
                                  (this.uploadInput[documentType._id] = ref)
                                }
                                files={
                                  this.state[documentType._id + "StateFiles"]
                                }
                                allowMultiple={documentType.multiple || false}
                                allowFileTypeValidation={true}
                                acceptedFileTypes={[
                                  "image/*",
                                  "application/pdf",
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]}
                                instantUpload={false}
                                allowFileSizeValidation={true}
                                allowImagePreview={false}
                                maxFileSize="5MB"
                                credits={false}
                                server={{
                                  load: (
                                    source,
                                    load,
                                    error,
                                    progress,
                                    abort,
                                    headers
                                  ) => {
                                    var myRequest = new Request(source);
                                    fetch(myRequest).then(function (response) {
                                      response.blob().then(function (myBlob) {
                                        load(myBlob);
                                      });
                                    });
                                  },
                                }}
                                onremovefile={(file) => {
                                  _this.fileRemoved();
                                }}
                                onupdatefiles={(fileItems) => {
                                  if (
                                    fileItems[0] &&
                                    fileItems[0].file &&
                                    fileItems[0].file instanceof File
                                  ) {
                                    if (
                                      !_.isEmpty(fileItems) &&
                                      fileItems[0].fileSize < 5000000
                                    ) {
                                      if (
                                        this.bigFiles.includes(
                                          fileItems[0].fileExtension
                                        )
                                      ) {
                                        _this.fileUpdated(
                                          documentType.multiple,
                                          documentType.name,
                                          documentType._id,
                                          fileItems,
                                          documentType.required
                                        );
                                      } else if (
                                        this.smallFiles.includes(
                                          fileItems[0].fileExtension
                                        ) &&
                                        fileItems[0].fileSize < 500000
                                      ) {
                                        _this.fileUpdated(
                                          documentType.multiple,
                                          documentType.name,
                                          documentType._id,
                                          fileItems,
                                          documentType.required
                                        );
                                      } else if (
                                        this.smallFiles.includes(
                                          fileItems[0].fileExtension
                                        ) &&
                                        fileItems[0].fileSize > 500000
                                      ) {
                                        fileItems[0].abortLoad();
                                        Toast({
                                          type:'error',
                                          title: 'Error',
                                          message:"Please make sure image size is under 500kb",
                                        });
                                        // toast.error(
                                        //   "Please make sure image size is under 500kb"
                                        // );
                                      }
                                    } else if (
                                      !_.isEmpty(fileItems) &&
                                      fileItems[0].fileSize > 5000000
                                    ) {
                                      fileItems[0].abortLoad();
                                      Toast({
                                        type:'error',
                                        title: 'Error',
                                        message:"Please make sure document size is under 5mb",
                                      });
                                      // toast.error(
                                      //   "Please make sure document size is under 5mb"
                                      // );
                                    }
                                  }
                                }}
                              />

                              {/* <FilePond
                                  ref={ref => (this.uploadInput[documentType._id] = ref)}
                                  files={this.state[documentType._id + 'StateFiles']}
                                  allowMultiple={(documentType.multiple === true ? true : false)}
                                  allowFileTypeValidation={true}
                                  acceptedFileTypes={['image/*', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                                  instantUpload={false}
                                  imagePreviewHeight={150}
                                  server={{
                                    load: (source, load, error, progress, abort, headers) => {
                                      var myRequest = new Request(source);
                                      fetch(myRequest).then(function (response) {
                                        response.blob().then(function (myBlob) {
                                          load(myBlob)
                                        });
                                      });
                                    }
                                  }}
                                  onremovefile={file => {
                                    _this.fileRemoved();
                                  }}
                                  onupdatefiles={fileItems => {
                                    if (!_.isEmpty(fileItems)) {
                                      _this.fileUpdated(documentType.multiple, documentType.name, documentType._id, fileItems, documentType.required);
                                    }
                                  }}
                                /> */}
                            </div>
                            <div className="col-md-5">
                              <ul>
                                <li className="mt-1">
                                  {documentType.label} should be in good
                                  condition and clearly visible.
                                </li>
                                <li className="mt-1">
                                  Make sure that there is no light glare on the{" "}
                                  {documentType.label} in case of Image file.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        );
      }
      return displayDocumentTypes;
    };
    return (
      <div className="kyc-container kyc-document-container">
        <h3 className="text-center kyc-header standardColorIcon">Submit KYC</h3>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <ul className="progressbar d-flex p-0 justify-content-center">
                  <li className="standardColorIcon">Fill Details</li>
                  <li className="active standardColorIcon">Upload</li>
                  <li className="standardColorIcon">Submit</li>
                </ul>
              </div>
              <div className="col-md-2"></div>
            </div>

            {displayNote && (
              <div className="mt-3 d-none hide">
                <h6
                // className="color-white"
                >
                  Note : All documents requested need to be signed/stamped by a
                  registered lawyer, auditor. Documents must either be in the
                  English language or officially translated.
                </h6>
              </div>
            )}

            <div className="row mt-3">{getDisplayDocumentTypes()}</div>

            <div className="row mt-5">
              <div className="col-md-12 text-right pr-5 pl-5">
                <Link to="/dashboard">
                  <span className="btn cancel-button mr-2 p-2 pl-1 pr-1">
                    Do it later
                  </span>
                </Link>{" "}
                <Link to="/kyc" className="color-white">
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>
                <button
                  className="btn btn-primary permian-button"
                  type="submit"
                  onClick={this.saveKycDocuments}
                  disabled={buttonDisabled}
                >
                  {" "}
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.user.token,
  };
};

export default connect(mapStateToProps)(KycUpload);
