import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import { confirmAlert } from "react-confirm-alert";
// import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import NewAddressModal from "./NewAddressModal";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import Toast from '../CustomToast/CustomToast';

let api;

class MyAddresses extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
      openModal: false,
      countryIdValue: null,
      countryName: "Select country",
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.openAddAddressPopup = this.openAddAddressPopup.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.addNewAddressRequest = this.addNewAddressRequest.bind(this);
    this.onchange = this.onchange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.deleteShippingAddress = this.deleteShippingAddress.bind(this);
    this.deleteOperation = this.deleteOperation.bind(this);
  }

  async componentWillMount() {
    document.title =
      messageConstants.MY_ADDRESSES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    this.getRecords();
  }

  async getRecords() {
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    filters = JSON.stringify(filters);
    try {
      const response = await api.get("user/shipping/addresses", {
        sizePerPage: sizePerPage,
        page: page,
        filters: filters,
      });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.shippingAddresses,
          totalSize: response.data.totalShippingAddresses,
        });
      }
      pageProgress("remove");
    } catch (e) {
      pageProgress("force_remove");
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  openAddAddressPopup() {
    this.setState({
      openModal: true,
    });
  }

  onCloseModal() {
    this.setState({
      openModal: false,
    });
  }

  async addNewAddressRequest() {
    const {
      address1,
      address2,
      address3,
      landmark,
      pinCode,
      city,
      state,
      countryId,
    } = this.state;
    if (
      address1 &&
      address1 !== "" &&
      landmark &&
      landmark !== "" &&
      pinCode &&
      pinCode !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const response = await api.create("user/shipping/address/update", {
        address1: address1,
        address2: address2,
        address3: address3,
        landmark: landmark,
        pinCode: pinCode,
        city: city,
        state: state,
        countryId: countryId,
      });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          address1: "",
          address2: "",
          address3: "",
          landmark: "",
          state: "",
          city: "",
          pinCode: "",
          countryIdValue: "",
        });
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
        this.onCloseModal();
        this.getRecords();
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      Toast({
        type:'error',
        title: 'Error',
        message:msg,
      });
      // toast.error(msg);
    }
  }

  deleteOperation(addressId) {
    let _self = this;
    let buttons = [
      {
        label: "Yes",
        onClick: () => _self.deleteShippingAddress(addressId),
      },
      {
        label: "Cancel",
      },
    ];
    confirmAlert({
      title: "Are you sure want to delete shipping address?",
      buttons: buttons,
    });
  }

  async deleteShippingAddress(addressId) {
    if (addressId) {
      const response = await api.create("user/shipping/address/delete", {
        addressId: addressId,
      });
      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
        this.getRecords();
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCountryChange(selectedOption) {
    if (selectedOption) {
      this.setState({
        countryIdValue: selectedOption.value,
        countryName: selectedOption.label,
        countryId: selectedOption.value,
      });
    }
  }

  render() {
    const { data, sizePerPage, page, renderFlag, totalSize } = this.state;
    var _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "address1",
        text: "Address",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <div>{row.address1}</div>
              <div>{row.address2}</div>
              <div>{row.address3}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "city",
        text: "City",
      },
      {
        headerClasses: "text-bold",
        dataField: "state",
        text: "State",
      },
      {
        headerClasses: "text-bold",
        dataField: "countryId",
        text: "Country",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return <div className="text-center">{cell.name}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "landmark",
        text: "Landmark",
      },
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "pinCode",
        text: "Pin Code",
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var date = moment(cell).format("DD/MM/YYYY HH:mm");
          return <div>{date}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <div className="d-inline-block">
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => _this.deleteOperation(row._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="element-box">
                <h5 className="form-header">
                  <span className="pull-left">My Addresses</span>
                  <button
                    className="btn btn-primary pull-right"
                    onClick={() => this.openAddAddressPopup()}
                  >
                    Add Address
                  </button>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewAddressModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          addNewAddressRequest={this.addNewAddressRequest}
          onInputValueChange={this.onchange}
          handleCountryChange={this.handleCountryChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.country.countries,
  };
};

export default connect(mapStateToProps)(MyAddresses);
