export const DEC_TO_USD = 0.03556;


export const SIDEB_BAR = [
  {
    name:'Bitcoin Mining',
    id:1,
    routes:[
      {
        name:'Get Started',
        route:'/journey',
        icon:`<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 11L6.5 6L1.5 1" stroke="white" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
      {
        name:'Dashboard',
        route:'/dashboard',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1338 3.42773C6.72366 3.42773 2.33789 7.81351 2.33789 13.2237C2.33789 15.7853 3.17713 18.3353 4.78687 20.0808H19.4807C21.0905 18.3353 21.9297 15.7853 21.9297 13.2237C21.9297 7.81351 17.5439 3.42773 12.1338 3.42773Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.133 16.6566C13.215 16.6566 14.0922 15.7795 14.0922 14.6975C14.0922 13.6154 13.215 12.7383 12.133 12.7383C11.051 12.7383 10.1738 13.6154 10.1738 14.6975C10.1738 15.7795 11.051 16.6566 12.133 16.6566Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1338 9.79688V12.7357" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1338 5.875V6.85459" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.78516 13.7109H5.76473" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.74609 8.81641L7.43876 9.50908" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.502 13.7109H19.4815" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.0322 9.50717L17.7249 8.81445" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
      {
        name:'Earnings',
        route:'/earnings',
        icon:`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 1H2.5C1.67158 1 1 1.67158 1 2.5V17.5C1 18.3285 1.67158 19 2.5 19H17.5C18.3285 19 19 18.3285 19 17.5V2.5C19 1.67158 18.3285 1 17.5 1Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M4.71973 12.9173L7.54818 10.0889L9.74218 12.2777L14.9998 7" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 7H15V11" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
      {
        name:'Wallets',
        route:'/wallets',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 5C2 4.4477 2.44771 4 3 4H21C21.5523 4 22 4.4477 22 5V19C22 19.5523 21.5523 20 21 20H3C2.44771 20 2 19.5523 2 19V5Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M17.625 14.5H22V9.5H17.625C16.1753 9.5 15 10.6193 15 12C15 13.3807 16.1753 14.5 17.625 14.5Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M22 6.25V18.25" stroke="white" stroke-width="1.4" stroke-linecap="round"/>
        </svg>
        `
      },
      {
        name:'Billing & Subscriptions',
        route:'/subscriptions',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V21C4.5 21.5523 4.94772 22 5.5 22H18.5C19.0523 22 19.5 21.5523 19.5 21V5C19.5 4.44772 19.0523 4 18.5 4Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M9 2V5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 2V5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 9.5H16" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 13.5H14" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 17.5H12" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
      {
        name:'ASICS',
        routes:'/asics',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 2H2V10H22V2Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M22 14H2V22H22V14Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M6.5 5H5.5C5.22385 5 5 5.22385 5 5.5V6.5C5 6.77615 5.22385 7 5.5 7H6.5C6.77615 7 7 6.77615 7 6.5V5.5C7 5.22385 6.77615 5 6.5 5Z" fill="white"/>
        <path d="M6.5 17H5.5C5.22385 17 5 17.2239 5 17.5V18.5C5 18.7761 5.22385 19 5.5 19H6.5C6.77615 19 7 18.7761 7 18.5V17.5C7 17.2239 6.77615 17 6.5 17Z" fill="white"/>
        <path d="M10.5 5H9.5C9.22385 5 9 5.22385 9 5.5V6.5C9 6.77615 9.22385 7 9.5 7H10.5C10.7761 7 11 6.77615 11 6.5V5.5C11 5.22385 10.7761 5 10.5 5Z" fill="white"/>
        <path d="M10.5 17H9.5C9.22385 17 9 17.2239 9 17.5V18.5C9 18.7761 9.22385 19 9.5 19H10.5C10.7761 19 11 18.7761 11 18.5V17.5C11 17.2239 10.7761 17 10.5 17Z" fill="white"/>
        </svg>
        `,
        subRoutes:[
          {
            subName:'Workers',
            route:'/workers',
          },
          {
            subName:'Inventory',
            route:'/worker_inventory',
          },
          {
            subName:'My Orders',
            route:'/orders',
          }
        ]
      },
      {
        name:'Calculator',
        route:'/mining_calculator',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0002 2H4.00033C3.44805 2 3.00033 2.4477 3.00032 2.99998L3 21C2.99999 21.5523 3.44771 22 4 22H20.0002C20.5525 22 21.0002 21.5523 21.0002 21V3C21.0002 2.44771 20.5525 2 20.0002 2Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M17.5 5H6.5V9.5H17.5V5Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        <path d="M6 14L9.5 17.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 14L6 17.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 17.5H18" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 14.5H18" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
    ]
  },
  {
    name:'Compute Power',
    id:2,
    routes:[
      {
        name:'Dashboard',
        route:'/dashboard',
        icon:`<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 16H4.5L1 7.875L2 3.5H16L17 7.875L13.5 16Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 1V3.5" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 1V3.5" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 16V21H17V17.3158" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 9.5H12" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
      {
        name:'Compute Market',
        route:'/compute-market',
        icon:`<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0196 9V19H2.01953V9" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M0.921377 4.8883C0.156602 6.86885 1.63176 9 3.75482 9C5.41167 9 6.76402 7.65685 6.76402 6C6.76402 7.65685 8.10717 9 9.76402 9H10.2732C11.9301 9 13.2732 7.65685 13.2732 6C13.2732 7.65685 14.6261 9 16.283 9C18.4072 9 19.8837 6.8676 19.1183 4.88615L17.6171 1H2.42284L0.921377 4.8883Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
        </svg>
        `
      }
    ]
  },
  {
    name:'Energy',
    id:3,
    routes:[
      {
        name:'Dashboard',
        route:'/dashboard',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1338 3.42773C6.72366 3.42773 2.33789 7.81351 2.33789 13.2237C2.33789 15.7853 3.17713 18.3353 4.78687 20.0808H19.4807C21.0905 18.3353 21.9297 15.7853 21.9297 13.2237C21.9297 7.81351 17.5439 3.42773 12.1338 3.42773Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.133 16.6566C13.215 16.6566 14.0922 15.7795 14.0922 14.6975C14.0922 13.6154 13.215 12.7383 12.133 12.7383C11.051 12.7383 10.1738 13.6154 10.1738 14.6975C10.1738 15.7795 11.051 16.6566 12.133 16.6566Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1338 9.79688V12.7357" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1338 5.875V6.85459" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.78516 13.7109H5.76473" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.74609 8.81641L7.43876 9.50908" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.502 13.7109H19.4815" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.0322 9.50717L17.7249 8.81445" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
      },
      {
        name:'Offtake Market',
        route:'/powersitesMarket',
        icon:`<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.0196 9V19H2.01953V9" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M0.921377 4.8883C0.156602 6.86885 1.63176 9 3.75482 9C5.41167 9 6.76402 7.65685 6.76402 6C6.76402 7.65685 8.10717 9 9.76402 9H10.2732C11.9301 9 13.2732 7.65685 13.2732 6C13.2732 7.65685 14.6261 9 16.283 9C18.4072 9 19.8837 6.8676 19.1183 4.88615L17.6171 1H2.42284L0.921377 4.8883Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
          </svg>
        `
      },
      {
        name:'My Offtakes',
        route:'/powersites',
        icon:`<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 4C6 2.34315 7.34315 1 9 1H13.5C15.1568 1 16.5 2.34315 16.5 4V5H6V4Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 9.5L10.7575 11.9394C10.9167 11.9792 11.0833 11.9792 11.2425 11.9394L21 9.5V16C21 16.5523 20.5523 17 20 17H2C1.44771 17 1 16.5523 1 16V9.5Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 10.5V6C21 5.4477 20.5523 5 20 5H2C1.44771 5 1 5.4477 1 6V10.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.55 8.5C11.55 8.80375 11.3038 9.05 11 9.05C10.6962 9.05 10.45 8.80375 10.45 8.5C10.45 8.19625 10.6962 7.95 11 7.95C11.3038 7.95 11.55 8.19625 11.55 8.5Z" fill="black" stroke="white" stroke-width="1.4"/>
        </svg>
        `
      }
    ]
  },
  {
    name:'Resources',
    route:'/resources',
    id:4,
    icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 4.00195C2.5 3.44967 2.94771 3.00195 3.5 3.00195H9.5L12 6.00195H20.5C21.0523 6.00195 21.5 6.44965 21.5 7.00195V20.002C21.5 20.5543 21.0523 21.002 20.5 21.002H3.5C2.94771 21.002 2.5 20.5543 2.5 20.002V4.00195Z" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
    <path d="M21.2863 11.1445H2.71484" stroke="white" stroke-width="1.4" stroke-linejoin="round"/>
    </svg>
    `
  },
]