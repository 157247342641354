import React from 'react'
import DashHeader from '../DashHeader/DashHeader';
import Tabs from '../Tabs/Tabs';
import ComputeCard from './ComputeCard';
import './compute-market.scss';
import GraphicCardIcon from '../../assets/img/graphic-card.svg';

const ComputeMarket = () => {
  return (
    <div className='compute-market-container'>
      <DashHeader 
        heading={'Compute Market'} 
        description={'Configure and deploy GPU & CPU'} 
      />

      <div className='market-header'>
        <Tabs tabs={['GPU','CPU']} />
        <select>
          <option>Virtual Machine</option>
        </select>
      </div>

      
      <div>
        {
          ComputeMarketData.map((compute,index)=>(
            <>
            <div key={index} className='computer-card-heading'>
              <img src={GraphicCardIcon} alt='GraphicCardIcon' />
              <h5>{compute.graphicCard}</h5>
            </div>
            <div className='compute-cards-container'> 
              {
                compute.specInfo.map((specInfo,cardIndex) => (
                  <ComputeCard compute={specInfo} key={cardIndex} />
                ))
              }
            </div>
           
            </>
          ))
        }
      </div>
      
    </div>
  )
}

export default ComputeMarket

const ComputeMarketData =[
  {
    graphicCard:'NVIDIA A100',
    specInfo:[
      {
        heading:'A100-40GB-NVLINK-1x',
        hour:'$ 70/hour',
        gpu:'8',
        vcps:'256',
        ram:'256 GB',
        availibity:'High',
        gpuMemory:'80',
        ssd:'2 TB'
      },
      {
        heading:'A100-40GB-NVLINK-1x',
        hour:'$ 70/hour',
        gpu:'8',
        vcps:'256',
        ram:'256 GB',
        availibity:'High',
        gpuMemory:'80',
        ssd:'2 TB'
      },
      {
        heading:'A100-40GB-NVLINK-1x',
        hour:'$ 70/hour',
        gpu:'8',
        vcps:'256',
        ram:'256 GB',
        availibity:'High',
        gpuMemory:'80',
        ssd:'2 TB'
      }
    ]
  },
  {
    graphicCard:'NVIDIA A100',
    specInfo:[
      {
        heading:'A100-40GB-NVLINK-1x',
        hour:'$ 70/hour',
        gpu:'8',
        vcps:'256',
        ram:'256 GB',
        availibity:'High',
        gpuMemory:'80',
        ssd:'2 TB'
      },
      {
        heading:'A100-40GB-NVLINK-1x',
        hour:'$ 70/hour',
        gpu:'8',
        vcps:'256',
        ram:'256 GB',
        availibity:'High',
        gpuMemory:'80',
        ssd:'2 TB'
      },
      {
        heading:'A100-40GB-NVLINK-1x',
        hour:'$ 70/hour',
        gpu:'8',
        vcps:'256',
        ram:'256 GB',
        availibity:'High',
        gpuMemory:'80',
        ssd:'2 TB'
      }
    ]
  }
]