import React from 'react';
import { toast } from 'react-toastify';
import {
  FaInfo,
  FaCheck,
  FaExclamationTriangle,
  FaBug,
  FaExclamationCircle,
} from 'react-icons/fa';
import closeIcon from '../../assets/img/close-icon.svg';
import whiteClose from '../../assets/img/white-close.svg';
import sucessIcon from '../../assets/img/success-icon.svg';
import infoIcon from '../../assets/img/info-icon.svg';
import './custom-toast.scss';
export const displayIcon = (type) => {
  switch (type) {
    case 'success':
      return <img src={sucessIcon} alt='sucessIcon' />;
    case 'info':
      return <img src={infoIcon} alt='closeIcon' />;
    case 'error':
      return <img src={closeIcon} alt='closeIcon' />;
    case 'warning':
      return <img src={closeIcon} alt='closeIcon' />;
    default:
      return <FaBug  />;
  }
};

const Toast = ({ type, title, message }) =>
  toast(
    <div>
      <div className='toast-content'>
        {displayIcon(type)}
        <div>
          <h4>{title}</h4>
          <p>{message}</p>
        </div>
        <img className='white-close' src={whiteClose} alt='whiteClose' />
      </div>
    </div>,
    {
      closeButton: false,
      className:'custom-toast-container'
    }
);

export default Toast;
