import React from 'react'
import './dash-header.scss'
const DashHeader = ({heading,description}) => {
  return (
    <div className='dash-header-container'>
      <h2>{heading}</h2>
      <p>{description}</p>
    </div>
  )
}

export default DashHeader