import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Api from "../../services/api";
import queryString from "query-string";
import Basepages from "./Basepages";
import TermsBasePages from "./TermsBasePages";
import Landingpage from "./Landingpage";
import EmailVerifyBasePages from "./EmailVerifyBasePages";
import Base from "./Base";
import Signin from "../Signin/Signin";
import Signup from "../Signup/Signup";
import EmailVerify from "../EmailVerify/EmailVerify";
import Forgot from "../Forgot/Forgot";
import ResetPassword from "../ResetPassword/ResetPassword";
import Dashboard from "../Dashboard/Dashboard";
import Logout from "../Logout/Logout";
import Kyc from "../Kyc/Kyc";
import KycUpload from "../KycUpload/KycUpload";
import Resources from "../Resources/Resources";
import KycSubmit from "../KycSubmit/KycSubmit";
import KycStatus from "../KycStatus/KycStatus";
import Terms from "../Terms/Terms";
import MyAccount from "../MyAccount/MyAccount";
import MyAddresses from "../MyAddresses/MyAddresses";
import Listings from "../Listings/Listings";
import ListingDetails from "../Listings/ListingDetails";
import ListingProgressDetails from "../Listings/ListingProgressDetails";
import ListingCategoryProgressDetails from "../Listings/ListingCategoryProgressDetails";
import BuyBarrels from "../BuyBarrels/BuyBarrels";
import Home from "../Home/Home";
import Calculator from "../MiningCalculator/MiningCalculator";
import PoolStatistics from "../PoolStatistics/PoolStatistics";
import Exchange from "../Exchange/Exchange";
import Checkout from "../Checkout/Checkout";
import OrderPlaced from "../OrderPlaced/OrderPlaced";
import PowerSites from "../PowerSites/PowerSites";
import Workers from "../Workers/Workers";
import Subscriptions from "../Subscriptions/Subscriptions";
import Earnings from "../Earnings/Earnings";
import Orders from "../Orders/Orders";
import Wallet from "../Wallet/Wallet";
import Invoices from "../Invoices/Invoices";
import ViewPage from "../ViewPage/ViewPage";
import ViewBudget from "../ViewBudget/ViewBudget";
import ViewPageEth from "../ViewPageEth/ViewPageEth";
import Budget from "./BudgetFoler/Budget";
import Success from "../Success/Success";
import Canceled from "../Cancel/Cancel";
import StripeCheckout from "../StripeCheckout/StripeCheckout";
import WorkerInventory from "../WorkerInventory";
import MinerPowerSites from "../MinerPowerSites";
import PowerSitesMarket from "../MinerPowerSites/PowerSitesMarket";
import MinerPowerHistory from "../MinerPowerSites/MinerPowerHistory";
import MinerBuyEnergy from "../MinerPowerSites/MinerBuyEnergy";
import UserJourney from "../UserJourney/UserJourney";

import { connect } from "react-redux";
import {
  storeToken,
  storeUserInfo,
  clearUser,
  fetchUserDetails,
} from "../../store/actions/UserAction";
import { fetchCountries } from "../../store/actions/CountryAction";
import { fetchWorkerStatistic } from "../../store/actions/MinerAction";
import DashLayout from '../DashLayout/Layout';
import SubscriptionsPlan from "../Subscriptions/SubscriptionsPlan";
import ComputeMarket from '../ComputeMarket/ComputeMarket';
import PaymentSuccess from '../PaymentSuccess';

let api;

let currentLocation = "";
let loginCheckInterval;

const PrivateRoute = ({ component: Component, authToken, ...rest }) => {
  let messageLoginPage = "",
    messageClass = "";
  if (authToken === null) {
    messageLoginPage = "LOGIN_REQUIRED";
    messageClass = "warning";
  }
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          !_.isNull(authToken) ? (
            <Component {...rest} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: {
                  from: props.location,
                  message: messageLoginPage,
                  messageClass,
                },
              }}
            />
          )
        }
      />
    </div>
  );
};

const PublicRoute = ({ component: Component, authToken, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        _.isNull(authToken) ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      authToken: this.props.authToken,
    };
    this.logout = this.logout.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.checkKycVerified = this.checkKycVerified.bind(this);
  }
  logout(msg, message_type) {
    const { clearUser } = this.props;
    clearUser();
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
    this.props.history.push("/signin");
  }
  checkLogin() {
    if (this.props.authToken) {
      loginCheckInterval = setInterval(() => {
        if (!this.props.authToken) {
          this.logout("TOKEN_EXPIRED", "warning");
        }
      }, 1500);
    }
  }

  async checkKycVerified(flag = false) {
    let params = {};
    let response = { status: true };
    if (!!this.props.authToken) {
      if (flag === true) {
        params.twoFA = true;
      }
      const userDetailsResponse = await api.create(
        "user/getUserDetails",
        params
      );
      if (!_.isUndefined(userDetailsResponse)) {
        if (
          (_.isUndefined(userDetailsResponse.data.userKycDetail) ||
            userDetailsResponse.data.userKycDetail === null ||
            userDetailsResponse.data.userKycDetail === "") &&
          userDetailsResponse.data.isKycVerified === false
        ) {
          response.status = false;
          response.redirectUrl = "/kyc";
        } else if (
          userDetailsResponse.data.kycStatus === "u" ||
          userDetailsResponse.data.kycStatus === "r"
        ) {
          response.status = false;
          response.redirectUrl =
            userDetailsResponse.data.kycStatus === "u"
              ? "/kyc_status/pending"
              : "/kyc_status/rejected";
        } else {
          response.userDetailsResponse = userDetailsResponse;
        }
      }
    } else {
      this.props.history.push("/signin");
    }
    return response;
  }

  async componentDidMount() {
    const { fetchCountries, storeUser, storeToken } = this.props;
    fetchCountries();
    try {
      const urlString = this.props.location.pathname.substr(1);
      const urlStringArr = urlString.split("/");
      currentLocation = urlStringArr[0];
      if (currentLocation !== "secret") {
        this.checkLogin();
      } else {
        const urlData = window.location.href;
        const urlArr = urlData.split("?");
        const urlQuery = urlArr[1];
        const parsedObject = queryString.parse(urlQuery);
        const token = parsedObject.token;
        if (token) {
          const userResponse = await api.get("user/minerSignin", {
            token,
          });
          if (userResponse.code !== 200) {
            this.props.history.push("/signin");
          }
          if (userResponse.data) {
            const validToken = userResponse.data.token;
            const validUserinfo = userResponse.data.user;
            storeToken(validToken);
            storeUser(validUserinfo);
            this.props.history.push("/dashboard");
          }
        }
      }
      const token = localStorage.getItem("jwtToken");
      if (_.isNull(token) || _.isUndefined(token) || _.isEmpty(token)) {
        localStorage.clear();
        this.checkLogin();
      }
    } catch (e) {
      console.log(e);
      localStorage.clear();
      this.props.history.push("/signin");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.authToken !== nextProps.authToken) {
      this.setState({
        authToken: nextProps.authToken,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
  }

  updateWindowDimensions = () => {
    const windowHeight = window.innerHeigh;
    const windowWidth = window.innerWidth;
    const isMobileSized = window.innerWidth < 700 ? true : false;
    this.setState({ windowWidth, windowHeight, isMobileSized });
  };

  renderAuthenticationRoute() {
    return (
      <Basepages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <PublicRoute
          {...this.state}
          path="/signin"
          exact={true}
          component={Signin}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
        />
        <PublicRoute
          {...this.state}
          path="/signup"
          component={Signup}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
        />
        <PublicRoute
          {...this.state}
          path="/forgot"
          component={Forgot}
          urlpath={currentLocation}
        />
        <PublicRoute
          {...this.state}
          path="/reset_password/:id"
          component={ResetPassword}
          urlpath={currentLocation}
        />
        <PublicRoute
          {...this.state}
          path="/profile/:id"
          component={ResetPassword}
          urlpath={currentLocation}
        />
      </Basepages>
    );
  }

  renderEmailVerifyRoute() {
    return (
      <EmailVerifyBasePages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <PublicRoute
          {...this.state}
          path="/email_verification/:id"
          component={EmailVerify}
          urlpath={currentLocation}
        />
      </EmailVerifyBasePages>
    );
  }

  renderTermsAndConditionRoute() {
    return (
      <TermsBasePages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <Route
          path="/terms_conditions"
          render={() => <Terms {...this.props} {...this.state} />}
        />
      </TermsBasePages>
    );
  }

  renderAuthenitcatedRoute() {
    const { fetchUserDetails, fetchWorkerStatistic } = this.props;
    fetchUserDetails();
    fetchWorkerStatistic();
    return (
      <DashLayout
        logout={this.logout}
        currentLocation={currentLocation}
        {...this.state}
      >
        <Switch>
          <PrivateRoute
            {...this.state}
            path="/dashboard"
            component={Dashboard}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/subscription-plans"
            component={SubscriptionsPlan}
          />
          <PrivateRoute
            {...this.state}
            path="/pool_statistics"
            component={PoolStatistics}
          />
          <PrivateRoute
            {...this.state}
            path="/payment-success"
            component={PaymentSuccess}
          />
          <PrivateRoute {...this.state} path="/budgets" component={Budget} />
          <PrivateRoute
            {...this.state}
            path="/dec_stripe"
            component={Budget}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/power_sites"
            component={PowerSites}
          />
          <PrivateRoute {...this.state} path="/exchange" component={Exchange} />
          <PrivateRoute {...this.state} path="/checkout" component={Checkout} />
          <PrivateRoute
            {...this.state}
            path="/order_placed"
            component={OrderPlaced}
          />
          <PrivateRoute
            {...this.state}
            path="/listings"
            component={Listings}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/listing_detail/:id"
            component={ListingDetails}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/progress_reports/:id/category/:categoryid"
            component={ListingCategoryProgressDetails}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/resources"
            component={Resources}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/barrels/buy/:listingid"
            component={BuyBarrels}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/progress_reports/:id"
            component={ListingProgressDetails}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/my_account"
            component={MyAccount}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/manage_addresses"
            component={MyAddresses}
            checkKycVerified={this.checkKycVerified}
          />
          <PrivateRoute
            {...this.state}
            path="/logout"
            component={Logout}
            logout={this.logout}
          />
          <PrivateRoute
            {...this.state}
            path="/mining_calculator"
            component={Calculator}
          />
          <PrivateRoute
            {...this.state}
            path="/powersites"
            component={MinerPowerSites}
          />
          <PrivateRoute
            {...this.state}
            path="/journey"
            history={this.props.history}
            component={UserJourney}
          />
          <PrivateRoute
            {...this.state}
            path="/powersitehistory"
            component={MinerPowerHistory}
          />
          <PrivateRoute
            {...this.state}
            path="/powersitesMarket"
            component={PowerSitesMarket}
          />
          <PrivateRoute
            {...this.state}
            path="/buy_energy"
            component={MinerBuyEnergy}
          />
          <PrivateRoute {...this.state} path="/workers" component={Workers} />
          <PrivateRoute
            {...this.state}
            path="/subscriptions"
            component={Subscriptions}
          />
          <PrivateRoute {...this.state} path="/orders" component={Orders} />
          <PrivateRoute path="/compute-market" component={ComputeMarket} />
          <PrivateRoute
            {...this.state}
            exact
            path="/wallets"
            component={Wallet}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/success"
            component={Success}
            history={this.props.history}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/cancel"
            component={Canceled}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/stripeCheckout"
            component={StripeCheckout}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/invoices"
            component={Invoices}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/wallets/view"
            component={ViewPage}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/wallets/viewBudget"
            component={ViewBudget}
          />
          <PrivateRoute
            {...this.state}
            exact
            path="/wallets/viewEth"
            component={ViewPageEth}
          />
          <PrivateRoute {...this.state} path="/earnings" component={Earnings} />
          <PrivateRoute
            {...this.state}
            path="/worker_inventory"
            component={WorkerInventory}
          />
        </Switch>
      </DashLayout>
    );
  }

  renderCustomRoute() {
    return (
      <DashLayout
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <Switch>
          <PrivateRoute {...this.state} path="/kyc" component={Kyc} />
          <PrivateRoute
            {...this.state}
            path="/kyc_upload"
            component={KycUpload}
          />
          <PrivateRoute
            {...this.state}
            path="/kyc_submit"
            component={KycSubmit}
          />
          <PrivateRoute
            {...this.state}
            path="/kyc_status/:status"
            component={KycStatus}
          />
        </Switch>
      </DashLayout>
    );
  }

  renderHomeRoute() {
    return (
      <Landingpage
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <PublicRoute
          {...this.state}
          path="/landing"
          exact={true}
          component={Home}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
        />
      </Landingpage>
    );
  }

  renderMainPage() {
    if (!this.props.authToken) {
      return (
        <Basepages
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <Signin
                {...this.props}
                {...this.state}
                urlpath={currentLocation}
                checkLogin={this.checkLogin}
              />
            )}
          />
        </Basepages>
      );
    } else {
      return (
        <DashLayout
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <Dashboard
                {...this.props}
                {...this.state}
                checkKycVerified={this.checkKycVerified}
              />
            )}
          >
            <Redirect to="/dashboard" />
          </Route>
        </DashLayout>
      );
    }
  }
  render() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];
    let path = [
      "signin",
      "signup",
      "forgot",
      "reset_password",
      // "terms_conditions",
      "profile",
    ];
    let emailVerify = ["email_verification"];
    let termsAndConditions = ["terms_conditions"];
    let homeRoutes = ["privacy", "landing"];
    let customRoutes = ["kyc", "kyc_upload", "kyc_submit", "kyc_status"];
    if (currentLocation === "") {
      return <div>{this.renderMainPage()}</div>;
    } else if (
      currentLocation !== "" &&
      homeRoutes.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderHomeRoute()}</div>;
    } else if (
      currentLocation !== "" &&
      customRoutes.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderCustomRoute()}</div>;
    } else if (
      currentLocation !== "" &&
      emailVerify.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderEmailVerifyRoute()}</div>;
    } else if (currentLocation !== "" && path.indexOf(currentLocation) > -1) {
      return <div>{this.renderAuthenticationRoute()}</div>;
    } else if (
      currentLocation !== "" &&
      termsAndConditions.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderTermsAndConditionRoute()}</div>;
    } else {
      return <div>{this.renderAuthenitcatedRoute()}</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
  authUserInfo: state.user.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  storeUser: (userInfo) => dispatch(storeUserInfo(userInfo)),
  storeToken: (token) => dispatch(storeToken(token)),
  clearUser: () => dispatch(clearUser()),
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  fetchCountries: () => dispatch(fetchCountries()),
  fetchWorkerStatistic: () => dispatch(fetchWorkerStatistic()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
