import React, { Component } from "react";
import { Link } from "react-router-dom";
import userIcon from '../../assets/img/user-icon.svg'
import Api from "../../services/api";
import minorLogo from '../../assets/img/minor_logo.png';
import { toggleDarkMode } from "../../store/actions/DarkModeActions";
import { connect } from "react-redux";
import BudgetModal from '../Layout/BudgetFoler/BudgetModal';
import './sidebar.scss';
import walletIcon from '../../assets/img/wallet_icon.png'
import { SIDEB_BAR } from '../../utils/constant';
import { withRouter } from 'react-router-dom';
import CarotUp from '../../assets/img/carot-up.svg';
import CarotDown from '../../assets/img/carot-down.svg';
import hamBurger from '../../assets/img/hamburger.svg';
import ClickAwayListener from '../ClickAwayListner/ClickAwayListener';
import { clearUser } from '../../store/actions/UserAction';
import axios from 'axios';
import { API_ROOT } from '../../services/api-config';
import { niceNumberDecimalDisplay } from '../../utils/Util';

let api;
let loginCheckInterval;
class UserDashboardMenu extends Component {

  constructor(props) {
    super(props);
    api = new Api();
    this.interval = "";
    this.state = {
      isMenuActive: false,
      ethUnitUsdPrice: 0,
      btcUnitUsdPrice: 0,
      downloadLink: "",
      budgetModalOpen: false,
      isBitcoinDropdownOpen: false,
      toggleDropdown:0,
      subDropownToggle:false,
      walletDropdown:false,
    };
    this.menu = React.createRef();
    this.logout = this.logout.bind(this);
    this.getAgreement = this.getAgreement.bind(this);
    this.showBudgetModal = this.showBudgetModal.bind(this);
    this.onCloseModalBudget = this.onCloseModalBudget.bind(this);
  }

   componentDidMount() {
      this.setXprUnitPrice();
  }
  
  async setXprUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }
  
  toggleBitcoinDropdown = (value,type) => {
    if(value !== this.state.toggleDropdown){
      this.setState({ toggleDropdown:value })
    }else{
      if(type === 'Resources'){
        this.props.history.push('/resources');
        this.props.handleSidebar();
      }else{
        this.setState({ toggleDropdown:7 })
      }
    }
  }

  handleRedirection= (route) => {
    this.props.history.push(route);
    this.props.handleSidebar();
  }

  componentWillUnmount() {
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
  }

  toggleSubDropdown=() =>{
    this.setState({ subDropownToggle: !this.state.subDropownToggle});
  }
  activeRoute = (routeName) =>
    this.props.pathname.indexOf(routeName) > -1
      ? "menu_active"
      : "cursor-pointer";

  toggleMenu = () => {
    let menu = this.menu;
    this.setState({ isMenuActive: !this.state.isMenuActive });
    this.state.isMenuActive
      ? (menu.current.style.display = "none")
      : (menu.current.style.display = "block");
  };

  closeMenu = () => {
    let menu = this.menu;
    if (window.innerWidth < 768) {
      menu.current.style.display = "none";
    }
  };

  logout(msg, message_type) {
    const { clearUser } = this.props;
    clearUser();
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
    this.props.history.push("/signin");
  }
  
  toggleBitcoinMining = (currentIndex) => {
    // this.setState({
    //   showBitcoinMining: !this.state.showBitcoinMining,
    // });

    // this.setState((prevState) => ({
    //   isBitcoinDropdownOpen: currentIndex,
    // }));

    this.setState((prevState) => ({
      isBitcoinDropdownOpen: !prevState.isBitcoinDropdownOpen
    }))
  };


  async getAgreement() {
    const userAgreementResponse = await api.get("miner/getMinerAgreement");
    if (userAgreementResponse.code === 200) {
      let linkObject =
        userAgreementResponse.data.minerAgreementDetail.agreementDocument;
      this.setState(
        {
          downloadLink: linkObject[Object.keys(linkObject)[0]],
        },
        () => {
          window.open(this.state.downloadLink);
        }
      );
    }
  }

  showBudgetModal() {
    this.setState({ budgetModalOpen: true });
  }

  onCloseModalBudget() {
    this.setState({ budgetModalOpen: false });
  }

  render() {
    const { budgetModalOpen,walletDropdown, ethUnitUsdPrice,btcUnitUsdPrice, } = this.state;
    const { authUserInfo } = this.props;
    const userDisplayName = !!authUserInfo
      ? authUserInfo.userDisplayName || authUserInfo.fullName
      : "";
    const binancesubaccountNumber = !!authUserInfo
      ? authUserInfo.binanceAccountNumber
      : "";
      const { isBitcoinDropdownOpen } = this.state;
    const pathName = this.props.location.pathname;
    return (
      <>
        {/* <div className="">
          <div className="">
            <Link to="/dashboard">
              <div className="mm-logo">
                <img
                  className="customLogo"
                  src={supplierLogoImg}
                  height={70}
                  alt="Supplier"
                />
              </div>
            </Link>
            <div className="mm-buttons">
              <div className="mobile-menu-trigger" onClick={this.toggleMenu}>
                <div className="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>
          <div className="menu-and-user" ref={this.menu}>
            <div className="logged-user-w">
              <div className="logged-user-info-w">
                <div className="logged-user-name">
                  Welcome {userDisplayName}
                </div>
              </div>
            </div>
            <ul className="main-menu">
              <li
                className={this.activeRoute("journey")}
                onClick={this.closeMenu}
              >
                <Link to="/journey">
                  <span className="px-3 py-2">{`GET STARTED >>>`}</span>
                </Link>
              </li>

              <li
                className={this.activeRoute("dashboard")}
                onClick={this.closeMenu}
              >
                <Link to="/dashboard">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode ? dashboardLightIcon : dashboardIcon
                      }
                      alt="dashboard"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("workers")}
                onClick={this.closeMenu}
              >
                <Link to="/workers">
                  <div className="icon-w theme-color">
                    <img
                      src={this.props.darkMode ? workersLightIcon : workersIcon}
                      alt="workers"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Workers</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("worker_inventory")}
                onClick={this.closeMenu}
              >
                <Link to="/worker_inventory">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="worker_inventory"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Inventory</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("orders")}
                onClick={this.closeMenu}
              >
                <Link to="/orders">
                  <div className="icon-w theme-color">
                    <i className="fa fa-list"></i>
                  </div>
                  <span>Orders</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("wallets")}
                onClick={this.closeMenu}
              >
                <Link to="/wallets">
                  <div className="icon-w theme-color">
                    <img
                      id="walletcolor"
                      height="44px"
                      width="32px"
                      src={this.props.darkMode ? walletLightLogo : walletLogo}
                      style={{ color: "blue" }}
                      alt=""
                    />
                  </div>
                  <span>Wallets</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("my_account")}
                onClick={this.closeMenu}
              >
                <Link to="/my_account">
                  <div className="icon-w theme-color">
                    <i className="os-icon os-icon-user-male-circle2"></i>
                  </div>
                  <span style={{ fontWeight: "500" }}>My Account</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("Subscriptions")}
                onClick={this.closeMenu}
              >
                <Link className="d-flex" to="/subscriptions">
                  <div className="icon-w theme-color">
                    <img
                      className={!this.props.darkMode && "subscribeColor"}
                      style={{
                        width: "32px",
                        height: "44px",
                      }}
                      src={
                        this.props.darkMode
                          ? subscriptionLightLogo
                          : subscriptionLogo
                      }
                      alt=""
                      to="/subscriptions"
                    />
                  </div>
                  <span>Subscriptions</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("invoices")}
                onClick={this.closeMenu}
              >
                <Link to="/invoices">
                  <div className="icon-w theme-color">
                    <img
                      style={{
                        width: "32px",
                        height: "44px",
                      }}
                      className={!this.props.darkMode && "filtered"}
                      src={
                        this.props.darkMode
                          ? billingLightIconSVG
                          : billingIconSVG
                      }
                      alt=""
                    />
                  </div>
                  <span>Invoices</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("earnings")}
                onClick={this.closeMenu}
              >
                <Link to="/earnings">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode ? earningsLightIcon : earningsIcon
                      }
                      alt="earnings"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Earnings</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("Agreement")}
                onClick={this.closeMenu}
              >
                <Link to="#">
                  <div className="icon-w theme-color">
                    <img
                      className="ml-1"
                      style={{
                        width: "25px",
                        height: "35px",
                      }}
                      onClick={() => {
                        this.getAgreement();
                      }}
                      src={
                        this.props.darkMode ? downloadBlueLight : downloadBlue
                      }
                      alt=""
                    />
                  </div>
                  <span
                    onClick={() => {
                      this.getAgreement();
                    }}
                  >
                    Agreement
                  </span>
                </Link>
              </li>
              <li
                className={this.activeRoute("mining_calculator")}
                onClick={this.closeMenu}
              >
                <Link to="/mining_calculator">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="miner-calculator"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Mining Calculator</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("powersitesmarket")}
                onClick={this.closeMenu}
              >
                <Link to="/powersitesmarket">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="Offtake Market"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Offtake Market</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("powersites")}
                onClick={this.closeMenu}
              >
                <Link to="/powersites">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="My Offtakes"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>My Offtakes</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("resources")}
                onClick={this.closeMenu}
              >
                <Link to="/resources">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode ? resourcesLightIcon : resourcesIcon
                      }
                      alt="Resource Icon"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Resources</span>
                </Link>
              </li>
              <li className="hide-eshop" onClick={this.closeMenu}>
                <Link
                  to="#"
                  onClick={() => {
                    window.open(
                      `https://shop.permianchain.com?user=${auth.getToken()}`,
                      "_blank"
                    );
                  }}
                >
                  <div className="icon-w theme-color">
                    <i className="fa fa-shopping-bag"></i>
                  </div>
                  <span>Go to eShop</span>
                </Link>
              </li>

              <li>
                <Link to="/logout">
                  <div className="icon-w theme-color">
                    <img
                      src={this.props.darkMode ? logoutLightIcon : logoutIcon}
                      alt="logout"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Logout</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="icon-w theme-color">
                    <Switch
                      onColor="#6485e1"
                      // style={{width: "1px"}}
                      onChange={this.props.toggleDarkMode}
                      checked={this.props.darkMode}
                    />
                  </div>
                  <span className="ml-4 hide-dark-mode">Dark Mode</span>
                </Link>
              </li>
            </ul>
          </div>
        </div> */}

        <div className='sidebar-main-layout-container'>
          <aside className="sidebar">
            <div className='sidebar-routes-conatiner'>
              <div className="logo">
                <img onClick={this.props.handleSidebar} src={hamBurger} alt='hamburger' className='hamburger-icon'/>
                <img src={minorLogo} alt="Logo" />
                <div>
                  <span className="app-name">Edge</span>
                  <span>Permain Chain</span>
                </div>
              </div>

              <div  className="wallet-dropdown">
                <div className="wallet-icon">
                  <img src={walletIcon} alt="wallet icon" />
                </div>
                <div className="wallet-text">Your wallets</div>
                <div className="wallet-arrow">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down">
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </div>
              </div>

              {
                SIDEB_BAR.map((sidebar,index) => (
                  <div className='sidebar-routes-list' key={index}>
                    <div onClick={() => this.toggleBitcoinDropdown(index,sidebar.name)} className={index !== 3 ? 'sidebar-heading' : pathName === sidebar.route ? 'resources-heading active': 'resources-heading'}>
                      {
                        index === 3 && 
                        <div dangerouslySetInnerHTML={{ __html: sidebar.icon }} /> 
                      }
                      <p>{sidebar.name}</p>
                      {
                        index !== 3 &&
                        <img src={this.state.toggleDropdown === index ? CarotUp : CarotDown} alt='carot-up' width={10} height={10}/>
                      }
                    </div>
                    {
                      this.state.toggleDropdown === index &&
                      <>
                        {
                        sidebar && sidebar.routes && sidebar.routes.map((route,routeIndex) =>(
                          <div key={routeIndex}>
                          <div className={route.name === 'Get Started' ? 'get-started-route' : `routes-container ${pathName === route.route ? 'active' : ''}`}>
                              <div dangerouslySetInnerHTML={{ __html: route.icon }} />
                              <p onClick={() => this.handleRedirection(route.route)}>
                                {route.name}
                              </p>
                              { 
                                route.subRoutes && 
                                <img 
                                  className='sub-dropdown-img' 
                                  onClick={this.toggleSubDropdown} 
                                  src={this.state.subDropownToggle ? CarotUp : CarotDown} 
                                  alt='carot-up' 
                                  width={10} 
                                  height={10}
                                />
                              }
                            </div>
                            <div className='sub-dropdown-routes'>
                              {
                              this.state.subDropownToggle &&
                              route && route.subRoutes && route.subRoutes.map((subRoute,suRouteIndex) =>(
                                <div className={`routes-container ${pathName === subRoute.route ? 'active' : ''}`} key={suRouteIndex}>
                                  <p onClick={() => this.handleRedirection(subRoute.route)}>
                                    {subRoute.subName}
                                  </p>
                                </div>
                              ))
                              }
                            </div>
                          </div>
                        ))
                        }
                      </>
                    }
                  </div>
                ))
              }
            </div>
            
            <div className='sidebar-footer'>
              <div className='user-total-balance'>
                  <div>
                    <span>${niceNumberDecimalDisplay(ethUnitUsdPrice)}</span>
                    <p>ETH/USD</p>
                  </div>
                  <div>
                    <span>${niceNumberDecimalDisplay(btcUnitUsdPrice)}</span>
                    <p>BTC/USD</p>
                  </div>
              </div>

              <div onClick={()=> this.setState({ walletDropdown:true })} className="user-dropdown">
                <div className="user-icon">
                  <img src={userIcon} alt="user icon" />
                </div>
                <div className="user-text">{userDisplayName}</div>
                <div className="user-arrow">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                    <path d="M4.66992 4.66634L8.00326 1.33301L11.3366 4.66634" stroke="white" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.3366 11.333L8.00326 14.6663L4.66992 11.333" stroke="white" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                  </svg>
                </div>
                <ClickAwayListener onClickAway={()=> this.setState({ walletDropdown:false })}>
                  {
                    walletDropdown &&
                    <div className='wallet-dropdown-content'>
                    <div>
                      <p onClick={this.getAgreement}>Agreement</p>
                      <p onClick={() => this.handleRedirection('/my_account')}><p>My Account</p></p>
                      <p onClick={this.logout}>Logout</p>
                    </div>
                  </div>
                  }
              </ClickAwayListener>
              </div>

            </div>
          </aside>
        </div>
    
        <BudgetModal
          onCloseModalBudget={this.onCloseModalBudget}
          BudgetModalOpen={budgetModalOpen}
          paymentSource="DEC"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  darkMode: state.darkMode,
  authUserInfo: state.user.userInfo,
});

const mapDispatchToProps = (dispatch)=>({
  toggleDarkMode: toggleDarkMode,
  clearUser: () => dispatch(clearUser())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDashboardMenu));
