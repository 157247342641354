import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import Modal from "react-responsive-modal";
import moment from "moment";

import UserDashboardTopBar from "../Layout/UserDashboardTopBar";
import Sidebar from '../Sidebar/Sidebar';
import CustomHeader from '../Layout/CustomHeader';
import Footer from '../Layout/Footer';
import './dash-layout.scss';
import hamBurger from '../../assets/img/hamburger.svg';
import minorLogo from '../../assets/img/minor_logo.png';
import PaymentSuccess from '../PaymentSuccess';

class DashLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      isSidebarOpen:false,
      dateValue: "04-13-2021",
      timeValue: "10:00",
      ampmValue: "pm",
      countdown: {
        days: "",
        hours: "",
        mins: "",
        secs: "",
      },
      isCountdownSet: true,
      isModalOpen: false,
      infoMessage: "",
      settingsFormError: false,
      errorMessage: "",
    };
    this.timer = null;
    this.countDownDate = {
      dateValue: this.state.dateValue,
      timeValue: this.state.timeValue,
      ampmValue: this.state.ampmValue,
      unixEndDate: "1618770600",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    document.body.classList.add("menu-position-side");
    document.body.classList.add("menu-side-left");
    document.body.classList.add("full-screen");
    document.body.classList.add("with-content-panel");
    document.body.classList.add("user-dashboard-panel");
  }

  renderCountdownDate(countDownDate) {
    return this.countDownDate;
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };

  handleSubmit(event, dateValue, timeValue, ampmValue) {
    event.preventDefault();
    const unixEndDate = Number(
      moment(
        `${dateValue} ${timeValue} ${ampmValue}`,
        "MM-DD-YYYY hh:mm A"
      ).format("X")
    );

    if (!moment(dateValue, "MM-DD-YYYY", true).isValid()) {
      this.setState({
        settingsFormError: true,
        errorMessage:
          "Date input must be a valid date set in MM-DD-YYYY format.",
      });
    } else if (!moment(timeValue, "hh:mm", true).isValid()) {
      this.setState({
        settingsFormError: true,
        errorMessage:
          "Time input must be valid according to the 12-hour clock set in hh:mm format.",
      });
    } else if (unixEndDate - moment().format("X") < 1) {
      this.setState({
        settingsFormError: true,
        errorMessage: "The countdown must be set to a future date.",
      });
    } else {
      this.startCountdown(
        this.renderCountdownDate({
          dateValue,
          timeValue,
          ampmValue,
          unixEndDate,
        })
      );
      this.showModal(false);
    }
  }

  startCountdown(endDate) {
    clearInterval(this.timer);
    this.timer = null;

    if (endDate.unixEndDate !== "") {
      this.timer = setInterval(() => this.playTimer(endDate.unixEndDate), 1000);
    } else {
      this.setState({
        isCountdownSet: false,
        infoMessage: "Click the Settings button to start a new countdown.",
      });
    }
  }

  playTimer(unixEndDate) {
    const distance = unixEndDate - moment().format("X");

    if (distance > 0) {
      this.setState({
        countdown: {
          days: parseInt(distance / (60 * 60 * 24), 10),
          hours: parseInt((distance % (60 * 60 * 24)) / (60 * 60), 10),
          mins: parseInt((distance % (60 * 60)) / 60, 10),
          secs: parseInt(distance % 60, 10),
        },
        isCountdownSet: true,
        infoMessage: "",
      });
    } else {
      clearInterval(this.timer);
      this.timer = null;
      this.renderCountdownDate(this.countDownDate);
      this.setState({
        isCountdownSet: false,
        infoMessage:
          "Countdown ended. Click the Settings button to start a new countdown.",
      });
    }
  }

  clearCountdown() {
    if (this.renderCountdownDate().unixEndDate !== "") {
    } else {
      alert(
        "No countdown has been set. Please click the Settings button to start a new countdown."
      );
    }
  }

  showModal(status) {
    this.setState({
      dateValue: this.renderCountdownDate().dateValue,
      timeValue: this.renderCountdownDate().timeValue,
      ampmValue: this.renderCountdownDate().ampmValue,
      isModalOpen: status,
      settingsFormError: false,
    });
  }

  componentDidMount() {
    this.startCountdown(this.renderCountdownDate());

    window.addEventListener("click", (event) => {
      if (event.target.id === "modal") {
        this.showModal(false);
      }
    });
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleSidebar=() =>{
    this.setState({isSidebarOpen:false});
  }
  render() {
    const { countdown, modalOpen } = this.state;
    return (
      <div>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} />
        <div className='dashlayout-container'>
          <div className={`sidebar-container ${this.state.isSidebarOpen ? 'open' : ''}`}>      
            <Sidebar
              pathname={window.location.pathname}
              className={this.state.isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}
              handleSidebar={this.handleSidebar}
            />
          </div>

          <div className='main-content-container'>
            <div className='mobile-header-container'>
              <div className="mobile-header-top">
                <img onClick={()=>this.setState({isSidebarOpen:true})} src={hamBurger} alt='hamburger' className='hamburger-icon'/>
                <img src={minorLogo} alt="Logo" />
                <div>
                  <span className="app-name">Edge</span>
                  <span>Permain Chain</span>
                </div>
              </div>
            </div>
          

            <div className='main-content-box'> 
              <UserDashboardTopBar /> 
              {this.props.children}
            </div>
           
          </div>
        </div>
        
      <Modal
        open={modalOpen}
        onClose={() => this.onCloseModal()}
        center
        width="600px"
        classNames={{
          closeIcon: "pull-right cursor-pointer bg-white",
          modal: "instructon-modal-container",
        }}
      >
        {/* Modal Content */}
      </Modal>
    </div>
    
    );
  }
}
export default DashLayout;
