import React, { Component } from "react";
import Api from "../../services/api";
import CancelImg from "../../assets/img/Cancel.png";
// import { toast } from "react-toastify";
import "./Cancel.css";
import { pageProgress } from "../../utils/Util";
import Toast from '../CustomToast/CustomToast';
let api;

class Cancel extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      session: "",
      paymentSource: "",
      paymentStatus: "",
    };
  }

  createCustomer = async () => {
    const { session, paymentStatus } = this.state;
    if (session) {
      const response = await api.create("miner/saveStripeBudgetPayment", {
        session: session,
        payment_status: paymentStatus,
      });
      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message: response.message,
        });
        this.props.history.push("/wallets");
      }
    }
  };

  getSessionDetails = async () => {
    const urlData = window.location.href;
    const urlArr = urlData.split("?");
    const urlQuery = urlArr[1];
    if (!urlQuery) {
      this.props.history.push("/wallets");
    } else {
      const sessionArr = urlQuery.split("=");
      const exSessionArr = sessionArr[1];
      const filteredSessionArr = exSessionArr.split("&");
      const session = filteredSessionArr[0];
      const paymentSource = sessionArr[sessionArr.length - 1];
      this.setState({ paymentSource });
      if (session) {
        this.setState({ session });
        const response = await api.get("miner/checkout-session", {
          session: session,
        });
        if (response.code === 200) {
          if (response.data.payment_status === "paid") {
            this.setState({ paymentStatus: true });
          } else {
            this.setState({ paymentStatus: false });
          }
        }
      }
      if (this.state.paymentSource === "budget") {
        this.createCustomer();
      } else {
        this.setState({ okayLoading: false });
      }
    }
  };

  componentDidMount() {
    pageProgress("remove");
    Toast({
      type:'info',
      title: 'Success',
      message: "Please wait saving the transaction details!",
    });
    // toast.success("Please wait saving the transaction details!");
    this.getSessionDetails();
  }

  render() {
    return (
      <div className="buy-xpr-container ">
        <div className="content-i operation_page_main dashboard-page-main">
          <div className="content-box">
            <div className="succes-container p-3">
              <div className="text-center">
                <img className="cancel-img" src={CancelImg} alt="success" />
              </div>
              <h5 className="success-head mt-3">
                Payment Not Requested Successfully!
              </h5>
              <div className="text-center mt-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push("/wallets");
                  }}
                >
                  Okay
                </button>
              </div>
              <div className="text-center mt-2">
                <span className="support-text gray pl-2 pr-2">
                  For support you may contact support@permianchain.com or use
                  the live chat below.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cancel;
