import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Input,
  Checkbox,
} from "@material-ui/core";
import AlgorithmIcon from '../../assets/img/algorithm.svg';
import PowerIcon from '../../assets/img/power.svg';
import effieciencyIcon from '../../assets/img/efficincy.svg';
import HashIcon from '../../assets/img/hashrate.svg';
import { niceNumberDecimalDisplay } from "../../utils/Util";
import './get-started.scss';

const useStyles = (theme) => ({
  card: {
    width: "100%",
    boxShadow: "0px 0px 16px rgb(0 0 0 / 22%)",
  },
  media: {
    height: 200,
    width: "100%",
  },
  header: {
    fontWeight: "600 !important",
    fontSize: "1.2rem",
    marginTop: "20px",
    marginBottom: "20px",
  },
  item: {
    fontWeight: "600 !important",
  },
  divide: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  price: {
    fontWeight: "600",
    fontSize: "1rem",
    color: "#111",
  },
  imageContainer: {
    position: "relative",
    textAlign: "center",
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "rgba(255, 0, 0, 0.5)",
    fontSize: "3.5rem",
    fontWeight: "600",
  },
});

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
    // this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  // handleCheckBox = (event) => {
  //   if (event.target.checked) {
  //     this.props.onSelected(event.target.name);
  //   } else {
  //     this.props.onSelected(null);
  //   }
  // };

  handleSelect = (productId) => {
    const isSelected = this.props.selectedProduct === productId;
    this.props.onSelected(isSelected ? null : productId);
  };
  
  render() {
    const { classes, products, selectedProduct } = this.props;
    const description = [
      {
        key: "algorithm",
        label: "Algorithm",
        format: "",
        icon:AlgorithmIcon
      },
      {
        key: "hashrate",
        label: "Hashrate",
        format: "T",
        icon:HashIcon
      },
      {
        key: "powerConsumption",
        label: "Power (W):",
        format: "W",
        icon:PowerIcon
      },
      {
        key: "powerWth",
        label: "Efficiency (W/T)",
        format: "",
        icon:effieciencyIcon
      },
      // {
      //   key: "costPerTerahash",
      //   label: "Cost ($/T)",
      //   format: "/T",
      //   dollarSign: true,
      //   icon:
      // },
    ];

    return (
      <section className='products-container'>
        {products.map((product, index) => {
          let shouldDisable = false;
          if (selectedProduct && selectedProduct !== product._id) {
            shouldDisable = true;
          }
          if (product.quantity === 0) {
            shouldDisable = true;
          }

          return (
            <div className='card-box-container' key={product._id}>
              <div className='card-box'>
                <div className='image-container'>
                  <img
                    className={product?.model?.includes("SOLD OUT") ? 'sold-out-img' :''}
                    src={`${product.imageUrl}`}
                    alt={product.model}
                  />
                </div>

                <div className='content-container'>
                  <h3
                    className='content-heading'
                  >
                    {product.model}
                  </h3>
                  {description.map((desc) => {
                    return (
                      <div
                        className='content-description'
                      >
                        <img src={desc.icon} alt='desc' width={25} height={25} />
                        <span>{desc.label}:</span> {desc.dollarSign && "$"}
                        {product[desc.key]}
                        {desc.format}
                      </div>
                    );
                  })}

                  <p className='product-price'>
                    <span>Price:</span> ${niceNumberDecimalDisplay(product.priceInUsd, 2)}
                  </p>

                  <div className='quantity-selector'>
                    {selectedProduct !== product._id &&
                      <button
                        onClick={() => this.handleSelect(product._id)}
                        className={`btn-primary ${selectedProduct === product._id ? 'selected' : ''}`}
                        disabled={product?.model?.includes("SOLD OUT")}
                      >
                        <p>{product?.model?.includes("SOLD OUT") ? "Sold Out" :'Add to my stack'}</p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 6V18" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M6 12H18" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                    }
                  
                  {/* <Checkbox
                    onClick={this.handleCheckBox}
                    name={product._id}
                    checked={
                      selectedProduct ? selectedProduct === product._id : false
                    }
                    disabled={shouldDisable}
                  /> */}

                  {selectedProduct === product._id && (
                    <>
                      <span>Quantity </span>
                      <span>
                        {" "}
                        <input
                          className="input-field"
                          placeholder="How many you want?"
                          type="number"
                          InputProps={{
                            inputProps: { min: "1", max: "10", step: "1" },
                          }}
                          onChange={(e) =>
                            this.props.onQuantity(e.target.value)
                          }
                          value={this.props.quantity || 1}
                        />
                        <div onClick={() => this.handleSelect(null)} className='delete-icon'>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3335 4.5835H16.6668" stroke="#FF6C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.5 2.0835H12.5" stroke="#FF6C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 7.0835H15V16.6668C15 17.3572 14.4404 17.9168 13.75 17.9168H6.25C5.55962 17.9168 5 17.3572 5 16.6668V7.0835Z" stroke="#FF6C6C" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M8.3335 10.417L11.6668 13.7503" stroke="#FF6C6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.6668 10.417L8.3335 13.7503" stroke="#FF6C6C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                      </span>
                    </>
                  )}
                  </div>
                </div>
              </div>
            </div>
           
          );
        })}{" "}
      </section>
    );
  }
}

export default withStyles(useStyles)(Product);
