import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import decLogo from "../../assets/img/wallet-usd.svg";
import dollarLogo from "../../assets/img/wallet-asic.svg";
import greenDot from "../../assets/img/green-mark.svg";
import actionIcon from "../../assets/img/action-icon.svg";

import overlayFactory from "react-bootstrap-table2-overlay";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { API_NETWORK, API_CONTRACTADDRESS } from "../../services/api-config";
// import { toast } from "react-toastify";
import Api from "../../services/api";
import btcLogo from "../../assets/img/wallet-btc.svg";
import BudgetModal from "../Layout/BudgetFoler/BudgetModal";
import {
  niceNumberDecimalDisplay,
  getBtcUsd,
  pageProgress,
  niceNumberNoDecimalDisplay,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import WithdrawModal from "./WithdrawModal";
import ConfirmationModal from "./ConfirmationModal";
import DepositModal from "./DepositModal";
import WalletTooltip from "./WalletTooltip";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../store/actions/UserAction";
import { DEC_TO_USD } from "../../utils/constant";
import Table from "../Table/Table";
import './wallet.scss';
import Toast from '../CustomToast/CustomToast';
import ClickAwayListener from '../ClickAwayListner/ClickAwayListener';

let api;

class Wallet extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      isOpen: '',
      earningsData: [],
      tableLoading: true,
      BudgetModalOpen: false,
      isHavingBudget: "",
      ethBalance: "",
      confirmationModalOpen: false,
      withdrawModalOpen: false,
      budgetBalance: "",
      depositModalOpen: false,
      paymentSource: "",
      earningsRenderFlag: false,
      decWalletAddress: "",
      decSecretKey: "",
      walletAddress: "",
      genrateAdddress: "",
      currency: "",
      btcBalance: "",
      decBalance: "",
      btcWalletAddress: "",
      btcUsdPrice: 0,
      decFetchErr: false,
    };
    pageProgress("force_remove");
    this.BudgetShowModal = this.BudgetShowModal.bind(this);
    this.onCloseModalBudget = this.onCloseModalBudget.bind(this);
    this.withdrawShowModal = this.withdrawShowModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.withdrawOnCloseModal = this.withdrawOnCloseModal.bind(this);
    this.generateWallet = this.generateWallet.bind(this);
    this.copyWhiteListAddress = this.copyWhiteListAddress.bind(this);
    this.confirmationOnCloseModal = this.confirmationOnCloseModal.bind(this);
    this.confirmationShowModal = this.confirmationShowModal.bind(this);
  }

  BudgetShowModal() {
    this.setState({
      BudgetModalOpen: true,
    });
  }

  onCloseModalBudget() {
    this.setState({ BudgetModalOpen: false });
  }

  copyWhiteListAddress() {
    let msg = "Address successfully copied!";
    Toast({
      type:'success',
      title: 'Success',
      message: msg,
    });
  }

  withdrawShowModal(asset, address) {
    this.setState({
      title: "",
      withdrawModalOpen: true,
      currency: asset,
      genrateAdddress: address,
    });
  }

  async confirmationShowModal() {
    const response = await api.create("user/wallet/import");
    if (response.code === 200) {
      Toast({
        type:'success',
        title: 'Success',
        message: response.message,
      });
    } else {
      Toast({
        type:'error',
        title: 'Error',
        message: response.message,
      });
    }
    this.setState({
      title: "",
      confirmationModalOpen: true,
    });
  }

  async generateWallet() {
    const response = await api.create("miner/getEthAddresses");

    if (response.code === 200 && response.data) {
      this.setState({
        decWalletAddress: response.data.EthAddress,
        decSecretKey: response.data.secretKey,
      });
    }
    const newData = this.state.earningsData.map((d) => {
      if (d.asset === "DEC") {
        return {
          asset: "DEC",
          balance: niceNumberDecimalDisplay(this.state.ethBalance, 2),
          operations: false,
          walletAddress: this.state.decWalletAddress,
          showWalletAddress: true,
          showGenerateWalletAddress: false,
        };
      }
      return d;
    });
    this.setState({
      earningsData: newData,
    });
  }

  onCloseModal() {
    this.setState({ depositModalOpen: false });
  }

  confirmationOnCloseModal() {
    this.setState({ confirmationModalOpen: false });
  }

  withdrawOnCloseModal() {
    this.setState({ withdrawModalOpen: false });
  }

  async componentDidMount() {
    document.title =
      "Wallets" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.props.fetchUserDetails(async (userDetails) => {
      await this.getRecords(userDetails);
    });
  }

  async getRecords(authUserInfo) {
    try {
      const priceUsd = await getBtcUsd();
      this.setState({ btcUsdPrice: priceUsd });
      if (!(authUserInfo && authUserInfo.btcWalletAddress)) {
        Toast({
          type:'warn',
          title: 'Warning',
          message: 'BTC Wallet will be generated once you have at least one worker',
        });
        // toast.warn(
        //   "BTC Wallet will be generated once you have at least one worker"
        // );
      }

      if (authUserInfo && authUserInfo.receivingEthAddress) {
        try {
          const userEthBalance = await axios.get(
            `${API_NETWORK}?module=account&action=tokenbalance&contractaddress=${API_CONTRACTADDRESS}&address=${authUserInfo.receivingEthAddress}&tag=latest&apikey=APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V`,
            {
              headers: "",
              timeout: 5000,
            }
          );

          if (userEthBalance.status === 200) {
            // TODO: logic here needs commenting!
            let finalTokenValue = Math.ceil(Number(userEthBalance.data.result));
            finalTokenValue = finalTokenValue / Math.pow(10, 18);
            this.setState({
              ethBalance: finalTokenValue,
              decWalletAddress: authUserInfo.receivingEthAddress,
              decFetchErr: false,
            });
          }
        } catch (err) {
          this.state.decFetchErr = true;
          console.log(err.message);
          Toast({
            type:'error',
            title: 'Error',
            message: err.message + ": Getting DEC Balance",
          });
          // toast(err.message + ": Getting DEC Balance");
        }
      }

      if (authUserInfo && authUserInfo.btcWalletAddress) {
        const response = await api.get("miner/checkBalanceBtc");

        if (response.code === 200 && response.data && response.data.Balance) {
          this.setState({
            btcBalance: response.data.Balance.available,
            btcWalletAddress: authUserInfo.btcWalletAddress,
          });
        }
      }

      if (authUserInfo && authUserInfo.isHavingBudget) {
        const budgetResponse = await api.get("miner/viewBudget", {
          type: "budget",
        });

        if (
          budgetResponse.code === 200 &&
          budgetResponse.data &&
          budgetResponse.data.budgetedMiner
        ) {
          this.setState({
            budgetBalance: budgetResponse.data.budgetedMiner.amountLeft,
            budgetBtcBalance: budgetResponse.data.budgetedMiner.amountLeftinBTC,
          });
        }
      }

      let earningsData = [
        {
          asset: "ASIC BUDGET",
          balance: niceNumberDecimalDisplay(this.state.budgetBalance, 2),
        },
        {
          asset: "BTC",
          balance: niceNumberDecimalDisplay(this.state.btcBalance, 8),
          walletAddress: authUserInfo.btcWalletAddress,
        },
        {
          asset: "DEC",
          balance: niceNumberDecimalDisplay(this.state.ethBalance, 2),
          walletAddress: authUserInfo.receivingEthAddress,
          operations: authUserInfo.receivingEthAddress ? false : true,
        },
      ];
      this.setState({
        earningsData,
        tableLoading: false,
        earningsRenderFlag: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  renderTotal() {
    const { budgetBalance, btcBalance, ethBalance } = this.state;
    const total =
      (budgetBalance || 0) +
      (this.btcToUsd(btcBalance) || 0) +
      (this.decToUsd(ethBalance) || 0);
    return (
      <div>
        <div>
          <h2 className="text-bold">
            {niceNumberDecimalDisplay(total, 2) || "0.00"} USD
          </h2>
        </div>
        <div>
          <p className="walletFont">Total Balance</p>
        </div>
      </div>
    );
  }

  btcToUsd(balance) {
    const { btcUsdPrice } = this.state;
    return balance * btcUsdPrice;
  }

  decToUsd(balance) {
    return balance * DEC_TO_USD;
  }

  convertBTCToUSD(balance) {
    const balanceInUSD = this.btcToUsd(balance);
    return niceNumberDecimalDisplay(balanceInUSD, 2) || "0.00";
  }

  convertDECToUSD(balance) {
    const balanceInUSD = this.decToUsd(balance);
    return niceNumberDecimalDisplay(balanceInUSD, 2) || "0.00";
  }

  renderBalance(cell, asset) {
    const { btcBalance, ethBalance, budgetBtcBalance } = this.state;

    let balance1 = cell;
    let balance2 = niceNumberDecimalDisplay(budgetBtcBalance, 8) || "0.00";
    let currency1 = "USD";
    let currency2 = "DEC";
    if (asset === "BTC") {
      balance1 = niceNumberDecimalDisplay(btcBalance, 8) || "0.00";
      balance2 = this.convertBTCToUSD(btcBalance);
      currency1 = "BTC";
      currency2 = "USD";
    } else if (asset === "DEC") {
      balance1 = niceNumberDecimalDisplay(ethBalance, 2) || "0.00";
      balance2 = this.convertDECToUSD(ethBalance);
      currency1 = "DEC";
      currency2 = "USD";
    }
    return (
      <>
        <div className="mr-2 font-size-16">
          <p style={{ color: "white", marginBottom: "4px" }}>
            {balance1} {currency1}
          </p>
        </div>
        <div className="mr-2 font-size-16">
          <p style={{ color: "white", marginBottom: "0" }}>
            {balance2} {currency2}
          </p>
        </div>
      </>
    );
  }

  render() {
    const {
      earningsData,
      earningsRenderFlag,
      tableLoading,
      workerLoading,
      BudgetModalOpen,
      btcBalance,
      decBalance,
    } = this.state;

    const _this = this;
    
    // const workerColumns = [
    //   {
    //     headerClasses: "custom-table-th",
    //     style: { whiteSpace: "nowrap" },
    //     dataField: "asset",
    //     text: "ASSET",
    //     formatter: function (cell) {
    //       let logoSrc = btcLogo;
    //       let style = {
    //         marginRight: "5px",
    //         marginLeft: "5px",
    //         height: "70px",
    //         width: "70px",
    //       };
    //       let title = "Bitcoin";
    //       let currency = "BTC";
    //       if (cell === "DEC") {
    //         logoSrc = decLogo;
    //         style = {
    //           marginRight: "5px",
    //           marginLeft: "6px",
    //           borderRadius: "50%",
    //           height: "65px",
    //           width: "65px",
    //         };
    //         title = "Digital Energy Currency";
    //         currency = "DEC";
    //       } else if (cell === "ASIC BUDGET") {
    //         logoSrc = dollarLogo;
    //         style = {
    //           marginRight: "7px",
    //           marginLeft: "11px",
    //           borderRadius: "50%",
    //           height: "56px",
    //           width: "56px",
    //           objectFit: "cover",
    //         };
    //         title = "ASIC BUDGET";
    //         currency = "USD";
    //       }
    //       return (
    //         <div className="logowithText">
    //           <span className="p-inherit">
    //             <div style={{ backgroundColor: "" }}>
    //               <div>
    //                 <img
    //                   src={logoSrc}
    //                   className="setDeclogo"
    //                   alt="DEC Logo"
    //                   height="65px"
    //                   width="65px"
    //                   style={style}
    //                 />
    //               </div>
    //             </div>
    //           </span>

    //           <div className="decText ml-1">
    //             <span className="text-bold">{title}</span>
    //             {cell === "BTC" && (
    //               <span className="custom-tooltip-coin1  justify-content-center ml-1 mr-1">
    //                 <i
    //                   className=" icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
    //                   style={{
    //                     color: "#999999",
    //                     cursor: "pointer",
    //                   }}
    //                 ></i>
    //                 <WalletTooltip />
    //               </span>
    //             )}
    //             <br />
    //             <span>({currency})</span>
    //           </div>
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "balance",
    //     text: "BALANCE",
    //     headerFormatter: function () {
    //       return (
    //         <span className="vertical-center justify-content-start">
    //           <span className="mr-2">BALANCE</span>
    //         </span>
    //       );
    //     },
    //     formatter: function (cell, row) {
    //       return (
    //         <div className="balanceInfo" style={{ whiteSpace: "nowrap" }}>
    //           {_this.renderBalance(cell, row.asset)}
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "walletAddress",
    //     text: "WALLET ADDRESS",
    //     isDummyField: true,
    //     formatter: function (cell, row) {
    //       let smallAddress;
    //       if (row.walletAddress) {
    //         smallAddress = String(row.walletAddress);
    //         smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
    //           smallAddress.length - 3,
    //           smallAddress.length
    //         )}`;
    //       }
    //       return row.walletAddress ? (
    //         <div>
    //           <span style={{ color: "gray" }}>{smallAddress}</span>
    //           <CopyToClipboard
    //             text={row.walletAddress}
    //             onCopy={() => _this.copyWhiteListAddress()}
    //           >
    //             <i
    //               className="fa fa-clipboard cursor-pointer ml-2 standardColorIcon"
    //               aria-hidden="true"
    //             ></i>
    //           </CopyToClipboard>
    //         </div>
    //       ) : row.walletAddress === "" && row.asset === "DEC" ? (
    //         <button
    //           className="btn btn-primary"
    //           onClick={() => {
    //             _this.generateWallet();
    //           }}
    //         >
    //           Generate Wallet
    //         </button>
    //       ) : (
    //         ""
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "operations",
    //     text: "OPERATIONS",
    //     formatter: function (cell, row) {
    //       return (
    //         <div className="btn-group mr-1 mb-1">
    //           <div>
    //             {row.asset === "BTC" ? (
    //               <Link to={`wallets/view`}>
    //                 <button
    //                   className="btn btn-primary mr-2"
    //                   type="button"
    //                   disabled={!row.walletAddress}
    //                 >
    //                   View
    //                 </button>
    //               </Link>
    //             ) : row.asset === "DEC" ? (
    //               <Link
    //                 to={{
    //                   pathname: "wallets/viewEth",
    //                   state: { decAddress: row.walletAddress },
    //                 }}
    //               >
    //                 <button
    //                   className="btn btn-primary mr-2"
    //                   type="button"
    //                   disabled={!row.walletAddress || _this.state.decFetchErr}
    //                 >
    //                   View
    //                 </button>
    //               </Link>
    //             ) : row.asset === "ASIC BUDGET" ? (
    //               <Link
    //                 to={{
    //                   pathname: "/wallets/viewBudget",
    //                 }}
    //               >
    //                 <button className="btn btn-primary mr-2" type="button">
    //                   View
    //                 </button>
    //               </Link>
    //             ) : (
    //               ""
    //             )}
    //             {row.asset === "BTC" ? (
    //               <button
    //                 className="btn btn-primary mr-2"
    //                 type="button"
    //                 disabled={!row.walletAddress}
    //                 onClick={() => _this.withdrawShowModal(row.asset)}
    //               >
    //                 Withdraw
    //               </button>
    //             ) : row.asset === "DEC" ? (
    //               ""
    //             ) : row.asset === "ASIC BUDGET" ? (
    //               <button
    //                 className="btn btn-primary mr-2"
    //                 type="button"
    //                 onClick={() => {
    //                   _this.setState({ paymentSource: "budget" }, () => {
    //                     _this.BudgetShowModal();
    //                   });
    //                 }}
    //               >
    //                 Deposit
    //               </button>
    //             ) : (
    //               ""
    //             )}
    //             {row.asset === "DEC" ? (
    //               <button
    //                 className="btn btn-primary mr-2"
    //                 type="button"
    //                 disabled={!row.walletAddress || _this.state.decFetchErr}
    //                 onClick={() => {
    //                   _this.setState({ paymentSource: "DEC" }, () => {
    //                     _this.BudgetShowModal();
    //                   });
    //                 }}
    //               >
    //                 Buy
    //               </button>
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //           {row.walletAddress && row.asset === "DEC" ? (
    //             <div id="keyLeft">
    //               <span
    //                 className=" custom-tooltip row justify-content-center ml-2 mr-3"
    //                 tooltip-title={`import Wallet`}
    //               >
    //                 <i
    //                   className=" fa fa-key privateKey fa-lg mt-2 mr-3 standardColorIcon"
    //                   aria-hidden="true"
    //                   onClick={() => {
    //                     _this.confirmationShowModal();
    //                   }}
    //                 ></i>
    //               </span>
    //               <CopyToClipboard
    //                 text={API_CONTRACTADDRESS}
    //                 onCopy={() => _this.copyWhiteListAddress()}
    //               >
    //                 <span
    //                   className="custom-tooltip row justify-content-center"
    //                   tooltip-title={`Copy Smart Contract Address`}
    //                 >
    //                   <i className=" fa fa-id-badge fa-lg standardColorIcon"></i>
    //                 </span>
    //               </CopyToClipboard>
    //             </div>
    //           ) : (
    //             ""
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    // ];

    const columns = [
      {
        heading: "",
        style: { whiteSpace: "nowrap" },
        accessor: "",
        Cell: ({ row, value }) => {
          console.log(row.asset, "cell");
          let logoSrc = btcLogo;
          let style = {
            marginRight: "5px",
            marginLeft: "5px",
            height: "70px",
            width: "70px",
          };
          let title = "Bitcoin";
          let currency = "BTC";
          if (row.asset === "DEC") {
            logoSrc = decLogo;
            style = {
              marginRight: "5px",
              marginLeft: "6px",
              borderRadius: "50%",
              height: "65px",
              width: "65px",
            };
            title = "Digital Energy Currency";
            currency = "DEC";
          } else if (row.asset === "ASIC BUDGET") {
            logoSrc = dollarLogo;
            style = {
              marginRight: "7px",
              marginLeft: "11px",
              borderRadius: "50%",
              height: "56px",
              width: "56px",
              objectFit: "cover",
            };
            title = "ASIC BUDGET";
            currency = "USD";
          }
          return (
            <div className="logowithText">
              <span className="p-inherit">
                <div style={{ backgroundColor: "" }}>
                  <div>
                    <img
                      src={logoSrc}
                      className="setDeclogo"
                      alt="DEC Logo"
                      style={style}
                    />
                  </div>
                </div>
              </span>

              <div className="decText wallet-icons ml-1">
                <span
                  className="heading-wallet"
                >
                  {title}
                </span>
                {/* {row.asset === "BTC" && (
                  <span className="custom-tooltip-coin1  justify-content-center ml-1 mr-1">
                    <i
                      className=" icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                      style={{
                        color: "#999999",
                        cursor: "pointer",
                      }}
                    ></i>
                    <WalletTooltip />
                  </span>
                )} */}
                <span>{currency}</span>
              </div>
            </div>
          );
        },
      },

      {
        heading: "",
        accessor: "",
        Cell: () => {
          return (
            <span className="vertical-center justify-content-start">
              <span className="mr-2">BALANCE</span>
            </span>
          );
        },
        Cell: ({ row, cell }) => {
          return (
            <div className="balanceInfo" style={{ whiteSpace: "nowrap" }}>
              {_this.renderBalance(cell, row.asset)}
            </div>
          );
        },
      },
      {
        heading: "",
        accessor: "",
        Cell: ({ value, row }) => {
          let smallAddress;
          if (row.walletAddress) {
            smallAddress = String(row.walletAddress);
            smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
              smallAddress.length - 3,
              smallAddress.length
            )}`;
          }
          return row.walletAddress ? (
            <div className='wallet-address'>
              <span style={{ color: "gray",marginRight:'8px' }}>{smallAddress}</span>
              <CopyToClipboard
                text={row.walletAddress}
                onCopy={() => _this.copyWhiteListAddress()}
              >
                <svg width="15" height="15" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.66699 4.14419V2.60449C4.66699 2.08673 5.08673 1.66699 5.60449 1.66699H13.7295C14.2473 1.66699 14.667 2.08673 14.667 2.60449V10.7295C14.667 11.2473 14.2473 11.667 13.7295 11.667H12.1724" stroke="#45454B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.0625 4.33301H2.9375C2.41973 4.33301 2 4.75274 2 5.27051V13.3955C2 13.9133 2.41973 14.333 2.9375 14.333H11.0625C11.5803 14.333 12 13.9133 12 13.3955V5.27051C12 4.75274 11.5803 4.33301 11.0625 4.33301Z" stroke="#45454B" stroke-width="1.4" stroke-linejoin="round"/>
                </svg>
              </CopyToClipboard>
            </div>
          ) : row.walletAddress === "" && row.asset === "DEC" ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                _this.generateWallet();
              }}
            >
              Generate Wallet
            </button>
          ) : (
            ""
          );
        },
      },
      // {
      //   heading: "",
      //   accessor: "",
      //   Cell: ({ row, value }) => {
      //     return (
      //       <div className="btn-group d-flex justify-content-end mr-1 mb-1">
      //         {row.walletAddress && row.asset === "DEC" ? (
      //           <div id="keyLeft">
      //             <span
      //               className=" custom-tooltip row justify-content-center ml-2 mr-3"
      //               tooltip-title={`import Wallet`}
      //             >
      //               <i
      //                 className=" fa fa-key privateKey fa-lg mt-2 mr-3 standardColorIcon"
      //                 aria-hidden="true"
      //                 onClick={() => {
      //                   _this.confirmationShowModal();
      //                 }}
      //               ></i>
      //             </span>
      //             <CopyToClipboard
      //               text={API_CONTRACTADDRESS}
      //               onCopy={() => _this.copyWhiteListAddress()}
      //             >
      //               <span
      //                 className="custom-tooltip row justify-content-center"
      //                 tooltip-title={`Copy Smart Contract Address`}
      //               >
      //                 <i className=" fa fa-id-badge fa-lg standardColorIcon"></i>
      //               </span>
      //             </CopyToClipboard>
      //           </div>
      //         ) : (
      //           ""
      //         )}
      //       </div>
      //     );
      //   },
      // },
      {
        heading: "",
        accessor: "",
        Cell: ({ row, value }) => {
          return (
            <div className="btn-group d-flex justify-content-end mr-1 mb-1">
              <div className="view-buttons">
                {row.asset === "BTC" ? (
                  <Link to={`wallets/view`}>
                    <button
                      className="btn btn-primary mr-2"
                      type="button"
                      disabled={!row.walletAddress}
                    >
                      View
                    </button>
                  </Link>
                ) : row.asset === "DEC" ? (
                  <Link
                    to={{
                      pathname: "wallets/viewEth",
                      state: { decAddress: row.walletAddress },
                    }}
                  >
                    <button
                      className="btn btn-primary mr-2"
                      type="button"
                      disabled={!row.walletAddress || _this.state.decFetchErr}
                    >
                      View
                    </button>
                  </Link>
                ) : row.asset === "ASIC BUDGET" ? (
                  <Link
                    to={{
                      pathname: "/wallets/viewBudget",
                    }}
                  >
                    <button className="btn btn-primary mr-2" type="button">
                      View
                    </button>
                  </Link>
                ) : (
                  ""
                )}
                {row.asset === "BTC" ? (
                  <button
                    className="outline-btn mr-2"
                    type="button"
                    disabled={!row.walletAddress}
                    onClick={() => _this.withdrawShowModal(row.asset)}
                  >
                    Withdraw
                  </button>
                ) : row.asset === "DEC" ? (
                  ""
                ) : row.asset === "ASIC BUDGET" ? (
                  <button
                    className="mr-2 outline-btn"
                    type="button"
                    onClick={() => {
                      _this.setState({ paymentSource: "budget" }, () => {
                        _this.BudgetShowModal();
                      });
                    }}
                  >
                    Deposit
                  </button>
                ) : (
                  ""
                )}
                {row.asset === "DEC" ? (
                  <button
                    className="mr-2 outline-btn"
                    type="button"
                    disabled={!row.walletAddress || _this.state.decFetchErr}
                    onClick={() => {
                      _this.setState({ paymentSource: "DEC" }, () => {
                        _this.BudgetShowModal();
                      });
                    }}
                  >
                    Buy
                  </button>
                ) : (
                  ""
                )}

                <div className="dropdown">
                  {row.walletAddress && row.asset === "DEC" &&
                    <button
                    className="dropdown-icon"
                    onClick={()=> {
                      if(this.state.isOpen !== row.asset){
                        this.setState({ isOpen:row.asset })
                      }else{
                        this.setState({ isOpen:'' })
                      }
                    }} 
                  >
                    <img src={actionIcon} alt="img" />
                  </button>
                  }
                  {
                      this.state.isOpen === row.asset &&
                      <div className='wallet-action-content'>
                      <div>
                        <p onClick={() => _this.confirmationShowModal()}>Import Wallet</p>
                        <p onClick={() => _this.copyWhiteListAddress()}>Copy Address</p>
                      </div>
                    </div>
                    }                   
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const WorkerRemoteAll = ({ data, page, sizePerPage, totalSize }) => (
      <div className="table-responsive wallet-table">
        {console.log('data',data)}
        <Table
          showPagination={false}
          data={data}
          onSortedList={() => console.log("")}
          column={columns}
          currentPage={page}
          onPageChange={(page) => this.setState({ earningsPage: page })}
          totalSize={totalSize}
          itemsPerPage={10}
          className={'wallet-table'}
        />
        {/* <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        /> */}
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
    };

    return (
      <>
        <div className="wallelts-content wallet-container">
          <h2>Wallets</h2>
          <p>
            Manage your assets, check balances, and perform operations with ease
          </p>

          <div className="worker-box">
            <div className="green-img">
              <img src={greenDot} alt="img" />
            </div>
            <p className="balance-content">{this.renderTotal()}</p>
          </div>

          <div className="">
            <div className="card-inner-padding-wallet mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4 ">
              <div className="earnings-table table-responsive">
                <div>
                  {tableLoading && (
                    <div className="text-center mb-2 mt-2">
                      <span id="loading" color="#047bf8" />
                    </div>
                  )}
                </div>
                {earningsRenderFlag === true && (
                  <WorkerRemoteAll data={earningsData} />
                )}
              </div>
              <WithdrawModal
                {...this.props}
                {...this.state}
                withdrawOnCloseModal={this.withdrawOnCloseModal}
                btcBalance={btcBalance}
                decBalance={decBalance}
                onInputValueChange={this.onchange}
              />
              <DepositModal
                {...this.props}
                {...this.state}
                depositOnCloseModal={this.onCloseModal}
                onInputValueChange={this.onchange}
              />
              <ConfirmationModal
                {...this.props}
                {...this.state}
                confirmationOnCloseModal={this.confirmationOnCloseModal}
                onInputValueChange={this.onchange}
              />
            </div>
          </div>
          <BudgetModal
            authToken={this.props.authToken}
            onCloseModalBudget={this.onCloseModalBudget}
            BudgetModalOpen={BudgetModalOpen}
            paymentSource={this.state.paymentSource}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
