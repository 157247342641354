import React from 'react'
import './subscription.scss';

const PlanCard = ({ plan,index,setPlanSelction }) => {
  return (
    <div className='plan-card-box' key={index}>
      <h2>{plan.heading}</h2>
      <p className='price'>{plan.price}</p>
      <ul>
        {plan.features.map((feature, featureIndex) => (
          <li key={featureIndex}>{feature}</li>
        ))}
      </ul>
      <span>{plan.subHeading}</span>
      <button className='btn-primary' onClick={()=> setPlanSelction(plan)}>{plan.buttonText}</button>
    </div>
  )
}

export default PlanCard
