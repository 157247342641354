import React from 'react'
import StripeIcon from '../../assets/img/stripe-icon.svg';
import fiatIcon from '../../assets/img/fiat-icon.svg';
import btcIcon from '../../assets/img/btc-icon.svg';
import decIcon from '../../assets/img/dec-icon.svg';
import './subscription.scss';

const SelectPaymentModal = ({ planSelection,paymentMethod,setPaymentMethod }) => {
  const paymentMethods = [
    {
      name:'Stripe',
      icons:StripeIcon,
      id:1
    },
    {
      name:'FIAT Payment Request',
      icons:fiatIcon,
      id:2
    },
    {
      name:'BTC Payment',
      icons:btcIcon,
      id:3
    },
    {
      name:'DEC Payment',
      icons:decIcon,
      id:4
    }
  ]
  return (
    <>
    {
      paymentMethod === 0 ?
      <div className='select-payment-method-modal'>
      <p>Benefit from occasional discounts when making payments in DEC</p>
      <div className='payment-methods-list'>
        {
          paymentMethods.map((paymentMethod,index) => (
            <div key={index} className='payment-box'>
              <div className='payment-name'>
                <img src={paymentMethod.icons} alt={paymentMethod.name} />
                <h4>{paymentMethod.name}</h4>
              </div>
              <button onClick={() => setPaymentMethod(paymentMethod.id)} className='btn-secondary'>Select</button> 
            </div>
          ))
        }
      </div>
      </div>
      :paymentMethod === 3 ?
      <>
      <div
        className="btc-payment-method-modal">
        <div>
          <h4 className='heading'>
            BTC Payment
          </h4>
          <p className='desc'>Securely complete your Bitcoin payment by filling in the required details.</p>
          <div className='form-modal'>
            <label htmlFor="title">
              Receiving Address
              <span className="required-field">*</span>
            </label>
            <input
              className="input-field"
              id="address"
              type="text"
              placeholder="Enter Address"
              // value={walletAddress}
              disabled={true}
            />
          </div>

          <div className='form-modal'>
            <label htmlFor="title">
              Sender Address
              <span className="required-field">*</span>
            </label>
            <input
              className="input-field"
              id="address"
              type="text"
              placeholder="Enter Address"
              // value={senderWalletAddress}
              disabled={true}
            />
          </div>

          <div className='form-modal'>
            <label htmlFor="title">
              Amount<span className="required-field">*</span>
            </label>
            <div className="">
              <input
                className="input-field"
                id="amount"
                type="number"
                placeholder="Enter Amount"
                value={20}
                disabled={true}
                // onChange={this.onChangeAmount}
              />
            </div>
          </div>

          <div className="total-amount">
            <p>
              Amount USD: <span>$ 20</span>
               {/* {niceNumberDecimalDisplay(amountUsd, 2)} */}
            </p>
          </div>
          <div className="modal-footer-btn">
            <button
              className="btn-secondary"
              type="button"
              // onClick={withdrawOnCloseModal}
            >
              Cancel
            </button>
            <button
              className="btn-primary"
              type="button"
              // onClick={this.onSubmit}
            >
              {" "}
              Pay{" "}
              {/* {this.state.paymentRequestButtonLoading && (
                <i className="fa-spin fa fa-spinner text-white m-1" />
              )} */}
            </button>
          </div>
        </div>
      </div>
      </>
      :
        <div className='dec-payment-method'>
            <div>
              <h4 className='heading'>
                DEC Payment
              </h4>
              <h6 className='desc'>
                Congratulations! you are going to have{" "}
                20
                {/* {invoiceId.invoiceId && invoiceId.invoiceId.decDiscount} */}
                % Discount
              </h6>
              <div className='form-modal'>
                <label htmlFor="title">
                  Receiving Address
                  <span className="required-field">*</span>
                </label>
                <input
                  className="input-field"
                  id="address"
                  type="text"
                  placeholder="Enter Address"
                  // value={decAddress}
                  disabled={true}
                />
              </div>

              <div className='form-modal'>
                <label htmlFor="title">
                  Amount
                  <span className="required-field">*</span>
                </label>
                <div className="">
                  <input
                    className="input-field"
                    id="amount"
                    type="text"
                    placeholder="Enter Amount"
                    // value={`$ ${niceNumberNoDecimalDisplay(
                    //   this.state.amountinUSD,
                    //   2
                    // )}`}
                    disabled={true}
                  />
                </div>
              </div>
              <div className='form-modal'>
                <label htmlFor="title">
                  Discounted Amount
                  <span className="required-field">*</span>
                </label>
                <div className="">
                  <input
                    className="input-field"
                    id="amount"
                    type="text"
                    placeholder="Enter Amount"
                    // value={`$ ${niceNumberNoDecimalDisplay(
                    //   this.state.discountAmount,
                    //   2
                    // )}`}
                    disabled={true}
                  />
                </div>
              </div>
              <div className='form-modal'>
                <label htmlFor="title">
                  DEC Tokens<span className="required-field">*</span>
                </label>
                <div>
                  <input
                    className="input-field"
                    id="amount"
                    type="number"
                    placeholder="Enter Amount"
                    // value={`${niceNumberNoDecimalDisplay(
                    //   this.state.decTokens,
                    //   0
                    // )}`}
                    disabled={true}
                  />
                </div>
              </div>
              
              <div className='form-modal'>
                <label htmlFor="title">
                  Note<span className="required-field">*</span>
                </label>
                <div className='dec-desc-note'>
                  <span>
                    <span className="text-bold"></span> Gas Fees not
                    included. You can approve or reject the Gas Fees from
                    your MetaMask account after clicking ‘Pay’. Lowest Gas
                    Fees are estimated between time 5:30 pm UTC and 11:30
                    pm UTC.
                  </span>
                </div>
              </div>
              

              <div className="modal-footer-btn">
                <button
                  className="btn-secondary"
                  type="button"
                  // onClick={() => {
                  //   onCloseDecModal();
                  // }}
                >
                  Cancel
                </button>
                <button
                  className="btn-primary"
                  type="button"
                  // onClick={() => {
                  //   onCloseDecModal();
                  //   depositOnCloseModal();
                  //   this.sendTokens(
                  //     API_CONTRACTADDRESS,
                  //     this.state.decAddress,
                  //     this.state.decTokens
                  //   );
                  // }}
                  // disabled={
                  //   this.state.decAddress &&
                  //   this.state.amountinUSD &&
                  //   this.state.discountAmount &&
                  //   this.state.decTokens
                  //     ? false
                  //     : true
                  // }
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
    }
    </>
  )
}

export default SelectPaymentModal