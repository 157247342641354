import React, { Component } from "react";
// import { toast } from "react-toastify";
import _ from "lodash";
import Api from "../../services/api";
import ChangePasswordModal from "./ChangePasswordModal";
import EditProfileModal from "./EditProfileModal";
import { confirmAlert } from "react-confirm-alert";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import { storeUserDetails } from "../../store/actions/UserAction";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import Toast from '../CustomToast/CustomToast';

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      showPhoneNumber: "",
      email: "",
      changePasswordModalOpen: false,
      editProfileModalOpen: false,
      updateEthAddressButtonLoading: false,
      changePasswordButtonLoading: false,
      editProfileButtonLoading: false,
      toTpUri: "",
      showEmail: "",
      twoFAFormattedKey: "",
      withdrawBtcAddress: "",
      withdrawEthAddress: "",
      currency: "ERC-20",
      twoFAStatus: false,
      accountId: "",
      twoFAcode: "",
      receivingEthAddress: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      currentKycStatus: "u",
      role: "",
      paymentMethods: [],
      userPaymentDetails: {},
      errorMessage: "",
    };
    this.onchange = this.onchange.bind(this);
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
    this.onChangePasswordCloseModal =
      this.onChangePasswordCloseModal.bind(this);
    this.openEditProfileModal = this.openEditProfileModal.bind(this);
    this.onEditProfileCloseModal = this.onEditProfileCloseModal.bind(this);
    this.twoFaAuthenticationChange = this.twoFaAuthenticationChange.bind(this);
    this.updateReceivingEthAddress = this.updateReceivingEthAddress.bind(this);
    this.onWhiteListAddressChange = this.onWhiteListAddressChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updateKyc = this.updateKyc.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
  }

  async getUserDetails() {
    const api = new Api();
    const { storeUserDetails } = this.props;
    try {
      const response = await api.create("user/getUserDetails", { twoFA: true });
      if (response.code === 200) {
        const userDetails = response.data;
        console.log(userDetails, "userDetails");
        storeUserDetails(userDetails);
        this.setState({
          accountId: userDetails.binanceAccountNumber,
          twoFAFormattedKey: userDetails.twoFAFormattedKey,
          twoFAStatus: userDetails.twoFAStatus,
          toTpUri: userDetails.toTpUri,
          withdrawBtcAddress: userDetails.withdrawBtcAddress,
          withdrawEthAddress: userDetails.withdrawEthAddress,
          receivingEthAddress:
            this.state.currency === "ERC-20"
              ? userDetails.withdrawEthAddress
              : userDetails.withdrawBtcAddress,
          fullName: userDetails.fullName,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          showEmail: userDetails.email,
          phoneNumber: userDetails.phoneNumber,
          showPhoneNumber: userDetails.phoneNumber,
          role: userDetails.role,
        });
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
      pageProgress("remove");
    } catch (err) {
      console.log(err);
      pageProgress("force_remove");
      Toast({
        type:'error',
        title: 'Error',
        message:err.message,
      });
      // toast.error(err.message);
    }
  }

  async componentWillMount() {
    document.title =
      messageConstants.MY_ACCOUNT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    this.getUserDetails();
  }

  async updateRejectKycStatus(userId, type) {
    const api = new Api();
    const response = await api.create("user/kyc_status/update/reject");
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        this.props.history.push("/kyc");
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
    }
  }

  updateKyc() {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Cancel",
      },
      {
        label: "Yes",
        onClick: () => _self.updateRejectKycStatus(),
      },
    ];

    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure want to resubmit KYC?",
      buttons: buttons,
    });
  }

  async twoFaAuthenticationChange(type = "enable") {
    let { twoFAcode } = this.state;
    let authUserInfo = this.props.authUserInfo;
    let twoFAStatus = type === "disable" ? false : true;
    if (
      !_.isUndefined(twoFAcode) &&
      authUserInfo.email !== "" &&
      twoFAcode !== ""
    ) {
      const api = new Api();
      const response = await api.create("user/verify2FA", {
        email: authUserInfo.email,
        code: twoFAcode,
        status: twoFAStatus,
      });
      if (response.code === 200) {
        this.setState({
          twoFAcode: "",
          twoFAStatus: response.data.twoFAStatus,
          toTpUri: response.data.toTpUri,
        });
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
    } else {
      Toast({
        type:'error',
        title: 'Error',
        message:messageConstants.ERROR_MSG_VALID_TWO_FA_CODE,
      });
      // toast.error(messageConstants.ERROR_MSG_VALID_TWO_FA_CODE);
    }
  }

  async updateReceivingEthAddress() {
    let { receivingEthAddress, currency } = this.state;
    if (!_.isUndefined(receivingEthAddress) && receivingEthAddress !== "") {
      let response;
      const api = new Api();
      if (currency === "BTC") {
        response = await api.create("user/checkBtcAddress", {
          btcAddress: receivingEthAddress,
        });
        this.getUserDetails();
      }
      if (currency === "ERC-20") {
        response = await api.create("user/checkEthAddress", {
          ethAddress: receivingEthAddress,
        });
        this.getUserDetails();
      }

      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:response.message,
        });
        // toast.error(response.message);
      }
    } else {
      Toast({
        type:'error',
        title: 'Error',
        message:messageConstants.ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS,
      });
      // toast.error(messageConstants.ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS);
    }
  }

  async updatePassword() {
    console.log("update password");
    let { oldPassword, newPassword, confirmNewPassword } = this.state;
    if (
      !_.isUndefined(oldPassword) &&
      oldPassword !== "" &&
      newPassword !== "" &&
      newPassword === confirmNewPassword
    ) {
      this.setState({ changePasswordButtonLoading: true });
      const api = new Api();
      const response = await api.create("user/changePassword", {
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      this.setState({ changePasswordButtonLoading: false });
      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
        this.onChangePasswordCloseModal();
      } else {
        this.setState({ errorMessage: response.message });
      }
    } else {
      let msg =
        newPassword !== confirmNewPassword
          ? "Passworda doea not matched."
          : messageConstants.REQUIRED_FIELDS_VALID_MSG;
      // toast.error(msg);

      this.setState({ errorMessage: msg });
    }
  }

  async updateProfile() {
    let { fullName, firstName, lastName, phoneNumber, email } = this.state;
    if (
      !_.isUndefined(fullName) &&
      fullName !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      phoneNumber !== "" &&
      email !== "" &&
      !_.isUndefined(email) &&
      !_.isUndefined(phoneNumber)
    ) {
      this.setState({ editProfileButtonLoading: true });
      const api = new Api();
      const response = await api.create("user/profile/edit", {
        fullName: fullName,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email,
      });
      this.setState({ editProfileButtonLoading: false });
      if (response.code === 200) {
        Toast({
          type:'success',
          title: 'Success',
          message:response.message,
        });
        // toast.success(response.message);
        this.onEditProfileCloseModal();
        this.getUserDetails();
      } else {
        this.setState({ errorMessage: response.message });
        this.getUserDetails();
      }
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      this.setState({ errorMessage: msg });
    }
  }

  async onWhiteListAddressChange(event) {
    this.setState({ currency: event.target.value }, () => {
      if (this.state.currency === "BTC") {
        this.setState({
          receivingEthAddress: this.state.withdrawBtcAddress,
        });
      }
      if (this.state.currency === "ERC-20") {
        this.setState({
          receivingEthAddress: this.state.withdrawEthAddress,
        });
      }
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openChangePasswordModal() {
    this.setState({ changePasswordModalOpen: true });
  }

  onChangePasswordCloseModal() {
    this.setState({ changePasswordModalOpen: false });
  }

  openEditProfileModal() {
    this.setState({ editProfileModalOpen: true });
  }

  onEditProfileCloseModal() {
    this.setState({ editProfileModalOpen: false });
  }

  render() {
    let {
      toTpUri,
      twoFAStatus,
      twoFAFormattedKey,
      twoFAcode,
      fullName,
      lastName,
      firstName,
      showEmail,
      showPhoneNumber,
      receivingEthAddress,
      accountId,
      updateEthAddressButtonLoading,
      currentKycStatus,
    } = this.state;
    let qrCodeImg = "";
    let twofaButtonClassName = "btn-secondary";
    if (!twoFAStatus && toTpUri !== "") {
      qrCodeImg =
        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=" +
        toTpUri;
    }
    let twofaLabel = "Disabled";
    let twofaClassName = "badge-danger-disabled";
    let buttonLabel = "Enable 2FA";
    let buttonType = "enable";
    if (twoFAStatus === true) {
      twofaLabel = "Enabled";
      twofaClassName = "badge-success";
      buttonLabel = "Disable 2FA";
      buttonType = "disable";
      twofaButtonClassName = "btn-danger";
    }
    let kycFontClass = "";
    let kycLabel = "No KYC Submitted";
    let kycDescription = "You do not have submitted a KYC.";
    if (currentKycStatus === "u") {
      kycFontClass = "fa-eye";
      kycLabel = "Under Review";
      kycDescription =
        "Your KYC is under review. We will notify you once it approves.";
    } else if (currentKycStatus === "r") {
      kycFontClass = "fa-times";
      kycLabel = "Rejected";
      kycDescription = "Your KYC is rejected. Please submit it again.";
    } else if (currentKycStatus === "a") {
      kycFontClass = "fa-check";
      kycLabel = "Verified";
      kycDescription =
        "Your KYC is already verified. You can update if there any changes.";
    }
    let updateEthAddressButtonDisabled =
      updateEthAddressButtonLoading === true ? true : false;
    return (
      <div className="my-account-container acc-container">
        <div className="title-account">
          <h2>My Account</h2>
          <p>Account Management and Wallet Linking</p>
        </div>
        <div className="content-i">
          <div className="">
            <div className="row row-eq-height">
              <div className="col-lg-6">
                <div className="dark-cards">
                  <span className="color-white-div">Account Information</span>

                  <div className="read-field">
                    <div className="title">Account ID</div>

                    <div className="bg-text">
                      <p>{accountId ? accountId : "N/A"}</p>
                    </div>
                  </div>
                  <div className="read-field">
                    <div className="title">First name</div>

                    <div className="bg-text">
                      <p>{fullName}</p>
                    </div>
                  </div>
                  <div className="read-field">
                    <div className="title">Last name</div>

                    <div className="bg-text">
                      <p>{fullName}</p>
                    </div>
                  </div>
                  <div className="read-field">
                    <div className="title">Email</div>

                    <div className="bg-text">
                      <p>
                        {" "}
                        {!_.isUndefined(showEmail) &&
                        !_.isEmpty(showEmail) &&
                        !_.isNull(showEmail)
                          ? showEmail
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="read-field">
                    <div className="title">Phone</div>

                    <div className="bg-text">
                      <p>
                        {" "}
                        {!_.isUndefined(showPhoneNumber) &&
                        !_.isEmpty(showPhoneNumber) &&
                        !_.isNull(showPhoneNumber)
                          ? showPhoneNumber
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="dark-cards">
                    <div className="head-dark">
                      <span className="color-white-div">
                        2 Factor Authentication
                      </span>
                      <span className="pull-right">
                        <label className={"badge " + twofaClassName}>
                          {twofaLabel}
                        </label>
                      </span>
                    </div>
                    <div className="normal-text">
                      Two-factor authentication is a method for protection your
                      web account. When it is activated you need to enter not
                      only your password, but also a special code.
                    </div>
                    <div className="QrRow mt-4 ">
                      <div className="text-center">
                        {/* {qrCodeImg !== "" && (
                            <div className=" mr-3 mb-4">
                              <img alt="" src={qrCodeImg} height="170" />
                            </div>
                          )} */}
                      </div>
                      <div className="">
                        {!twoFAStatus && (
                          <div className="">
                            <div className="two-factor-heading-text text-style dark-box">
                              <p>
                                Open up your 2FA mobile app and scan the
                                following QR barcode. If your 2FA mobile app
                                does not support QR barcodes, enter in the
                                following number:
                              </p>
                              <h5 className=" ">{twoFAFormattedKey}</h5>
                            </div>
                          </div>
                        )}
                        <div className="end-section mt-4">
                          <label className="mb-2 ml-2 mr-2">2FA Code</label>
                          <div className="field-value-section">
                            <div className="input-with-select">
                              <input
                                className="form-control mr-2"
                                type="text"
                                name="twoFAcode"
                                id="twoFAcode"
                                placeholder="Enter 2FA code"
                                onChange={this.onchange}
                                value={twoFAcode}
                              />
                            </div>
                            <div className='enable-2fa-btn'>
                              <button
                                className={twofaButtonClassName}
                                onClick={() =>
                                  this.twoFaAuthenticationChange(buttonType)
                                }
                              >
                                {buttonLabel}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="col-sm-12 col-md-12 col-lg-12 mgtp-sm-4 mgtp-md-4 mgtp-xs-4">
                  <div className="dark-cards">
                    <span className="color-white-div">
                      {" "}
                      Whitelist Your Wallet
                    </span>
                    <div className="mt-3 text-style">
                      To receive rewards and make withdrawals make sure to
                      insert your BTC and ERC20 wallet addresses for
                      whitelisting.
                    </div>
                    <div className="mt-3">
                      {/* <div className="myaccount-whitelist-container">
                        <input
                          className="form-control receiving-eth-address"
                          placeholder={`Receiving wallet address`}
                          type="text"
                          name="receivingEthAddress"
                          id="receivingEthAddress"
                          value={receivingEthAddress}
                          onChange={this.onchange}
                        />
                        <select
                          className="whitelist-select"
                          id="whitelistAddress"
                          name="whitelistAddress"
                          defaultValue="ERC-20"
                          onChange={this.onWhiteListAddressChange}
                        >
                          <option value="ERC-20">ERC20</option>
                          <option value="BTC">BTC</option>
                        </select>
                      </div> */}

                      <h5 className="text-mining mb-3">
                        Budget
                        <i class="fa-light fa-circle-question"></i>
                        <i
                          className="fa fa-info-circle ml-1"
                          id="budgetTooltip"
                          title="Budget"
                        ></i>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="bottom"
                          target="budgetTooltip"
                        >
                          <PopoverBody>
                            The budget field is used if you wish to calculate
                            profits based on total capital instead of total
                            hashrate. The budget is the amount used to purchase
                            a number of application specific integrated circuits
                            (ASICs) for Bitcoin mining.
                          </PopoverBody>
                        </UncontrolledPopover>
                      </h5>

                      <div className="alignment">
                        <div className="input-with-select">
                          <input
                            // className="input-field"
                            type="text"
                            id="receivingEthAddress"
                            name="receivingEthAddress"
                            onChange={this.onchange}
                            value={receivingEthAddress}
                          />
                          <div className="sub-field">USD</div>
                        </div>

                        <div className="text-center update-btn d-flex">
                          <button
                            className="btn-secondary"
                            onClick={this.updateReceivingEthAddress}
                            disabled={updateEthAddressButtonDisabled}
                          >
                            Update{" "}
                            {updateEthAddressButtonLoading && (
                              <i className="fa-spin fa fa-spinner text-white m-1" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mgtp-sm-4 mgtp-md-4 mgtp-xs-4">
                  <div className=" dark-cards">
                    <div>
                      <span className="color-white-div">KYC</span>
                      <div className="mt-3 text-center">
                        <span className="verified-text">{kycLabel}</span>
                      </div>
                      <div className="mt-3 text-center text-style">
                        <span>{kycDescription}</span>
                      </div>
                    </div>
                    <div className="mt-3 text-center d-flex">
                      {(!currentKycStatus || currentKycStatus === "p") && (
                        <button
                          className="btn standardColor account-button my-account-button"
                          type="button"
                          onClick={() => this.props.history.push("/kyc")}
                        >
                          Submit KYC
                        </button>
                      )}
                      {(currentKycStatus === "a" ||
                        currentKycStatus === "r") && (
                        <button
                          className="btn standardColor account-button my-account-button"
                          type="button"
                          onClick={() => this.updateKyc()}
                        >
                          Update KYC
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="dark-cards dark-blue-theme-color my-account-header-block h-100 myaccount_card">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="color-white-div">Password</span>
                        <button
                          className="btn-secondary"
                          onClick={() => this.openEditProfileModal()}
                        >
                          Edit
                        </button>
                      </div>

                      {/* <div className="mt-1 text-style">
                        Account ID :{" "}
                        <span className="pl-1">
                          {accountId ? accountId : "N/A"}
                        </span>
                      </div>
                      <div className="mt-1 text-style">
                        Name : <span className="pl-1">{fullName}</span>
                      </div>
                      <div className="mt-2 text-style">
                        Email :{" "}
                        <span className="pl-1">
                          {!_.isUndefined(showEmail) &&
                          !_.isEmpty(showEmail) &&
                          !_.isNull(showEmail)
                            ? showEmail
                            : "N/A"}
                        </span>
                      </div>
                      <div className="mt-2 text-style">
                        Phone :{" "}
                        <span className="pl-1">
                          {!_.isUndefined(showPhoneNumber) &&
                          !_.isEmpty(showPhoneNumber) &&
                          !_.isNull(showPhoneNumber)
                            ? showPhoneNumber
                            : "N/A"}
                        </span>
                      </div> */}

                      <span className="text-style">
                        If you need to change your password, please use this
                        function
                      </span>
                    </div>
                    <div className="mt-3 text-center d-flex">
                      <button
                        className="btn-secondary"
                        onClick={() => this.openChangePasswordModal()}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordModal
          {...this.props}
          {...this.state}
          onInputValueChange={this.onchange}
          isFormValid={this.isFormValid}
          displayValidationErrors={this.displayValidationErrors}
          onCloseModal={this.onChangePasswordCloseModal}
          updatePassword={this.updatePassword}
          errorMessage={this.state.errorMessage}
        />
        <EditProfileModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onEditProfileCloseModal}
          onInputValueChange={this.onchange}
          updateProfile={this.updateProfile}
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  storeUserDetails: (user) => dispatch(storeUserDetails(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
