import _ from "lodash";
import axios from "axios";
import { API_ROOT, API_NETWORK } from "../services/api-config";
// import { toast } from "react-toastify";
import { ethers } from "ethers";
import Web3 from "web3";
import Toast from '../components/CustomToast/CustomToast';

export const niceNumberDisplay = (val) => {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, "$1,$2");
  }
  return val;
};

export const niceNumberNoDecimalDisplay = (value, decimalPoint = 3) => {
  if (!value) return 0;
  const niceNumber = parseFloat(value)
    .toFixed(decimalPoint)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return Number.isNaN(niceNumber) ? 0 : niceNumber;
};

export const niceNumberDecimalDisplay = (value, decimalPoint = 3) => {
  if (!value) return 0;
  let niceNumber = parseFloat(value);
  if (decimalPoint > 0) {
    niceNumber =
      niceNumber > 0
        ? niceNumber.toFixed(decimalPoint).replace(/\d(?=(\d{3})+\.)/g, "$&,")
        : 0;
  } else {
    niceNumber =
      niceNumber > 0
        ? niceNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0;
  }
  return Number.isNaN(niceNumber) ? 0 : niceNumber;
};

export const connect = async () => {
  await window.ethereum.enable();
  window.web3 = new Web3(window.ethereum);
  window.web3.eth.requestAccounts().then(console.log(""));
};
export const sendTokens = async (
  tokenContractAddress,
  whitelistedAddress = "",
  tokenValue,
  decimalPoints = 18
) => {
  try {
    if (tokenContractAddress && whitelistedAddress && tokenValue > 0) {
      let finalTokenValue = Math.ceil(Number(tokenValue));
      finalTokenValue = finalTokenValue * Math.pow(10, decimalPoints);
      finalTokenValue = await toPlainString(finalTokenValue);
      const signer = new ethers.providers.Web3Provider(
        window.ethereum
      ).getSigner();
      const contractABI = await getABIOfContact(tokenContractAddress);
      if (contractABI) {
        const contract = new ethers.Contract(
          tokenContractAddress,
          contractABI,
          signer
        );
        const stakeResponse = await contract.transfer(
          whitelistedAddress,
          finalTokenValue
        );
        if (stakeResponse.txHash) {
          Toast({
            type:'success',
            title: 'Success',
            message:`Token issued successfully.`
          });
          // toast.success(`Token issued successfully.`);
        }
        return stakeResponse;
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:`Unable to process for whitelisting transaction.`
        });
        // toast.error(`Unable to process for whitelisting transaction.`);
      }
    }
  } catch (error) {
    const errorMessage = error.message;
    Toast({
      type:'error',
      title: 'Error',
      message:errorMessage
    });
    // toast.error(errorMessage);
  }
};

const getABIOfContact = async (contractAddress) => {
  try {
    const url = `${API_NETWORK}?module=contract&action=getabi&address=${contractAddress}&apikey=${"APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V"}`;
    const abiData = await axios.get(url);
    if (abiData.status === 200) {
      return abiData.data.result;
    }
  } catch (error) {
    console.error("error: ", error);
  }
};

export const tooltipNumberDisplay = (value, symbol, action = "append") => {
  let finalNumber = "";
  if (!_.isUndefined(value) && !_.isNull(value)) {
    let niceNumber = parseFloat(value);
    let numberAsString = value.toString();
    if (
      numberAsString.indexOf(".") === -1 ||
      numberAsString.split(".")[1].length < 3
    ) {
      niceNumber =
        niceNumber > 0
          ? niceNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : 0;
    } else {
      niceNumber =
        niceNumber > 0 ? numberAsString.replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0;
    }
    if (!_.isUndefined(symbol)) {
      finalNumber =
        action === "append"
          ? niceNumber + " " + symbol
          : symbol + " " + niceNumber;
    } else {
      finalNumber = niceNumber;
    }
  } else {
    finalNumber = 0;
  }
  return finalNumber;
};

export const ethAddressDisplay = (ethAddress) => {
  let ETHAddressDisplay = "";
  if (!_.isUndefined(ethAddress)) {
    ETHAddressDisplay = ethAddress.substr(0, 6) + "..." + ethAddress.substr(-4);
  }
  return ETHAddressDisplay;
};

export const parseTextInputToNumeric = (number) => {
  if (number) {
    if (number.indexOf(",") !== -1) {
      number = number.replace(/,/g, "");
    }
    number = parseFloat(number);
  }
  return number;
};

export const hashrateGraphConverter = (number, convertIn) => {
  let returnHash = 0;
  const graphObject = {
    "EH/s": () => niceNumberDecimalDisplay(number / 1000000000000000000, 2),
    "PH/s": () => niceNumberDecimalDisplay(number / 1000000000000000, 2),
    "TH/s": () => niceNumberDecimalDisplay(number / 1000000000000, 2),
    "GH/s": () => niceNumberDecimalDisplay(number / 1000000000, 2),
    "MH/s": () => niceNumberDecimalDisplay(number / 1000000, 2),
    "KH/s": () => niceNumberDecimalDisplay(number / 1000, 2),
    "H/s": () => niceNumberDecimalDisplay(number, 2),
  };
  const unitArr = Object.getOwnPropertyNames(graphObject);
  if (unitArr.includes(convertIn)) {
    returnHash = graphObject[`${convertIn}`]();
  }
  return returnHash;
};

export const hashrateConverter = (
  number,
  onlyNumber = false,
  returnInPetaHash = false
) => {
  if (!number) return 0;
  if (number < 1000) {
    return `${number} H/s`;
  } else {
    let kiloHash = number / 1000;
    let megaHash = number / 1000000;
    let gigahash = number / 1000000000;
    let teraHash = number / 1000000000000;
    let petaHash = number / 1000000000000000;
    let exahash = number / 1000000000000000000;

    if (returnInPetaHash === true) {
      petaHash = niceNumberDecimalDisplay(petaHash, 2);
      return onlyNumber ? petaHash : `${petaHash} PH/s`;
    }

    if (kiloHash < 1000) {
      kiloHash = niceNumberDecimalDisplay(kiloHash, 2);
      return onlyNumber ? kiloHash : `${kiloHash} KH/s`;
    }
    if (megaHash < 1000) {
      megaHash = niceNumberDecimalDisplay(megaHash, 2);
      return onlyNumber ? megaHash : `${megaHash} MH/s`;
    }
    if (gigahash < 1000) {
      gigahash = niceNumberDecimalDisplay(gigahash, 2);
      return onlyNumber ? gigahash : `${gigahash} GH/s`;
    }
    if (teraHash < 1000) {
      teraHash = niceNumberDecimalDisplay(teraHash, 2);
      return onlyNumber ? teraHash : `${teraHash} TH/s`;
    }
    if (petaHash < 1000) {
      petaHash = niceNumberDecimalDisplay(petaHash, 2);
      return onlyNumber ? petaHash : `${petaHash} PH/s`;
    }
    if (exahash < 1000) {
      exahash = niceNumberDecimalDisplay(exahash, 2);
      return onlyNumber ? exahash : `${exahash} EH/s`;
    }
    return number;
  }
};

export const getBtcUsd = async (number) => {
  const geminiPricingResponse = await axios.get(
    `${API_ROOT}/geminiPricing.json`
  );
  let priceUsd = "";
  if (geminiPricingResponse) {
    const parseJson = geminiPricingResponse.data;
    if (parseJson && parseJson["btc"]) {
      priceUsd = parseJson["btc"];
    }
  }
  if (!geminiPricingResponse || !priceUsd) {
    const response = await axios.get(
      `https://api.gemini.com/v1/pubticker/btcusd`
    );
    if (response) {
      const priceDetails = response.data;
      priceUsd = priceDetails.last;
    }
  }
  return priceUsd;
};

export const getEthUsd = async (number) => {
  const geminiPricingResponse = await axios.get(
    `${API_ROOT}/geminiPricing.json`
  );
  let priceUsd = "";
  if (geminiPricingResponse) {
    const parseJson = geminiPricingResponse.data;
    if (parseJson && parseJson["eth"]) {
      priceUsd = parseJson["eth"];
    }
  }
  if (!geminiPricingResponse || !priceUsd) {
    const response = await axios.get(
      `https://api.gemini.com/v1/pubticker/btcusd`
    );
    if (response) {
      const priceDetails = response.data;
      priceUsd = priceDetails.last;
    }
  }
  return priceUsd;
};

const toPlainString = async (num) => {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
};

export const pageProgress = (action) => {
  console.log(`pageProgress:${action}`);
  const body = document.body;
  if (action === "remove") {
    body.classList.add("page-loaded");
  } else if (action === "force_remove") {
    setTimeout(() => {
      body.classList.add("page-loaded");
    }, 5000);
  } else {
    body.classList.remove("page-loaded");
  }
};

export const upgradeAlert = (email) => {
  if (!email) return false;

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const lastDate = date.getDate();

  const emails = [
    "edelmann.dubai@hotmail.com",
    "daniel.luntadi@gmail.com",
    "nik31273@gmail.com",
    "fghorayeb@hotmail.com",
    "miner@permianchain.com",
  ];

  if (emails.includes(email) && year === 2022 && month === 0 && lastDate <= 31)
    return true;
  return false;
};
