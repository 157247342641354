import React, { useState, useEffect } from "react";
import axios from "axios";
// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { PRICE_KEY, API_ROOT } from "../../services/api-config";
import CreditCardInput from "react-credit-card-input";
// stripe
import Select from "react-select";
import {
  useStripe,
  useElements,
  CardElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
// Util imports
import { makeStyles } from "@material-ui/core/styles";
// Custom Components
import CardInput from "./CardInput";
// import { toast } from "react-toastify";
import Toast from '../CustomToast/CustomToast';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: "0vh auto",
    boxShadow: "0px 0px 16px rgb(0 0 0 / 29%)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
});

const HomePage = ({
  authToken,
  userEmail,
  userline1,
  onNewPayment,
  userstate,
  userCity,
  totalWorkers,
  onSubscribe,
  newPayment,
  optionRef,
  onMenuOpen,
  handleCountryChange,
  userCountryIdValue,
  usercountry,
  countriesList,
  subscribeUnitAmount,
  getRecords,
  userName1,
  userPostalCode,
}) => {
  const [email, setEmail] = useState(userEmail);
  const [city, setCity] = useState(userCity);
  const [amount, setAmount] = useState("");
  const [line1, setLine1] = useState(userline1);
  const [country, setCountry] = useState(usercountry);
  const [countryIdValue, setCountryIdValue] = useState(userCountryIdValue);
  const [state, setState] = useState(userstate);
  const [userName, setUserName] = useState(userName1);
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [postCode, setPostCode] = useState(userPostalCode);
  const [qty, setQty] = useState(totalWorkers);
  const [payLoading, setPayLoading] = useState(false);
  const [changeCardLoading, setChangeCardLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    setEmail(userEmail);
    setUserName(userName1);
    setLine1(userline1);
    setCountry(usercountry);
    setQty(totalWorkers);
    setCountryIdValue(userCountryIdValue);
    setState(userstate);
    setCity(userCity);
    setPostCode(userPostalCode);
  }, [
    userEmail,
    userCity,
    userline1,
    userstate,
    usercountry,
    userCountryIdValue,
    userName1,
    totalWorkers,
    userPostalCode,
  ]);
  useEffect(() => {
    setAmount(qty * subscribeUnitAmount);
  }, [qty, subscribeUnitAmount]);

  const classes = useStyles();
  axios.defaults.headers.common["Authorization"] = authToken;
  const handleSubmitPay = async (event) => {
    try {
      setPayLoading(true);

      if (qty > 0 && amount > 0) {
        let api = `${API_ROOT}/miner/subscribe`;
        if (!stripe || !elements) {
          return;
        }
        var result = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          cardNumber: elements.getElement(CardExpiryElement),
          billing_details: {
            email: email,
            name: userName,
            address: {
              country: countryIdValue,
              city: city,
              state: state,
              line1: line1,
            },
          },
        });
        const postalCode =
          result &&
          result.paymentMethod &&
          result.paymentMethod.billing_details &&
          result.paymentMethod.billing_details.address &&
          result.paymentMethod.billing_details.address.postal_code;
        const exp_month =
          result &&
          result.paymentMethod &&
          result.paymentMethod.card &&
          result.paymentMethod.card.exp_month;
        const exp_year =
          result &&
          result.paymentMethod &&
          result.paymentMethod.card &&
          result.paymentMethod.card.exp_year;

        if (result.error) {
          console.log(result.error.message);
          setPayLoading(false);
          Toast({
            type:'error',
            title: 'Error',
            message:result.error.message,
          });
          // toast.error(result.error.message);
        } else if (
          !result.error &&
          result.paymentMethod.id &&
          email &&
          userName &&
          line1 &&
          countryIdValue &&
          state &&
          city &&
          exp_month &&
          exp_year &&
          PRICE_KEY &&
          qty &&
          amount
        ) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken,
            },
          };
          const res = await axios.post(
            api,
            {
              payment_method: result.paymentMethod.id,
              email: email,
              userName: userName,
              line1: line1,
              country: countryIdValue,
              city: city,
              exp_month: exp_month,
              exp_year: exp_year,
              state: state,
              qty: qty,
              price: PRICE_KEY,
              amount: amount,
              postalCode: postalCode,
            },
            config
          );
          onSubscribe();
          setPayLoading(false);

          if (res.status === 200) {
            Toast({
              type:'success',
              title: 'Success',
              message:res.data.message,
            });
            // toast.success(res.data.message);
            getRecords();
          } else {
            Toast({
              type:'error',
              title: 'error',
              message:res.data.message,
            });
            // toast.error(res.data.message);
          }
        } else {
          Toast({
            type:'error',
            title: 'error',
            message:"Please fill all the details!",
          });
          // toast.error("Please fill all the details!");
          setPayLoading(false);
        }
      } else {
        Toast({
          type:'error',
          title: 'error',
          message:"Please make sure quantity or amount is greater than zero!",
        });
        // toast.error(
        //   "Please make sure quantity or amount is greater than zero!"
        // );
        setPayLoading(false);
      }
    } catch (err) {
      setPayLoading(false);
      Toast({
        type:'error',
        title: 'error',
        message:"Failed to pay susbscription!",
      });
      // toast.error("Failed to pay susbscription!");
      console.log(err);
    }
  };

  const handleChangeCard = async () => {
    let month = "";
    let year = "";
    try {
      setChangeCardLoading(true);
      let expiryArr = expiry.split("/");
      month = expiryArr[0] ? expiryArr[0].trim() : "";
      year = expiryArr[1] ? expiryArr[1].trim() : "";
      if (
        email &&
        userName &&
        line1 &&
        countryIdValue &&
        month &&
        cvc &&
        year &&
        city &&
        cardNumber &&
        state
      ) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        };
        const res = await axios.post(
          `${API_ROOT}/miner/createPayment`,
          {
            email: email,
            userName: userName,
            line1: line1,
            country: countryIdValue,
            expi_month: month,
            type: "subscribe",
            cvcReq: cvc,
            expi_year: year,
            city: city,
            num: cardNumber,
            state: state,
            postal_code: postCode,
          },
          config
        );
        setChangeCardLoading(false);

        if (res.status === 200) {
          Toast({
            type:'success',
            title: 'Success',
            message:res.data.message,
          });
          // toast.success(res.data.message);
          onSubscribe();
          onNewPayment();
        } else {
          Toast({
            type:'error',
            title: 'Error',
            message:res.data.message,
          });
          // toast.error(res.data.message);
        }
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:"Please fill all the details!",
        });
        // toast.error("Please fill all the details!");
        setChangeCardLoading(false);
      }
    } catch (e) {
      console.log(e);
      if (cvc || year || month || cardNumber) {
        Toast({
          type:'error',
          title: 'Error',
          message:"Please fill the card details properly!",
        });
        // toast.error("Please fill the card details properly!");
      } else {
        Toast({
          type:'error',
          title: 'Error',
          message:"Please fill the card details!",
        });
        // toast.error("Please fill the card details!");
      }
      setChangeCardLoading(false);
    }
  };
  return (
    <>
      <Card className={classes.root + " budget-background"}>
        <CardContent className={classes.content}>
          <input
            placeholder="Email"
            id="outlined-email-input"
            className="mui-field mt-3"
            margin="normal"
            variant="outlined"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <input
            placeholder="Address"
            className="mui-field mt-3"
            margin="normal"
            variant="outlined"
            type="text"
            required
            value={line1}
            onChange={(e) => setLine1(e.target.value)}
            fullWidth
          />
          <Select
            autoFocus={false}
            className="country-select3 mt-3"
            classNamePrefix="contry"
            options={countriesList}
            placeholder="Select Country"
            name="countryId"
            id="country"
            ref={optionRef}
            onChange={handleCountryChange}
            value={{
              label: country,
              value: countryIdValue,
            }}
            onMenuOpen={onMenuOpen}
          />
          <input
            className="mui-field mt-3"
            placeholder="City"
            margin="normal"
            variant="outlined"
            type="text"
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
          />
          <input
            placeholder="State"
            className="mui-field mt-3"
            margin="normal"
            variant="outlined"
            type="text"
            required
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
          />
          {!newPayment && (
            <input
              placeholder="Quantity"
              className="mui-field mt-3"
              margin="normal"
              variant="outlined"
              type="number"
              required
              value={qty}
              onChange={(e) => {
                if (e.target.value > totalWorkers) {
                  setQty(totalWorkers);
                  Toast({
                    type:'error',
                    title: 'Error',
                    message:"Quantity is exceeding the total workers you have!",
                  });
                  // toast.error(
                  //   "Quantity is exceeding the total workers you have!"
                  // );
                } else if (e.target.value <= totalWorkers) {
                  setQty(e.target.value);
                }
              }}
              fullWidth
            />
          )}
          {!newPayment && (
            <>
              <div className="form-group">
                <input
                  className="form-control mui-field w-100 mt-3"
                  value={`$ ${amount}`}
                  style={{ padding: "15px 10px", fontSize: "16px" }}
                  disabled={true}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
            </>
          )}
          {newPayment && (
            <input
              className="mui-field mt-3"
              placeholder="Postal Code"
              margin="normal"
              variant="outlined"
              type="text"
              required
              value={postCode}
              onChange={(e) => {
                setPostCode(e.target.value);
              }}
              fullWidth
            />
          )}
          {newPayment && (
            <CreditCardInput
              containerClassName="fieldContainer1 mt-2"
              cardNumberInputProps={{
                value: cardNumber,
                onError: (err) => {
                  setCardNumber("");
                },
                onChange: (e) => {
                  setCardNumber(e.target.value);
                },
              }}
              cardExpiryInputProps={{
                value: expiry,
                onError: (err) => {
                  setExpiry("");
                },
                onChange: (e) => {
                  setExpiry(e.target.value);
                },
              }}
              cardCVCInputProps={{
                value: cvc,
                onError: (err) => {
                  setCvc("");
                },
                onChange: (e) => setCvc(e.target.value),
              }}
              fieldClassName="input"
            />
          )}
          {!newPayment && <CardInput />}
          {!newPayment && (
            <div className="row align-items-start justify-content-center">
              <button
                className="btn btn-primary mt-2"
                onClick={handleSubmitPay}
                disabled={payLoading}
              >
                Pay
                {payLoading && (
                  <i className="fa-spin fa fa-spinner text-white ml-2" />
                )}
              </button>
            </div>
          )}
          {newPayment && (
            <div className="row align-items-start justify-content-center">
              <button
                className="btn btn-primary mt-2"
                onClick={handleChangeCard}
              >
                Change
                {changeCardLoading && (
                  <i className="fa-spin fa fa-spinner text-white ml-2" />
                )}
              </button>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default HomePage;
