import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import overlayFactory from "react-bootstrap-table2-overlay";
import { niceNumberNoDecimalDisplay } from "../../utils/Util";
import { confirmAlert } from "react-confirm-alert";
import DepositModal from "./DepositModal";
// import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import "./modals.css";
import SubscribedTooltip from "./SubscribedTooltip";
import NotSubscribedTooltip from "./NotSubscribedTooltip";
import TokensIssuedTooltip from "./TokensIssuedTooltip";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import Toast from '../CustomToast/CustomToast';

let api;

class Invoices extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      earningsLoading: true,
      workerTotalSize: "",
      earningsData: [],
      tableLoading: true,
      monthData: [],
      invoiceSizePerPage: 10,
      page: 1,
      invoiceLoading: false,
      open: false,
      invoiceId: "",
      year: "",
      month: "",
      selectedMonth: "",
      yearData: [{ value: "2021", label: "2021" }],
      selectedYear: "",
      withdrawModalOpen: false,
      earningsRenderFlag: false,
      depositModalOpen: false,
      invoiceTotalSize: "",
    };
    pageProgress("force_remove");
    this.copyWhiteListAddress = this.copyWhiteListAddress.bind(this);
    this.withdrawShowModal = this.withdrawShowModal.bind(this);
    this.depositShowModal = this.depositShowModal.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onCloseModaldeposit = this.onCloseModaldeposit.bind(this);
    this.getNote = this.getNote.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearRecords = this.clearRecords.bind(this);
    this.generateInvoiceNumber = this.generateInvoiceNumber.bind(this);
  }

  filterRecords(month, year) {
    pageProgress("remove");
  }

  setInvoiceLoading = () => {
    this.setState({ invoiceLoading: true });
  };
  filterRecords = (month, year) => {
    this.setState(
      {
        year: year ? year.value : "",
        month: month ? month.value : "",
        invoiceLoading: true,
      },
      () => {
        this.getRecords();
      }
    );
  };
  clearRecords = () => {
    this.setState(
      {
        year: "",
        month: "",
        selectedMonth: "",
        selectedYear: "",
        invoiceLoading: true,
        page: 1,
      },
      () => {
        this.getRecords();
      }
    );
  };

  handleMonthChange = (selectedMonth) => {
    this.setState({
      selectedMonth,
    });
  };
  handleYearChange = (selectedYear) => {
    this.setState({
      selectedYear,
    });
  };
  depositShowModal = (value) => {
    this.setState({
      depositModalOpen: true,
      invoiceId: value,
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.invoiceSizePerPage !== sizePerPage ||
      this.state.page !== page
    ) {
      this.setState(
        { invoiceSizePerPage: sizePerPage, page: page, invoiceLoading: true },
        () => {
          this.getRecords();
        }
      );
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  getNote = (note) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h1 className="">Note :</h1>
              <p>{note}</p>
              <div className="react-confirm-alert-button-group">
                <button
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  onCloseModaldeposit = () => {
    this.setState({ depositModalOpen: false });
  };
  copyWhiteListAddress = () => {
    let msg = "Address successfully copied!";
    Toast({
      type:'success',
      title: 'Success',
      message: msg,
    });
    // toast.success(msg);
  };
  withdrawShowModal = () => {
    this.setState({
      withdrawModalOpen: true,
    });
  };
  componentDidMount() {
    document.title =
      "Invoices" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.setState({
      monthData: [
        { value: "1", label: "Jan" },
        { value: "2", label: "Feb" },
        { value: "3", label: "Mar" },
        { value: "4", label: "Apr" },
        { value: "5", label: "May" },
        { value: "6", label: "Jun" },
        { value: "7", label: "Jul" },
        { value: "8", label: "Aug" },
        { value: "9", label: "Sep" },
        { value: "10", label: "Oct" },
        { value: "11", label: "Nov" },
        { value: "12", label: "Dec" },
      ],
    });
    let date = new Date();
    let yearDifference = date.getFullYear() - this.state.yearData[0].value;
    for (let i = 1; i <= yearDifference; i++) {
      this.state.yearData.push({
        value: Number(this.state.yearData[0].value) + i,
        label: Number(this.state.yearData[0].value) + i,
      });
    }
    this.getRecords();
  }
  generateInvoiceNumber = () => {
    let number = Math.floor(Math.random() * 1000000);
    if (number.toString().length === 6) {
      return number;
    } else if (number.toString().length === 5) {
      number = `${number}0`;
    } else if (number.toString().length === 4) {
      number = `${number}00`;
    } else if (number.toString().length === 3) {
      number = `${number}000`;
    } else if (number.toString().length === 2) {
      number = `${number}0000`;
    } else if (number.toString().length === 1) {
      number = `${number}00000`;
    } else if (number.toString().length === 0) {
      number = `${number}000000`;
    }
    return number;
  };

  getUserDetails = async () => {
    try {
      const userResponse = await api.create("user/getUserDetails");

      if (userResponse.code === 200) {
        this.setState({
          isSubscribed: userResponse.data.isSubscribed,
          email: userResponse.data.email,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getRecords = async (reference) => {
    try {
      if (reference) {
        let _this = reference;
        const { invoiceSizePerPage, page, month, year } = _this.state;
        const userResponse = await api.get("miner/invoices", {
          sizePerPage: invoiceSizePerPage,
          page: page,
          month: month,
          year: year,
        });

        if (userResponse.code === 200) {
          let arr = [];
          if (userResponse.data && !_.isEmpty(userResponse.data.Invoice)) {
            const sortedData = userResponse.data.Invoice.sort(function (a, b) {
              return (
                new Date(b.invoiceId.createdOnUTC) -
                new Date(a.invoiceId.createdOnUTC)
              );
            });

            await sortedData.forEach((data) => {
              for (let i = 0; i < 2; i++) {
                if (i === 0 && data.invoiceId) {
                  arr.push({
                    ...data,
                    description: "Electricity Fee",
                    invoiceNumber: _this.generateInvoiceNumber(),
                    cadElectricityFee: data.invoiceId.raisedAmountinCAD,
                    usdElectricityFee: data.invoiceId.raisedAmountinUSD,
                    btcElectricityFee: data.invoiceId.raisedAmountinBTC,
                    decTokens: data.invoiceId.raisedAmountinDEC,
                    discountUSDAmount:
                      data.invoiceId.raisedAmountInUsdAfterDecDiscount,
                  });
                }
                if (i === 1 && data.invoiceId) {
                  arr.push({
                    ...data,
                    description: "Management Fee",
                    alreadyPaid: true,
                    invoiceNumber: _this.generateInvoiceNumber(),
                    cadElectricityFee: data.invoiceId.raisedAmountinCAD,
                    usdElectricityFee: data.invoiceId.raisedAmountinUSD,
                    btcElectricityFee: data.invoiceId.raisedAmountinBTC,
                  });
                }
              }
            });
            let finalArr = arr;
            _this.setState({
              earningsData: finalArr,
              invoiceTotalSize: userResponse.data.count,
            });
          }
        }
        _this.setState({
          tableLoading: false,
          invoiceLoading: false,
          earningsRenderFlag: true,
        });
      }

      if (!reference) {
        const { invoiceSizePerPage, page, month, year } = this.state;
        const userResponse = await api.get("miner/invoices", {
          sizePerPage: invoiceSizePerPage,
          page: page,
          month: month,
          year: year,
        });

        if (userResponse.code === 200) {
          if (userResponse.data) {
            let arr = [];
            const sortedData = userResponse.data.Invoice.sort(function (a, b) {
              return (
                new Date(b.invoiceId.createdOnUTC) -
                new Date(a.invoiceId.createdOnUTC)
              );
            });
            await sortedData.forEach((data) => {
              for (let i = 0; i < 2; i++) {
                if (i === 0 && data.invoiceId) {
                  arr.push({
                    ...data,
                    description: "Electricity Fee",
                    invoiceNumber: this.generateInvoiceNumber(),
                    cadElectricityFee: data.invoiceId.raisedAmountinCAD,
                    usdElectricityFee: data.invoiceId.raisedAmountinUSD,
                    btcElectricityFee: data.invoiceId.raisedAmountinBTC,
                    decTokens: data.invoiceId.raisedAmountinDEC,
                    discountUSDAmount:
                      data.invoiceId.raisedAmountInUsdAfterDecDiscount,
                  });
                }
                if (i === 1 && data.invoiceId) {
                  arr.push({
                    ...data,
                    description: "Management Fee",
                    alreadyPaid: true,
                    invoiceNumber: this.generateInvoiceNumber(),
                    cadElectricityFee: data.invoiceId.raisedAmountinCAD,
                    usdElectricityFee: data.invoiceId.raisedAmountinUSD,
                    btcElectricityFee: data.invoiceId.raisedAmountinBTC,
                  });
                }
              }
            });
            let finalArr = arr;
            this.setState({
              earningsData: finalArr,
              invoiceTotalSize: userResponse.data.count,
            });
          }
        }
        this.setState({
          tableLoading: false,
          invoiceLoading: false,
          earningsRenderFlag: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      earningsData,
      earningsRenderFlag,
      page,
      invoiceSizePerPage,
      selectedMonth,
      selectedYear,
      yearData,
      invoiceId,
      monthData,
      invoiceTotalSize,
      tableLoading,
      fullName,
      address,
      city,
      postalCode,
      country,
    } = this.state;
    const { isSubscribed, email } = this.props.userDetails;
    const _this = this;
    let workerColumns = [];
    workerColumns = [
      {
        headerClasses: "custom-table-th",
        dataField: "invoiceId",
        text: "INVOICE ID",
        isDummyField: true,
        formatter: function (cell, row) {
          if (row.invoiceId) {
            return (
              <span>
                {row.invoiceId._id.substr(row.invoiceId._id.length - 6)}
              </span>
            );
          }
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "description",
        text: "DESCRIPTION",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>{row.description} </span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "total",
        text: "AMOUNT",
        isDummyField: true,
        formatter: function (cell, row) {
          if (row.invoiceId) {
            let total =
              row.usdElectricityFee +
              Number(
                !_.isUndefined(row.invoiceId.subscribedAmountinUSD)
                  ? row.invoiceId.subscribedAmountinUSD
                  : 0
              );

            return row.description === "Electricity Fee" ? (
              <div>
                <span>$ {niceNumberNoDecimalDisplay(total, 2)}</span>
              </div>
            ) : (
              <span>
                $ {niceNumberNoDecimalDisplay(row.invoiceId.poolfee, 2)}
              </span>
            );
          }
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "isSubscribed",
        text: (
          <span>
            BALANCE PAID{" "}
            <span className="custom-tooltip-coin2 row justify-content-center ml-1 mr-1">
              <i
                className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                style={{
                  fontSize: "18px",
                  color: "#999999",
                  cursor: "pointer",
                }}
              ></i>

              {isSubscribed && <SubscribedTooltip />}
              {!isSubscribed && <NotSubscribedTooltip />}
            </span>
          </span>
        ),
        isDummyField: true,
        formatter: function (cell, row) {
          if (row.invoiceId) {
            let paid = 0;
            if (row.status === "a") {
              paid =
                row.usdElectricityFee +
                Number(
                  !_.isUndefined(row.invoiceId.subscribedAmountinUSD)
                    ? row.invoiceId.subscribedAmountinUSD
                    : 0
                );
            } else if (row.status !== "a") {
              paid = Number(
                !_.isUndefined(row.invoiceId.subscribedAmountinUSD)
                  ? row.invoiceId.subscribedAmountinUSD
                  : 0
              );
            }
            return row.description === "Electricity Fee" ? (
              <>
                {row.status === "a" && (
                  <>
                    {row.paymentMode === "DEC" && (
                      <>
                        <div>
                          <strike style={{ color: "red" }}>
                            $ {niceNumberNoDecimalDisplay(paid, 2)}
                          </strike>
                        </div>
                        <div>
                          <span>
                            ${" "}
                            {niceNumberNoDecimalDisplay(
                              row.discountUSDAmount +
                                (row.invoiceId.subscribedAmountinUSD || 0),
                              2
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {row.paymentMode !== "DEC" && (
                      <>
                        {!row.invoiceId.subscribedAmountinUSD && (
                          <span>
                            ${" "}
                            {niceNumberNoDecimalDisplay(
                              row.invoiceId.raisedAmountinUSD,
                              2
                            )}
                          </span>
                        )}
                        {!!row.invoiceId.subscribedAmountinUSD && (
                          <span>
                            ${" "}
                            {niceNumberNoDecimalDisplay(
                              row.invoiceId.raisedAmountinUSD +
                                row.invoiceId.subscribedAmountinUSD,
                              2
                            )}
                          </span>
                        )}
                      </>
                    )}
                  </>
                )}
                {row.status !== "a" && (
                  <div>
                    <span>$ {niceNumberNoDecimalDisplay(paid, 2)}</span>
                  </div>
                )}
              </>
            ) : (
              <span>
                $ {niceNumberNoDecimalDisplay(row.invoiceId.poolfee, 2)}
              </span>
            );
          }
        },
      },

      {
        headerClasses: "custom-table-th",
        dataField: "balance",
        text: "BALANCE DUE",
        isDummyField: true,
        formatter: function (cell, row) {
          if (row.invoiceId) {
            return row.description === "Electricity Fee" ? (
              <div>
                <span>
                  ${" "}
                  {row.status === "a"
                    ? 0
                    : row.usdElectricityFee < 0
                    ? niceNumberNoDecimalDisplay(row.usdElectricityFee, 2)
                    : niceNumberNoDecimalDisplay(row.usdElectricityFee, 2)}
                </span>
              </div>
            ) : (
              <span>$ 0</span>
            );
          }
        },
      },

      {
        headerClasses: "custom-table-th",
        dataField: "outstandingTokens",
        text: (
          <span>
            TOKENS ISSUED{" "}
            <span className="custom-tooltip-coin2 row justify-content-center ml-1 mr-1">
              <i
                className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                style={{
                  fontSize: "18px",
                  color: "#999999",
                  cursor: "pointer",
                }}
              ></i>

              <TokensIssuedTooltip />
            </span>
          </span>
        ),
        // text: "",
        isDummyField: true,
        formatter: function (cell, row) {
          if (
            row.invoiceId &&
            row.description === "Electricity Fee" &&
            row.invoiceId.outstandingTokens
          ) {
            return (
              <span>
                {niceNumberNoDecimalDisplay(row.invoiceId.outstandingTokens, 0)}{" "}
                DEC
              </span>
            );
          } else {
            return <span>0</span>;
          }
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "createdOnUTC",
        text: "Date",
        isDummyField: true,
        formatter: function (cell, row) {
          if (row.invoiceId) {
            return (
              <span>
                {moment(row.invoiceId.createdOnUTC)
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")}
              </span>
            );
          }
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "status",
        isDummyField: true,
        formatter: function (cell, row) {
          let classLabel;
          let statusLabel;
          if (!row.status) {
            classLabel = "badge-warning";
            statusLabel = "Pending";
          } else if (row.status === "u") {
            classLabel = "badge-primary";
            statusLabel = "Under Review";
          } else if (row.status === "p") {
            classLabel = "badge-warning";
            statusLabel = "Pending";
          } else if (row.status === "a") {
            classLabel = "badge-success";
            statusLabel = "Paid";
          } else if (row.status === "r") {
            classLabel = "badge-danger";
            statusLabel = "Rejected";
          }
          if (row.description === "Management Fee") {
            classLabel = "badge-success";
            statusLabel = "Paid";
          }
          if (row.invoiceId) {
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  className={"badge1 " + classLabel}
                  style={{ marginRight: "5px" }}
                >
                  {statusLabel}
                </span>
              </span>
            );
          }
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "adminNote",
        text: "ADMIN NOTE",
        isDummyField: true,
        formatter: function (cell, row) {
          if (
            row.adminNote &&
            row.status === "r" &&
            row.description !== "Management Fee"
          ) {
            return (
              <button
                onClick={() => {
                  _this.getNote(row.adminNote);
                }}
                className="btn btn-primary"
              >
                Invoice Message
              </button>
            );
          } else {
            return;
          }
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "actions",
        text: "ACTIONS",
        isDummyField: true,
        formatter: function (cell, row) {
          if (row.invoiceId) {
            return !row.alreadyPaid ? (
              <button
                disabled={
                  row.status === "p" ? false : row.status === "r" ? false : true
                }
                onClick={() => {
                  _this.depositShowModal(row);
                }}
                className="btn btn-primary"
              >
                Pay
              </button>
            ) : (
              <button disabled={true} className="btn btn-primary">
                Pay
              </button>
            );
          }
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="invoiceNumber"
          bordered={false}
          loading={this.state.invoiceLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({
            page,
            hideSizePerPage: true,
            sizePerPage,
            totalSize,
          })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="filter-box">
              <h5 className="form-header">Filter Invoices</h5>
              <form className="form-inline">
                <div className="col-sm-12 mb-2 col-md-5 col-lg-5">
                  <Select
                    value={selectedMonth}
                    options={monthData}
                    placeholder="Select Month"
                    isMulti={false}
                    onChange={this.handleMonthChange}
                  />
                </div>
                <div className="col-sm-12 mb-2 col-md-5 col-lg-5">
                  <Select
                    value={selectedYear}
                    options={yearData}
                    placeholder="Select Year"
                    isMulti={false}
                    onChange={this.handleYearChange}
                  />
                </div>
                <div className="col-sm-12 mt-2 col-md-12 col-lg-12">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      this.filterRecords(selectedMonth, selectedYear);
                    }}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </div>
              </form>
            </div>
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4 ">
              <div className="earnings-table table-responsive scrollable">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                    alignItems: "center !important",
                    paddingTop: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px",
                      alignItems: "center !important",
                      paddingTop: "5px",
                    }}
                  >
                    <h4 className="card-title-font form-table-header">
                      <span className="walletFont"> Invoices</span>
                    </h4>
                  </div>
                </div>
                <div>
                  {tableLoading && (
                    <div className="text-center mb-2 mt-2">
                      <span id="loading" color="#047bf8" />
                    </div>
                  )}
                </div>
                {earningsRenderFlag === true && (
                  <WorkerRemoteAll
                    data={earningsData}
                    page={page}
                    sizePerPage={invoiceSizePerPage}
                    totalSize={invoiceTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
              <DepositModal
                {...this.props}
                {...this.state}
                userDetails={{
                  email,
                  fullName,
                  address,
                  city,
                  postalCode,
                  country,
                }}
                depositOnCloseModal={this.onCloseModaldeposit}
                invoiceId={invoiceId}
                getRecords={this.getRecords}
                reference={this}
                setInvoiceLoading={this.setInvoiceLoading}
                onInputValueChange={this.onchange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.user.userDetails,
});

export default connect(mapStateToProps)(Invoices);
