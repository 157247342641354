import React, { Component } from "react";
import Modal from "react-responsive-modal";
// import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";

import { FilePond, registerPlugin } from "react-filepond";
import Api from "../../services/api";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { API_ROOT } from "../../services/api-config";
import { connect } from "react-redux";
import Toast from '../CustomToast/CustomToast';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let api;

class PaymentRequestModal extends Component {
  constructor(props, state) {
    super(props);
    api = new Api();
    this.state = {
      transactionAmount: Number(this.props.invoiceId.usdElectricityFee).toFixed(
        2
      ),
      userNote: "",
      issuerId: this.props.invoiceId.userId,
      invoiceNumber: this.props.invoiceId.invoiceId._id,
      wireTransferDetails: "",
    };
    this.changeAmount = this.changeAmount.bind(this);
    this.changeUserNote = this.changeUserNote.bind(this);
    this.saveNrtBuyPaymentRequest = this.saveNrtBuyPaymentRequest.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.uploadImageArray = {};
  }
  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "wireTransfer",
    });

    if (response.code === 200) {
      let details = response.data[0].wireTransferPaymentCredentials;
      const detailsArr = details.split(",");

      this.setState({
        wireTransferDetails: detailsArr,
      });
    }
  }
  onChangeCurrencyType(value) {
    this.setState({
      currency: value,
    });
  }
  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }
  async saveNrtBuyPaymentRequest() {
    this.props.onCloseModal();
    this.props.depositOnCloseModal();
    const data = new FormData();
    data.append("image", this.uploadImageArray["file"]);
    data.append("transactionAmount", this.state.transactionAmount);
    data.append("userNote", this.state.userNote);
    data.append("issuerId", this.state.issuerId);
    data.append("invoiceId", this.state.invoiceNumber);
    data.append("email", this.props.email);

    let authenticationToken = this.props.authToken;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: authenticationToken,
      },
    };

    axios
      .post(API_ROOT + "/miner/WireTransferPayment", data, config)
      .then((response) => {
        if (response.data.code === 200) {
          this.props.getRecords(this.props.reference);
          Toast({
            type:'success',
            title: 'Success',
            message: response.data.message,
          });
          // toast.success(response.data.message);
        } else {
          this.props.onCloseModal();
          this.props.depositOnCloseModal();
          Toast({
            type:'error',
            title: 'Error',
            message: response.data.message,
          });
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        Toast({
          type:'error',
          title: 'Error',
          message: "Please upload valid payment proof file.",
        });
        // toast.error("Please upload valid payment proof file.");
        console.log(error);
      });
  }
  async changeAmount(event) {
    this.setState({
      transactionAmount: event.target.value,
    });
  }
  async changeUserNote(event) {
    this.setState({
      userNote: event.target.value,
    });
  }
  render() {
    const {
      paymentRequestModalOpen,
      onCloseModal,
      invoiceId,
      paymentRequestButtonLoading,
    } = this.props;
    const { wireTransferDetails } = this.state;
    let wireFrameData = wireTransferDetails;
    let buttonDisabled = paymentRequestButtonLoading === true ? true : false;
    let amount = invoiceId
      ? Number(invoiceId.usdElectricityFee).toFixed(2)
      : "";
    amount = `$ ${amount}`;

    return (
      <Modal
        open={paymentRequestModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="text-large text-black">
                      {/* Make {securityTokenSymbol} pay request */}
                      Make FIAT Payment Request
                    </h4>
                    <div className="onboarding-text gray">
                      {/* Generate {securityTokenSymbol} pay request by filling
                      below required details. */}
                      Submit the below form to request to deposit your payment
                      request in fiat currency.
                    </div>
                    <form className="mt-3">
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">
                          Transaction Amount
                          <span className="required-field">*</span>
                        </label>
                        <div className="d-flex">
                          <input
                            className="form-control mr-3 "
                            id="transactionAmount"
                            placeholder="Enter transaction amount"
                            type="text"
                            value={amount}
                            name="transactionAmount"
                            // onChange={this.changeAmount}
                            disabled={true}
                          />
                        </div>
                        <div className="text-center mt-4 receive-text "></div>
                      </div>

                      <div class="form-group text-black">
                        <label htmlFor="userNote">
                          Wire transfer details to make payment
                        </label>
                        <div
                          style={{
                            border: "1px solid #ebebeb",
                            boxSizing: "border-box",
                            padding: "10px 30px",
                            borderRadius: "5px",
                          }}
                        >
                          {wireFrameData
                            ? wireFrameData.map((data, index) => {
                                const dataArr = data.split(":");
                                return (
                                  <div
                                    key={index}
                                    class="row justify-content-center text-black"
                                    style={{
                                      padding: "10px 20px",
                                    }}
                                  >
                                    <div>
                                      <span className="text-bold text-black">
                                        {dataArr[0]} :{" "}
                                      </span>
                                      <span>{dataArr[1]}</span>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="userNote">Note</label>
                        <textarea
                          className="form-control"
                          placeholder="Enter note"
                          name="userNote"
                          id="userNote"
                          value={this.userNote}
                          onChange={this.changeUserNote}
                        ></textarea>
                      </div>

                      <label className="text-black">
                        {" "}
                        Proof of Payment (pdf, jpg, jpeg)
                        <span className="required-field">*</span>
                      </label>
                      <div
                        className="resource-continaer payment-proof-container dropzoneIdContainer"
                        // style={{ backgroundColor: "#dddddd" }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <FilePond
                              allowMultiple={false}
                              allowFileTypeValidation={true}
                              className="uploadProof"
                              acceptedFileTypes={[
                                "image/*",
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              ]}
                              instantUpload={false}
                              onupdatefiles={(fileItems) => {
                                if (
                                  !_.isEmpty(fileItems) &&
                                  fileItems[0].fileSize < 5000000
                                ) {
                                  if (
                                    (fileItems[0].fileExtension === "pdf" ||
                                      fileItems[0].fileExtension === "docx") &&
                                    fileItems[0].fileSize < 5000000
                                  ) {
                                    this.fileUpdated(fileItems);
                                  } else if (
                                    (fileItems[0].fileExtension === "jpeg" ||
                                      fileItems[0].fileExtension === "jpg" ||
                                      fileItems[0].fileExtension === "png") &&
                                    fileItems[0].fileSize < 500000
                                  ) {
                                    this.fileUpdated(fileItems);
                                  } else if (
                                    (fileItems[0].fileExtension === "jpeg" ||
                                      fileItems[0].fileExtension === "jpg" ||
                                      fileItems[0].fileExtension === "png") &&
                                    fileItems[0].fileSize > 500000
                                  ) {
                                    fileItems[0].abortLoad();
                                    Toast({
                                      type:'error',
                                      title: 'Error',
                                      message: "Please make sure image size is under 500kb",
                                    });
                                    // toast.error(
                                    //   "Please make sure image size is under 500kb"
                                    // );
                                  }
                                } else if (
                                  !_.isEmpty(fileItems) &&
                                  fileItems[0].fileSize > 5000000
                                ) {
                                  fileItems[0].abortLoad();
                                  Toast({
                                    type:'error',
                                    title: 'Error',
                                    message: "Please make sure file size is under 5mb",
                                  });
                                  // toast.error(
                                  //   "Please make sure file size is under 5mb"
                                  // );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-4">
                        <button
                          className="btn modal-button mr-2 cancel-button"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn modal-button update-button"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={this.saveNrtBuyPaymentRequest}
                        >
                          {" "}
                          Submit{" "}
                          {paymentRequestButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(PaymentRequestModal);
