import React, { Component } from "react";
import moment from "moment";
import Switch from "react-switch";
import { ExportCSV } from "./ExportCSV";
import "./Earnings.scss";
import Api from "../../services/api";
import { niceNumberNoDecimalDisplay, getBtcUsd } from "../../utils/Util";
import Modal from "../Modal/Modal";

let api;
class EarningModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      startDate: "",
      endDate: "",
      show: false,
      timeHorizon: "",
      fileName: "MinerData",
      isActual: false,
    };
  }

  toggleActualAndLive = (e) => {
    this.setState({ isActual: !this.state.isActual });
  };

  setShow = (startDate, endDate) => {
    this.setState({ show: startDate && endDate ? true : false });
  };
  clearTime = () => {
    this.setState({ startDate: "", endDate: "", timeHorizon: "" });
  };

  onChangeTimeHorizon = (time) => {
    this.setState({
      timeHorizon: time,
    });
  };
  onChangeStartDate = (e) => {
    this.setState(
      {
        startDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  };
  onChangeEndDate = (e) => {
    this.setState(
      {
        endDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  };

  getEarnings = async () => {
    const { earningsTotalSize } = this.props;
    const { isActual } = this.state;

    try {
      const response = await api.get("user/earnings", {
        page: 1,
        sizePerPage: earningsTotalSize,
      });
      if (response.code !== 200) {
        alert("Fail to fetch data");
      }
      const priceUsd = await getBtcUsd();
      const earningsData =
        response.data && response.data.earnings ? response.data.earnings : [];
      const earningsArr = earningsData.map((data) => {
        const actualRate = data.electricityCostInUsd
          ? data.electricityCostInUsd / data.electricityCost
          : 0;
        const rate = isActual ? actualRate : priceUsd;
        return {
          date: data.date ? moment(data.date).format("YYYY/MM/DD") : "",
          grossEarnings: data.grossProfit
            ? niceNumberNoDecimalDisplay(data.grossProfit, 8)
            : "0",
          grossEarningsInUSD: data.grossProfit
            ? niceNumberNoDecimalDisplay(data.grossProfit * rate, 2)
            : "0",
          fees: data.poolFee
            ? niceNumberNoDecimalDisplay(data.poolFee, 8)
            : "0",
          feesInuUSD: data.poolFee
            ? niceNumberNoDecimalDisplay(data.poolFee * rate, 2)
            : "0",
          powerCost: isActual
            ? niceNumberNoDecimalDisplay(data.electricityCost, 8)
            : niceNumberNoDecimalDisplay(
                data.electricityCostInUsd / priceUsd,
                8
              ),
          powerCostiInUSD: data.electricityCostInUsd
            ? niceNumberNoDecimalDisplay(data.electricityCostInUsd, 2)
            : "0",
          netEarnings: data.netProfit
            ? niceNumberNoDecimalDisplay(data.netProfit, 8)
            : "0",
          netEarningsInUSD: data.netProfit
            ? niceNumberNoDecimalDisplay(data.netProfit * rate, 2)
            : "0",
        };
      });
      this.setState({
        excelData: earningsArr,
      });
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidUpdate(_prevProps, prevState) {
    if (prevState.isActual !== this.state.isActual) {
      await this.getEarnings();
    }
  }

  async componentDidMount() {
    await this.getEarnings();
  }

  render() {
    const { modalOpen, onCloseModal } = this.props;
    const { fileName, timeHorizon } = this.state;
    return (
      <Modal
        isOpen={modalOpen}
        onClose={onCloseModal}
        modalWidth={730}
        modalTitle="Export Data"
      >
        <div className="earning-modal-container">
          <div>
            <p>Export list of my workers</p>
            <div className="form-group text-black">
              <label htmlFor="title">
                Start Date<span className="required-field">*</span>
              </label>
              <input
                className="input-field "
                id="startDate"
                type="date"
                value={this.state.startDate}
                onChange={this.onChangeStartDate}
              />
            </div>

            <div className="form-group text-black">
              <label htmlFor="title">
                End Date<span className="required-field">*</span>
              </label>
              <input
                className="input-field"
                id="endDate"
                type="date"
                value={this.state.endDate}
                onChange={this.onChangeEndDate}
              />
            </div>

            <div className="switch-actual-live">
              <Switch
                onColor="#6485e1"
                checked={this.state.isActual}
                onChange={this.toggleActualAndLive}
                onHandleColor="#2693e6"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                height={25}
                width={42}
                className="actual-live-switch"
              />
              <p>{this.state.isActual ? "ACTUAL" : "Enable live option"}</p>
            </div>

            <div className="seperator-text">
              <span>OR</span>
            </div>
            <div className="time-horizon-field">
              <label>Select Time Horizon</label>
              <div>
                <div>
                  <select
                    className="input-field mb-2"
                    value={timeHorizon || ""}
                    onChange={(e) => this.onChangeTimeHorizon(e.target.value)}
                  >
                    <option value="" disabled>
                      Time Horizon
                    </option>
                    <option value="24H">24H</option>
                    <option value="7D">7D</option>
                    <option value="30D">30D</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <ExportCSV
                csvData={this.state.excelData}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                timeHorizon={this.state.timeHorizon}
                fileName={fileName}
                onCloseModal={onCloseModal}
                clearTime={this.clearTime}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EarningModal;
