import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Switch from "react-switch";

import Api from "../../services/api";
import { niceNumberNoDecimalDisplay, getBtcUsd } from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import bitcoinImg from "../../assets/img/earnings-icon.svg";
import EarningModal from "./EarningModal";
import "./Earnings.scss";
import EarningsTooltip from "./EarningsTooltip";
import { pageProgress } from "../../utils/Util";
import Table from '../Table/Table';
import { access } from 'fs';
import DashHeader from '../DashHeader/DashHeader';
let api;

class Earnings extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      earningsData: [],
      earningsPage: 1,
      earningsSizePerPage: 10,
      earningsTotalSize: 0,
      baseCurrency: "btc",
      modalOpen: false,
      timeHorizon: "24h",
      earningDetails: {},
      grossMiner7DaysData: null,
      grossMiner30DaysData: null,
      grossMinerYesterdaysData: null,
      grossMinerAllDaysData: null,
      decimalPoint: 8,
      isActual: false,
      priceUsd: 0,
      loader:false
    };
    pageProgress("force_remove");
  }
  showModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  async componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    const priceUsd = await getBtcUsd();

    this.setState({ priceUsd });
    await this.setStatistics(priceUsd);
    await this.getRecords(priceUsd);
    await this.displayEarnings();
  }

  async componentDidUpdate(_prevProps, prevState) {
    const { sizePerPage, earningsPage, baseCurrency, timeHorizon, priceUsd } =
      this.state;
    if (
      sizePerPage !== prevState.sizePerPage ||
      earningsPage !== prevState.earningsPage
    ) {
      this.getRecords(priceUsd);
    }
    if (
      baseCurrency !== prevState.baseCurrency ||
      timeHorizon !== prevState.timeHorizon
    ) {
      this.displayEarnings();
    }
  }

  convertBTCToUSD = (data, rate) => {
    if (!data) {
      return {
        earnings: 0,
        poolFees: 0,
        powerCost: 0,
        netEarnings: 0,
      };
    }
    return {
      earnings: parseFloat(data.earnings) * rate,
      poolFees: parseFloat(data.poolFees) * rate,
      powerCost: parseFloat(data.powerCost) * rate,
      netEarnings: parseFloat(data.netEarnings) * rate,
    };
  };

  setStatistics = async (rate) => {
    try {
      this.setState({ statisticsLoading: true });
      const response = await api.get("user/miner/statistics");
      if (response.code === 200 && response.data) {
        this.setState({
          grossMiner7DaysData: {
            btc: response.data.grossMiner7DaysData,
            usd: this.convertBTCToUSD(response.data.grossMiner7DaysData, rate),
          },
          grossMiner30DaysData: {
            btc: response.data.grossMiner30DaysData,
            usd: this.convertBTCToUSD(response.data.grossMiner30DaysData, rate),
          },
          grossMinerYesterdaysData: {
            btc: response.data.grossMinerYesterdaysData,
            usd: this.convertBTCToUSD(
              response.data.grossMinerYesterdaysData,
              rate
            ),
          },
          grossMinerAllDaysData: {
            btc: response.data.grossMinerAllDaysData,
            usd: this.convertBTCToUSD(
              response.data.grossMinerAllDaysData,
              rate
            ),
          },
        });
      }
    } catch (err) {
      console.log("err", err);
    }
    this.setState({ statisticsLoading: false });
  };

  async getRecords(priceUsd) {
    try {
      const { earningsPage, earningsSizePerPage } = this.state;

      this.setState({ loader:true });
      const response = await api.get("user/earnings", {
        page: earningsPage,
        sizePerPage: earningsSizePerPage,
      });

      if (response.code === 200) {
        let earningsData =
          response.data && response.data.earnings ? response.data.earnings : "";
        const totalEarnings =
          response.data && response.data.totalEarnings
            ? response.data.totalEarnings
            : "";
        earningsData = earningsData.map((d) => {
          return {
            ...d,
            actualRate: d.electricityCostInUsd / d.electricityCost,
          };
        });
        this.setState({
          earningsData,
          earningsTotalSize: totalEarnings,
          loader:false
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (_type, { page, sizePerPage, filters }) => {
    if (
      this.state.sizePerPage !== sizePerPage ||
      this.state.earningsPage !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, earningsPage: page });
    }
  };

  selectBaseCurrency = (value) => {
    this.setState({
      baseCurrency: value,
      decimalPoint: value === "usd" ? 2 : 8,
    });
  };

  selectTimeHorizon = (value) => {
    this.setState({ timeHorizon: value});
  };

  displayEarnings = async () => {
    const {
      timeHorizon,
      baseCurrency,
      grossMiner7DaysData,
      grossMiner30DaysData,
      grossMinerYesterdaysData,
      grossMinerAllDaysData,
    } = this.state;

    let data;
    if (grossMinerYesterdaysData) {
      if (timeHorizon === "24h") {
        data = grossMinerYesterdaysData[baseCurrency];
      } else if (timeHorizon === "7d") {
        data = grossMiner7DaysData[baseCurrency];
      } else if (timeHorizon === "30d") {
        data = grossMiner30DaysData[baseCurrency];
      } else if (timeHorizon === "all") {
        data = grossMinerAllDaysData[baseCurrency];
      }
    }
    this.setState({
      earningDetails: {
        decimal: baseCurrency === "btc" ? 8 : 2,
        ...data,
      },
    });
  };

  toggleActualAndLive = (e) => {
    this.setState({ isActual: !this.state.isActual });
  };

  renderNotionalEarnings = () => {
    const { baseCurrency, statisticsLoading, timeHorizon, earningDetails } =
      this.state;

    const { earnings, poolFees, powerCost, netEarnings, decimal } =
      earningDetails;

    return (
      <div className="earning-box-container card-inner-padding">
        <div className="earning-header">
            <div className='d-flex align-items-center'>
              <h2 className='heading'>Notional Earnings</h2>
              <span className='ml-2'>
                <i
                  className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                  style={{
                    fontSize: "18px",
                    color: "#7E25F2",
                    cursor: "pointer",
                  }}
                ></i>
                <EarningsTooltip />
              </span>
            </div>

            <div
              className="selection-container"
              style={{ fontSize: "14px" }}
            >
                <select
                className="earnings-select"
                id="timeHorizon"
                name="timeHorizon"
                defaultValue="btc"
                style={{
                  padding: "5px 7px",
                }}
                onChange={(e) => this.selectTimeHorizon(e.target.value)}
              >
                <option value="24h">24h</option>
                <option value="7d">7d</option>
                <option value="30d">30d</option>
                <option value="all">All</option>

              </select>
              <div className="btcDropDown">
                <select
                  className="earnings-select"
                  id="selectedCurrency"
                  name="selectedCurrency"
                  defaultValue="btc"
                  style={{
                    padding: "5px 7px",
                  }}
                  onChange={(e) => this.selectBaseCurrency(e.target.value)}
                >
                  <option value="usd">USD</option>
                  <option value="btc">BTC</option>
                </select>
              </div>
            </div>
          </div>

        <div className="earning-boxes">
          <div className="single-box">
            <img src={bitcoinImg} height="40" alt="bitcoin" />
            <div>
              <span className="box-heading">Gross Earnings</span>
              <br />
              <span className="box-value">
                {statisticsLoading && (
                  <i className="fa fa-spinner fa-spin"></i>
                )}
                {!statisticsLoading && (
                  <span>
                    {`${
                      earnings
                        ? niceNumberNoDecimalDisplay(earnings, decimal)
                        : "0"
                    } ${_.toUpper(baseCurrency)}`}
                  </span>
                )}
              </span>
            </div>
          </div>

          <div className="single-box">
            <img src={bitcoinImg} height="40" alt="bitcoin" />
            <div>
              <span className="box-heading">Fees</span>
              <br />
              <span className="box-value">
                {statisticsLoading && (
                  <i className="fa fa-spinner fa-spin"></i>
                )}
                {!statisticsLoading && (
                  <span>
                    {`${
                      earningDetails.poolFees
                        ? niceNumberNoDecimalDisplay(poolFees, decimal)
                        : "0"
                    } ${_.toUpper(baseCurrency)}`}
                  </span>
                )}
              </span>
            </div>
          </div>

          <div className="single-box">
            <img src={bitcoinImg} height="40" alt="bitcoin" />
            <div>
              <span className="box-heading">Power Cost</span>
              <br />
              <span className="box-value">
                {statisticsLoading && (
                  <i className="fa fa-spinner fa-spin"></i>
                )}
                {!statisticsLoading && (
                  <span>
                    {`${
                      earningDetails.powerCost
                        ? niceNumberNoDecimalDisplay(powerCost, decimal)
                        : "0"
                    } ${_.toUpper(baseCurrency)}`}
                  </span>
                )}
              </span>
            </div>
          </div>

          <div className="single-box">
            <img src={bitcoinImg} height="40" alt="bitcoin" />
            <div>
              <span className="box-heading">Net Earnings</span>
              <br />

              <span className="box-value">
                {statisticsLoading && (
                  <i className="fa fa-spinner fa-spin"></i>
                )}
                {!statisticsLoading && (
                  <span>
                    {`${
                      earningDetails.netEarnings
                        ? niceNumberNoDecimalDisplay(netEarnings, decimal)
                        : "0"
                    } ${_.toUpper(baseCurrency)}`}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        
      </div>
    );
  };

  render() {
    const {
      earningsData,
      earningsSizePerPage,
      earningsTotalSize,
      earningsPage,
      workerLoading,
      baseCurrency,
      decimalPoint,
      priceUsd,
      isActual,
      loader
    } = this.state;

    const isUSD = baseCurrency === "usd";

    const columns = [
      {
        heading:'Date',
        accessor:'date',
        Cell:({ value })=> <span>{value ? moment(value).format("YYYY/MM/DD") : ""}</span>
      },
      {
        heading:'Gross Earnings', 
        accessor:'grossProfit',
        Cell:({ row }) => {
          const { grossProfit, actualRate } = row;
          let usdValue = !isUSD
            ? grossProfit
            : isActual
            ? grossProfit * actualRate
            : grossProfit * priceUsd;
            usdValue = niceNumberNoDecimalDisplay(usdValue, decimalPoint);
          return ( <span>{`${usdValue} ${isUSD ? "USD" : "BTC"}`}</span> )
        }
      },
      {
        heading:'Fees',
        accessor:'poolfees',
        Cell:({ row }) => {
          const { poolFee, actualRate } = row;
          let usdValue = !isUSD
            ? poolFee
            : isActual
            ? poolFee * actualRate
            : poolFee * priceUsd;
            usdValue = niceNumberNoDecimalDisplay(usdValue, decimalPoint);
          return <span>{`${usdValue} ${isUSD ? "USD" : "BTC"}`}</span>
        }
      },
      {
        heading:'Power Cost',
        accessor:'',
        Cell:({ row }) => {
          const { electricityCostInUsd, electricityCost, actualRate } = row;
          let usdValue = isUSD
            ? electricityCostInUsd
            : !isActual
            ? (electricityCost * actualRate) / priceUsd
            : electricityCost;
            usdValue = niceNumberNoDecimalDisplay(usdValue, decimalPoint);
          return <span>{`${usdValue} ${isUSD ? "USD" : "BTC"}`}</span>
        }
      },
      {
        heading:'Net Earnings',
        accessor:'',
        Cell:({ row }) => {
          const { netProfit, actualRate } = row;
          let usdValue = !isUSD
            ? netProfit
            : isActual
            ? netProfit * actualRate
            : netProfit * priceUsd;

            usdValue = niceNumberNoDecimalDisplay(usdValue, decimalPoint);

          return <span>{`${usdValue} ${isUSD ? "USD" : "BTC"}`}</span>;
        }
      },
      {
        heading:'BTC/USD',
        accessor:'',
        Cell:({ row }) => {
          const usdValue = niceNumberNoDecimalDisplay(
            isActual ? row.actualRate : priceUsd,
            2
          );

          return <span>{`$ ${usdValue}`}</span>;
        }
      }
    ]
    
    // const workerColumns = [
    //   {
    //     headerClasses: "custom-table-th",
    //     style: { whiteSpace: "nowrap" },
    //     dataField: "date",
    //     text: (
    //       <>
    //         DATE<span className="font-weight-normal ml-1">(YYYY/MM/DD)</span>
    //       </>
    //     ),
    //     formatter: function (cell, _row) {
    //       return <span>{cell ? moment(cell).format("YYYY/MM/DD") : ""}</span>;
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "grossProfit",
    //     text: "Gross Earnings",
    //     formatter: function (_cell, row) {
    //       const { grossProfit, actualRate } = row;
    //       let value = !isUSD
    //         ? grossProfit
    //         : isActual
    //         ? grossProfit * actualRate
    //         : grossProfit * priceUsd;
    //       value = niceNumberNoDecimalDisplay(value, decimalPoint);
    //       return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "poolFee",
    //     text: "Fees",
    //     formatter: function (_cell, row) {
    //       const { poolFee, actualRate } = row;
    //       let value = !isUSD
    //         ? poolFee
    //         : isActual
    //         ? poolFee * actualRate
    //         : poolFee * priceUsd;
    //       value = niceNumberNoDecimalDisplay(value, decimalPoint);
    //       return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "",
    //     text: "Power Cost",
    //     isDummyField: true,
    //     formatter: function (_cell, row) {
    //       const { electricityCostInUsd, electricityCost, actualRate } = row;
    //       let value = isUSD
    //         ? electricityCostInUsd
    //         : !isActual
    //         ? (electricityCost * actualRate) / priceUsd
    //         : electricityCost;
    //       value = niceNumberNoDecimalDisplay(value, decimalPoint);
    //       return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "",
    //     text: "Net Earnings",
    //     isDummyField: true,
    //     formatter: function (_cell, row) {
    //       const { netProfit, actualRate } = row;
    //       let value = !isUSD
    //         ? netProfit
    //         : isActual
    //         ? netProfit * actualRate
    //         : netProfit * priceUsd;

    //       value = niceNumberNoDecimalDisplay(value, decimalPoint);

    //       return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
    //     },
    //   },
    //   {
    //     headerClasses: "custom-table-th",
    //     dataField: "btcUsdRate",
    //     text: "BTC/USD",
    //     isDummyField: true,
    //     formatter: function (_cell, row) {
    //       const value = niceNumberNoDecimalDisplay(
    //         isActual ? row.actualRate : priceUsd,
    //         2
    //       );

    //       return <span>{`$ ${value}`}</span>;
    //     },
    //   },
    // ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
      loader
    }) => (
      <div className="table-responsive">
        <Table 
          showPagination={true}
          data={data}
          onSortedList={()=>console.log('')}
          column={columns}
          currentPage={page}
          onPageChange={(page) => this.setState({ earningsPage: page })}
          totalSize={totalSize}
          itemsPerPage={10}
          loading={loader}
        />
        {/* <BootstrapTable
          remote
          keyField="date"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        /> */}
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container earnings-containers">
        <div className="content-i">
          <div>
            <DashHeader heading={'Earnings'} description={'Daily earnings overview with fees and power costs'} />
            {this.renderNotionalEarnings()}

            <div className="table-container-box">
              <div className="earnings-table table-responsive">
                {earningsData && (
                  <div>
                    <div className="table-header">
                        <h5 className="mr-2 text-white">Notional Earnings</h5>
                        <div className='header-switch'>
                          <div className='switch-bar'>
                            <Switch
                              onColor="#6310D2"
                              checked={this.state.isActual}
                              onChange={this.toggleActualAndLive}
                              onHandleColor="white"
                              handleDiameter={25}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              height={25}
                              width={42}
                              className="actual-live-switch"
                            />
                            <p className="mb-0 text-white">
                              {this.state.isActual ? "ACTUAL" : "LIVE"}
                            </p>
                          </div>
                          
                        <button
                          className="btn-primary export-btn"
                          onClick={() => this.showModal()}
                        >
                          Export Data
                        </button>

                        </div>
                       
                      <EarningModal
                        {...this.props}
                        {...this.state}
                        onCloseModal={this.onCloseModal}
                        onInputValueChange={this.onchange}
                      />
                    </div>
                    <WorkerRemoteAll
                      data={earningsData}
                      page={earningsPage}
                      sizePerPage={earningsSizePerPage}
                      totalSize={earningsTotalSize}
                      onTableChange={this.handleTableChange}
                      loader={loader}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Earnings;
