import React from 'react';
import './pagination.scss';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const maxVisiblePages = 3;
  let displayedPages = [];
  if (totalPages <= maxVisiblePages) {
    displayedPages = pageNumbers;
  } else {
    const middleIndex = Math.floor(maxVisiblePages / 2);
    const minDisplayIndex = Math.max(currentPage - middleIndex, 0);
    const maxDisplayIndex = Math.min(minDisplayIndex + maxVisiblePages - 1, totalPages - 1);

    if (minDisplayIndex > 0) {
      displayedPages.push(1);
      if (minDisplayIndex > 1) {
        displayedPages.push('...');
      }
    }

    for (let i = minDisplayIndex; i <= maxDisplayIndex; i++) {
      displayedPages.push(i + 1);
    }

    if (maxDisplayIndex < totalPages - 1) {
      if (maxDisplayIndex < totalPages - 3) {
        displayedPages.push('...');
      }
      displayedPages.push(totalPages);
    }
  }

  return (
    <>
      <nav className='custom-pagination'>
        <ul className='pagination'>
          {/* <li className='pagination-btn'>
          <span className={currentPage == 1 ? ' disabled-span' : 'active-span'} onClick={currentPage !== 1 ? () => onPageChange(currentPage - 1):null}>
          <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className={currentPage == 1 ? ' disabled-icon' : 'active-icon'} d="M19.9995 6.39997H1.27952M1.27952 6.39997L5.95952 1.71997M1.27952 6.39997L5.95952 11.08"  stroke-width="1.56"/>
          </svg>
          </span>
            <button onClick={currentPage !== 1 ? () => onPageChange(currentPage - 1):null}>
              Previous
            </button>
          </li> */}
          {
            displayedPages.length > 0 ?
            displayedPages.map(pageNumber => (
              <li key={pageNumber}>
                <button onClick={() => onPageChange(pageNumber)} className={pageNumber == currentPage ? ' active' : 'not-active'}>
                  {pageNumber}
                </button>
              </li>
            )):
            0
          }
          {/* <li className='pagination-btn'>
            <button className={currentPage == totalPages ? ' disabled' : ''} onClick={currentPage !== totalPages ? () => onPageChange(currentPage + 1):null}>
              Next
            </button>
            <span className={currentPage == totalPages ? ' disabled-span' : 'active-span'} onClick={currentPage !== totalPages ? () => onPageChange(currentPage + 1):null }>
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className={currentPage == totalPages ? ' disabled-icon' : 'active-icon'} d="M1.59912 6.39997H20.3191M20.3191 6.39997L15.6391 1.71997M20.3191 6.39997L15.6391 11.08" stroke="#052460" stroke-width="1.56"/>
          </svg>
          </span>

          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
