import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({data}) => {
  const options = {
    chart: {
      type: 'pie', 
      width: '100%',
      height: 450,
      background: 'transparent',
    },
    fill: {
      colors: ['#2B4734', '#2A1A47', '#433A24'],
      opacity: 1,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 6,
      colors: ['#84FE8D', '#8225EB', '#EBBE2D'],
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          background: 'transparent',
        },
        dataLabels: {
          enabled: false, 
        },
      },
    },
    theme: {
      monochrome: {
        enabled: false,
      },
    },
  };

  const series = data;

  return (
    <>
      <Chart options={options} series={series} type="pie" height={400} />
    </>
  );
};

export default DonutChart;
