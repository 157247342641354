import React, { useEffect, useRef } from 'react';

function ClickAwayListener({ children, onClickAway }) {
  const containerRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        onClickAway(); // Trigger the outside click handler
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClickAway]);

  return <div ref={containerRef}>{children}</div>;
}

export default ClickAwayListener;
